import type { SerializedStyles } from "@emotion/react";
import { cssFns, truthy, usePrincipalColors } from "@hermes/ui";

export const DoctorsListTitle = ({
  styles,
  city,
  count,
  serviceName,
  cityArea,
}: {
  styles?: SerializedStyles;
  serviceName: string;
  city: string;
  cityArea?: string;
  count: number;
}) => {
  const principalColors = usePrincipalColors();

  return (
    <div css={styles}>
      <h1
        css={[
          cssFns.typo({ level: "title-2", weight: "semibold" }),
          { color: principalColors.gs2, margin: "0px" },
        ]}
      >
        {[serviceName, cityArea, city].filter(truthy).join(", ")} ({count})
      </h1>
    </div>
  );
};

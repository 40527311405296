import { Flag } from "@hermes/flags";
import { lazy } from "@hermes/ui";
import { wrapCreateBrowserRouter } from "@sentry/react";
import { createBrowserRouter, type RouteObject } from "react-router-dom";

import { DoctorsListPage } from "#internal/pages/doctors-list/page";

import { AppContainer } from "../app-container";
import { RoutesIdEnum } from "./types";

const { NotFoundPage } = lazy(() => import("#internal/pages/not-found/page"));
const { HomePage } = lazy(() => import("#internal/pages/home/page"));
// const { DoctorsListPage } = lazy(
//   () => import("#internal/pages/doctors-list/page"),
// );
const { DoctorProfilePage } = lazy(
  () => import("#internal/pages/doctor-profile"),
);
const { ClinicsPage } = lazy(() => import("#internal/pages/clinics"));
const { ClinicProfilePage } = lazy(
  () => import("#internal/pages/clinic-profile"),
);
const { AppointmentPage } = lazy(() => import("#internal/pages/appointment"));
const { ProceduresListPage } = lazy(() => import("#internal/pages/procedures"));
const { FeedbackPage } = lazy(() => import("#internal/pages/feedback"));

const { ProceduresByCategory } = lazy(
  () => import("#internal/pages/procedures"),
);
const { MapPage } = lazy(() => import("#internal/pages/map/page"));
const { ContactsPage } = lazy(() => import("#internal/pages/contacts"));
const { JoinClinic } = lazy(() => import("#internal/pages/join-clinic"));

const { Impresszum } = lazy(
  () => import("#internal/pages/legal/hu/impresszum"),
);
const { UserGTC } = lazy(() => import("#internal/pages/legal/hu/user-gtc"));
const { UserGTC_Ro } = lazy(() => import("#internal/pages/legal/ro/user-gtc"));
const { PrivacyPolicyHu } = lazy(
  () => import("#internal/pages/legal/hu/privacy-policy"),
);
const { PrivacyPolicyRo } = lazy(
  () => import("#internal/pages/legal/ro/privacy-policy"),
);
const { ServicesRedirectPage } = lazy(
  () => import("#internal/pages/services-redirect"),
);

const { PrivacyPolicyKg } = lazy(
  () => import("#internal/pages/legal/kg/privacy-policy"),
);
const { UserAgreementKg } = lazy(
  () => import("#internal/pages/legal/kg/user-agreement"),
);

const { ProcessingAgreementKg } = lazy(
  () => import("#internal/pages/legal/kg/processing-agreement"),
);
const { SurveyPage } = lazy(() => import("#internal/pages/survey"));

const routes = [
  {
    path: "/:lang?",
    element: <AppContainer />,
    children: [
      {
        index: true,
        loader: async ({}) => {
          return {};
        },
        element: (
          <Flag
            id="e87753e2-a203-433f-8897-55257c104f1d"
            description="Enable home page route"
            fallback={<NotFoundPage />}
          >
            <HomePage />
          </Flag>
        ),
        id: RoutesIdEnum.HOME,
      },
      {
        path: "doctors/:city/*",
        element: (
          <Flag
            id="ad2fe9e4-05a4-424b-9502-4d8d9aa7c6e5"
            description="Enable doctors list page"
            fallback={<NotFoundPage />}
          >
            <DoctorsListPage />
          </Flag>
        ),
        id: RoutesIdEnum.DOCTORS_LIST,
        loader: () => {
          return {};
        },
      },
      {
        path: "doctor/:slug",
        element: (
          <Flag
            id="e875ec8f-4de9-4179-ae86-7621dffc876b"
            description="Enable doctor-details page route"
            fallback={<NotFoundPage />}
          >
            <DoctorProfilePage />
          </Flag>
        ),
        id: RoutesIdEnum.DOCTOR_PROFILE,
      },
      {
        path: "clinics/:city",
        element: (
          <Flag
            id="f7de8dcf-2c10-4017-b8e2-5eab52c93c8d"
            description="Enable clinics page route"
            fallback={<NotFoundPage />}
          >
            <ClinicsPage />
          </Flag>
        ),
        id: RoutesIdEnum.CLINICS_LIST,
      },
      {
        path: "procedures/:city",
        element: (
          <Flag
            id="dba053e6-5061-42d2-bc8d-c0055891eb9d"
            description="Enable procedures list page route"
          >
            <ProceduresListPage />
          </Flag>
        ),
        children: [
          {
            path: "categories",
            element: (
              <Flag
                id="f4f5c635-00da-556a-8ca6-ba47de27467b"
                description="Enable All procedures page"
              >
                <ProceduresByCategory />
              </Flag>
            ),
            id: RoutesIdEnum.PROCEDURES_BY_CATEGORIES,
          },
        ],
        id: RoutesIdEnum.PROCEDURES_LIST,
      },
      {
        path: "clinics/:city/:name",
        element: (
          <Flag
            id="93558975-dfc5-413e-b514-5c4b2f7f8bfa"
            description="Enable clinic profile page route"
            fallback={<NotFoundPage />}
          >
            <ClinicProfilePage />
          </Flag>
        ),
        id: RoutesIdEnum.CLINIC_PROFILE,
      },
      {
        path: "appointments/:appointment_id",
        element: (
          <Flag
            id="58c494f3-b6d1-4f64-bcab-6b213859fd94"
            description="Enable appointment page"
          >
            <AppointmentPage />
          </Flag>
        ),
        id: RoutesIdEnum.APPOINTMENT_PAGE,
      },
      {
        path: "feedback/:appointment_id",
        element: (
          <Flag
            id="f27904f1-2832-45f8-b12c-0660dab35258"
            description="Enable feedback page"
          >
            <FeedbackPage />
          </Flag>
        ),
        id: RoutesIdEnum.FEEDBACK_PAGE,
      },
      {
        path: "map",
        element: <MapPage />,
        id: RoutesIdEnum.MAP_PAGE,
      },
      {
        path: "contacts",
        element: <ContactsPage />,
        id: RoutesIdEnum.CONTACTS_PAGE,
      },
      {
        path: "join",
        element: <JoinClinic />,
        id: RoutesIdEnum.JOIN_CLINIC,
      },
      {
        path: "survey",
        element: <SurveyPage />,
        id: RoutesIdEnum.SURVEY,
      },
      //Service redirect page
      {
        path: "services/:city/*",
        element: (
          <Flag
            id="33e9f146-f362-4a91-bccf-a734a23dfe39"
            description="Enable services-list redirect page"
            fallback={<NotFoundPage />}
          >
            <ServicesRedirectPage />
          </Flag>
        ),
      },
      {
        path: "impresszum",
        element: (
          <Flag
            id="daf7ebdb-c87f-41df-81f2-4470cfdd2d5f"
            description="Enable GDPR legals"
            fallback={<NotFoundPage />}
          >
            <Impresszum />
          </Flag>
        ),
        id: RoutesIdEnum.IMPRESSZUM,
      },
      {
        path: "privacy_policy",
        element: (
          <>
            <Flag
              id="daf7ebdb-c87f-41df-81f2-4470cfdd2d5f"
              description="Enable GDPR legals"
              fallback={<></>}
            >
              <PrivacyPolicyHu />
            </Flag>
            <Flag
              id="f4dab5c8-c2a2-48a8-b2dc-f4a76c68af0e"
              description="Enable legals for Romania"
              fallback={<></>}
            >
              <PrivacyPolicyRo />
            </Flag>
          </>
        ),
        id: RoutesIdEnum.PRIVACY_POLICY,
      },

      {
        path: "confidentiality",
        element: (
          <Flag
            id="17601a95-3248-4348-b886-8c4b8c8ed757"
            description="Enable privacy-policy for KG"
            fallback={<NotFoundPage />}
          >
            <PrivacyPolicyKg />
          </Flag>
        ),
        id: RoutesIdEnum.PRIVACY_POLICY_KG,
      },
      {
        path: "user_gtc",
        element: (
          <>
            <Flag
              id="daf7ebdb-c87f-41df-81f2-4470cfdd2d5f"
              description="Enable GDPR legals"
              fallback={<></>}
            >
              <UserGTC />
            </Flag>
            <Flag
              id="f4dab5c8-c2a2-48a8-b2dc-f4a76c68af0e"
              description="Enable legals for Romania"
              fallback={<></>}
            >
              <UserGTC_Ro />
            </Flag>
          </>
        ),
        id: RoutesIdEnum.USER_GTC,
      },
      {
        path: "user-agreement",
        element: (
          <Flag
            id="5cd89517-143b-41a3-bd5b-5fe7804c8d06"
            description="Enable user-agreement page for KG"
            fallback={<NotFoundPage />}
          >
            <UserAgreementKg />
          </Flag>
        ),
        id: RoutesIdEnum.USER_AGREEMENT_KG,
      },
      {
        path: "processing-agreement",
        element: (
          <Flag
            id="5cd89517-143b-41a3-bd5b-5fe7804f6"
            description="Enable prossing-agreement page for KG"
            fallback={<NotFoundPage />}
          >
            <ProcessingAgreementKg />
          </Flag>
        ),
        id: RoutesIdEnum.PROCESSING_AGREEMENT_KG,
      },
      {
        path: "404",
        element: <NotFoundPage />,
        id: RoutesIdEnum.NOT_FOUND_PAGE,
      },
      {
        path: "*",
        element: <NotFoundPage />,
      },
    ],
  },
] satisfies RouteObject[];

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(routes);

export const mobileSearchableRoutes = [
  RoutesIdEnum.DOCTORS_LIST,
  RoutesIdEnum.CLINICS_LIST,
  RoutesIdEnum.PROCEDURES_LIST,
  RoutesIdEnum.NOT_FOUND_PAGE,
];
export const cityRoutes = [
  RoutesIdEnum.DOCTORS_LIST,
  RoutesIdEnum.CLINICS_LIST,
  RoutesIdEnum.PROCEDURES_LIST,
  RoutesIdEnum.CLINIC_PROFILE,
];
export const cityAreasRoutes = [RoutesIdEnum.DOCTORS_LIST];

export const pageVisitExceptionsRoutes = [
  RoutesIdEnum.FEEDBACK_PAGE,
  RoutesIdEnum.APPOINTMENT_PAGE,
];

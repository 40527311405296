import { useMutation, type UseMutationOptions } from "@tanstack/react-query";

import { useApi } from "../../context";
import type { GenericErrorResponse } from "../../errors";

export type UseV0RegistrationCodesParams = {
  phone: string;
};
export type UseV0RegistrationCodesResult =
  | {
      ok: true;
      data: {
        id: number;
        phone: string;
        send_sms: boolean;
      };
    }
  | {
      ok: false;
      error: GenericErrorResponse;
    };

type UseV0RegistrationCodesOptions = UseMutationOptions<
  UseV0RegistrationCodesResult,
  unknown,
  UseV0RegistrationCodesParams
>;

export const useV0RegistrationCodesCreate = (
  options?: Partial<UseV0RegistrationCodesOptions>,
) => {
  const { api } = useApi();

  return useMutation({
    ...options,
    mutationFn: (params) => {
      return api(
        {
          url: `/api/v0/registration_codes/`,
          method: "POST",
          data: params,
        },

        {
          201: async (r) => ({
            ok: true as const,
            data: r.data,
          }),
          400: async (r) => ({
            ok: false as const,
            error: r.data,
          }),
          500: async (r) => ({
            ok: false as const,
            error: r.data,
          }),
        },
      );
    },
  });
};

import { useMessage } from "@hermes/intl";
import { cssFns, truthy, usePrincipalColors } from "@hermes/ui";

export const DoctorProfileInfoDetails = ({
  science_degree,
  academic_status,
  experience,
  qualification,
  services,
}: {
  science_degree?: string | null;
  academic_status?: string | null;
  experience?: number | null;
  qualification?: string | null;
  services?: string[];
}) => {
  const message = useMessage();
  const principalColors = usePrincipalColors();

  return (
    <div
      css={[
        cssFns.typo({ weight: "regular", level: "body-1" }),
        { color: principalColors.gs4 },
      ]}
    >
      {services && <div css={{ marginBlockEnd: 6 }}>{services.join(", ")}</div>}
      {Boolean(experience) && (
        <div>
          {[
            message({
              id: "3f451199-ef63-49d7-a91d-b30bd26ca7e9",
              default:
                "Стаж {years} {years, plural, one {год} few {года} other {лет}}",
              values: {
                years: experience,
              },
            }),
            qualification,
            academic_status,
            science_degree,
          ]
            .filter(truthy)
            .join(" / ")}
        </div>
      )}
    </div>
  );
};

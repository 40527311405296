import { useMutation, type UseMutationOptions } from "@tanstack/react-query";

import { useApi } from "../../context";
import type { GenericErrorResponse } from "../../errors";
import type { CreatedAppointment } from "./definitions";

export type UseV1AppointmentValidateParams = {
  slot: number;
  source?: "web-mobile" | "desktop";
  client_name: string;
  client_phone: string;
  client_birthday?: string;
  clinic_branch?: number;
  doctor_service: number;
  start_datetime?: string;
  status?: string;
  code?: string;
  booking_page?: string;
  google_client_id?: string;
  google_session_id?: string;
  google_session_number?: string;
  list_position?: number;
  page_location?: string;
  page_referrer?: string;
  additional_fields: {
    clinic_branch_field: number;
    value: string;
  }[];
  insurance?: number;
};

type UseV1AppointmentValidateResult =
  | {
      ok: true;
      data: Omit<CreatedAppointment, "id" | "hash">;
    }
  | {
      ok: false;
      error: GenericErrorResponse;
    };

type UseV1AppointmentValidateOptions = UseMutationOptions<
  UseV1AppointmentValidateResult,
  unknown,
  UseV1AppointmentValidateParams
>;

export const useV1AppointmentValidate = (
  options?: UseV1AppointmentValidateOptions,
) => {
  const url = "/api/v1/appointments/validate/";
  const { api } = useApi();

  return useMutation<
    UseV1AppointmentValidateResult,
    unknown,
    UseV1AppointmentValidateParams
  >({
    ...options,
    mutationFn(params) {
      return api(
        {
          url,
          method: "POST",
          data: params,
        },
        {
          200: async (r) => ({
            ok: true as const,
            data: r.data,
          }),
          400: async (r) => ({
            ok: false as const,
            error: r.data,
          }),
          500: async (r) => ({
            ok: false as const,
            error: r.data,
          }),
        },
      );
    },
  });
};

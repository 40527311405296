import {
  type AppointmentField,
  useV1ClinicBranchAppointmentFieldsSuspense,
} from "@hermes/api";
import {
  Checkbox,
  type Field,
  type FieldState,
  type FormFields,
  LinearDateField,
  NumberField,
  TextField,
  truthy,
  useCheckboxState,
  useErrors,
  useFormFields,
  useLinearDateFieldState,
  useNumberFieldState,
  useTextFieldState,
} from "@hermes/ui";

import type { BranchServices } from "../doctor-card";

export const useAdditionalFields = ({
  errors,
  branch_services,
}: {
  errors: ReturnType<typeof useErrors>;
  branch_services: BranchServices;
}) => {
  const { data: appointment_fields } =
    useV1ClinicBranchAppointmentFieldsSuspense({
      id: branch_services.branch.id,
    });
  const additionalFields: FormFields<{
    [x: string]: Field<FieldState<any>>;
  }> = useFormFields(
    Object.fromEntries(
      appointment_fields
        .map((field) => {
          const type = Object.values(field.type)[0];

          if (type === "Integer" || type === "Целое") {
            return [
              field.id.toString(),
              useNumberFieldState({
                value: NaN,
                required: field.is_required,
                error: errors.get(field.id.toString()),
                rules: [
                  (v) => (field.max_length ? v <= field.max_length : true),
                  (v) => (field.min_length ? v >= field.min_length : true),
                ],
              }),
            ];
          }

          if (type === "Boolean") {
            return [
              field.id.toString(),
              useCheckboxState({
                value: false,
                required: field.is_required,
              }),
            ];
          }

          if (type === "Date") {
            return [
              field.id.toString(),
              useLinearDateFieldState({
                value: null,
                required: field.is_required,
              }),
            ];
          }

          return [
            field.id.toString(),
            useTextFieldState({
              value: "",
              required: field.is_required,
              rules: [
                (v) => (field.max_length ? v.length <= field.max_length : true),
                (v) => (field.min_length ? v.length >= field.min_length : true),
              ],
              error: errors.get(field.id.toString()),
            }),
          ];
        })
        .filter(truthy),
    ),
  );

  return { additionalFields, appointment_fields };
};

export const AdditionalField = ({
  field,
  info,
}: {
  field: Field<FieldState<any>>;
  info: AppointmentField;
}) => {
  const type = Object.values(info.type)[0];

  if (type === "Integer" || type === "Целое") {
    return (
      <NumberField
        key={info.id}
        field={field}
        label={info.title}
        maxValue={info.max_length}
        minValue={info.min_length}
        formatOptions={{
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          style: "decimal",
          useGrouping: false,
        }}
      />
    );
  }

  if (type === "Boolean") {
    return <Checkbox key={info.id} field={field} label={info.title} />;
  }

  if (type === "Date") {
    return <LinearDateField key={info.id} field={field} label={info.title} />;
  }

  return (
    <TextField
      key={info.id}
      field={field}
      label={info.title}
      maxLength={info.max_length}
      minLength={info.min_length}
    />
  );
};

import { cssFns, useScreenDetector } from "@hermes/ui";
import type { ReactNode } from "react";

export const ModalBodyContainer = ({ children }: { children?: ReactNode }) => {
  const { isMobile } = useScreenDetector();

  return (
    <div
      css={[
        isMobile ? cssFns.padding(10) : cssFns.padding(20, 30),
        {
          display: "flex",
          flexDirection: "column",
        },
      ]}
    >
      {children}
    </div>
  );
};

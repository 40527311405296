import {
  type DoctorService,
  type UseV1DoctorsServicesListParallelResult,
} from "@hermes/api";
import { Temporal } from "@hermes/intl";
import { type UseQueryResult } from "@tanstack/react-query";

/**
 * Для указанных сервисов врачей создает отрезки дат
 * в диапазоне которых нас будут интересовать слоты
 */
export const getSlotsDateRanges = ({
  dateRangeLength = 4,
  maxDaysPerRange = 7,
  queries,
  today,
}: {
  /**
   * Длинна основного отрезка или сколько дней слотов подтягивать,
   * включая стартовую дату, например:
   * - desktop: from + 3 days = 4
   * - mobile: from + 0 days = 1
   */
  dateRangeLength?: number;
  /**
   * Начиная от основного отрезка, сколько дней может войти в него
   * прежде чем придется начать новый
   *
   * Должно быть больше чем dateRangeLength
   */
  maxDaysPerRange?: number;
  queries: UseQueryResult<UseV1DoctorsServicesListParallelResult, Error>[];
  today: Temporal.PlainDate;
}) => {
  if (maxDaysPerRange < dateRangeLength) {
    throw new Error(
      "[getSlotsDateRanges]: maxDaysPerRange must be greater than dateRangeLength",
    );
  }

  return (
    queries
      // Достаем результаты каждой страницы и фильтруем пустые
      .map((query) => query.data?.results)
      .map((doctorServices) => {
        if (!doctorServices) return [];
        // Группируем сервисы по датам
        const groupedServices = doctorServices.reduce(
          (acc, service) => {
            const nearestDate = service.nearest_slot_datetime
              ? Temporal.PlainDate.from(service.nearest_slot_datetime)
              : today;

            // Используем дату как ключ
            // Нам не нужны даты раньше сегодняшнего дня,
            // если nearest day раньше today то просто ставим today
            // Все ранние даты войдут в отрезок от сегодняшнего дня
            const key =
              Temporal.PlainDate.compare(
                Temporal.PlainDate.from(nearestDate),
                today,
              ) >= 0
                ? nearestDate.toString()
                : today.toString();

            const services = acc[key] ?? [];
            services.push(service);
            acc[key] = services;

            return acc;
          },
          {} as Record<string, DoctorService[]>,
        );

        const sortedDates = Object.keys(groupedServices)
          // Сортируем даты по возрастанию
          .sort((a, b) =>
            Temporal.PlainDate.compare(
              Temporal.PlainDate.from(a),
              Temporal.PlainDate.from(b),
            ),
          );

        // Сворачиваем даты в диапазоны
        const ranges = sortedDates.reduce(
          (acc, currentDateKey) => {
            const currentDate = Temporal.PlainDate.from(currentDateKey);
            const lastRange = acc[acc.length - 1];

            const doctorServices = groupedServices[currentDateKey] || [];
            const services = doctorServices.map((service) => service.service);
            const doctors = doctorServices.map((service) => service.doctor);

            if (
              !lastRange ||
              currentDate.since(lastRange.date_from).total({ unit: "days" }) >=
                maxDaysPerRange
            ) {
              // В случае создания нового/первого отрезка обязательно
              // добавляем к currentDate, dateRangeLength - 1 дней чтобы захватить
              // изначальный отрезок от возможного сегодняшнего дня
              return [
                ...acc,
                {
                  date_from: currentDate,
                  date_to: currentDate.add({ days: dateRangeLength - 1 }),
                  services: new Set(services),
                  doctors: new Set(doctors),
                },
              ];
            }

            // Если в дальнейшем найдутся дни что будут толкать границу отрезка
            // вперед, то к ним прибавлять dateRangeLength - 1 уже не нужно
            lastRange.date_to =
              Temporal.PlainDate.compare(lastRange.date_to, currentDate) >= 0
                ? lastRange.date_to
                : currentDate;
            lastRange.services = new Set([...lastRange.services, ...services]);
            lastRange.doctors = new Set([...lastRange.doctors, ...doctors]);

            return acc;
          },
          [] as {
            date_from: Temporal.PlainDate;
            date_to: Temporal.PlainDate;
            services: Set<number>;
            doctors: Set<number>;
          }[],
        );

        return ranges.map((range) => ({
          date_from: range.date_from.toString(),
          date_to: range.date_to.toString(),
          services: [...range.services],
          doctors: [...range.doctors],
        }));
      })
  );
};

import type {
  DOMAttributes,
  FocusableElement,
  Node,
} from "@react-types/shared";
import { type ReactNode, type RefObject, useRef } from "react";
import { useMenuItem, useSubmenuTrigger } from "react-aria";
import { useSubmenuTriggerState, useTreeState } from "react-stately";

import { cssFns } from "../css";
import { DesktopPopupDone, MobileArrowRight } from "../icons";
import { renderContent } from "../overlays/content";
import { Modal, useModalState } from "../overlays/modal";
import { Popover } from "../overlays/popover";
import { useScreenDetector } from "../screen-type";
import { Spinner } from "../spinner";
import { usePrincipalColors } from "../theme";
import { Menu } from ".";
import { useOptions } from "./options";

export function MenuItem({ item }: { item: Node<{}> }) {
  const options = useOptions();

  if (item.hasChildNodes && options.rootTriggerState) {
    return <SubMenuItemContent item={item} />;
  }

  return <MenuItemContent item={item} />;
}

function MenuItemContent({ item }: { item: Node<{}> }) {
  const ref = useRef<HTMLLIElement>(null);
  const { state, closeOnSelect } = useOptions();

  const isSelected = state.selectionManager.isSelected(item.key);

  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isSelected,
      closeOnSelect,
      "aria-label": item.props.textValue,
    },
    state,
    ref,
  );

  return <ListItem liRef={ref} item={item} menuItemProps={menuItemProps} />;
}

function SubMenuItemContent({ item }: { item: Node<{}> }) {
  const principalColors = usePrincipalColors();
  const ref = useRef<HTMLLIElement>(null);
  const submenuRef = useRef<HTMLUListElement>(null);

  const {
    state,
    rootTriggerState,
    onSubmenuOpen,
    onSubmenuClose,
    isModalSubmenu: isSubmenuModalRendering,
    mobile,
    getOption,
    renderItem,
    closeOnSelect,
    parentMenuRef,
  } = useOptions();

  const submenuTriggerState = useSubmenuTriggerState(
    { triggerKey: item.key },
    rootTriggerState!,
  );
  const { submenuTriggerProps, popoverProps, submenuProps } = useSubmenuTrigger(
    {
      parentMenuRef,
      node: item,
      submenuRef,
      delay: 200,
      type: "menu",
    },
    {
      ...submenuTriggerState,
      open(...args) {
        onSubmenuOpen?.(String(item.key));
        submenuTriggerState.open(...args);
      },
      close(...args) {
        onSubmenuClose?.(String(item.key));
        submenuTriggerState.close(...args);
      },
    },
    ref,
  );

  const isSelected = state.selectionManager.isSelected(item.key);

  const { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isSelected,
      closeOnSelect,
      "aria-label": item.props.textValue,
      ...submenuTriggerProps,
    },
    state,
    ref,
  );

  const submenuState = useTreeState({
    selectionMode: "single",
    selectedKeys: state.selectionManager.selectedKeys,
    children: item.props.childItems,
    onSelectionChange(keys) {
      state.selectionManager.setSelectedKeys(keys);
    },
  });

  const content = !item.props.areChildItemsLoading ? (
    <Menu
      menuRef={submenuRef}
      menuProps={submenuProps as any}
      options={{
        state: submenuState,
        rootTriggerState,
        getOption,
        renderItem,
        mobile,
        closeOnSelect,
      }}
      isSubmenu
    />
  ) : (
    <div
      css={[
        cssFns.center(),
        cssFns.paddingBlock("10px"),
        { minWidth: "140px" },
      ]}
    >
      <Spinner />
    </div>
  );

  const modalState = useModalState({
    parentState: submenuTriggerState,
  });

  return (
    <>
      <ListItem
        liRef={ref}
        item={item}
        menuItemProps={menuItemProps}
        icon={<MobileArrowRight color={principalColors.ebblue} />}
      />
      {isSubmenuModalRendering
        ? modalState.isOpen && (
            <Modal
              state={modalState}
              onClose={() => {
                modalState.close();
              }}
              title={renderContent(mobile?.submenuTitle ?? mobile?.title, {
                close() {
                  submenuTriggerState.close();
                },
              })}
              main={content}
              slideDirection="left"
            />
          )
        : submenuTriggerState.isOpen && (
            <Popover
              triggerRef={ref}
              popoverProps={popoverProps}
              state={submenuTriggerState}
              maxHeight={350}
              placement="end top"
            >
              {content}
            </Popover>
          )}
    </>
  );
}

function ListItem({
  liRef,
  item,
  menuItemProps,
  icon,
}: {
  liRef: RefObject<HTMLLIElement>;
  item: Node<{}>;
  menuItemProps: DOMAttributes<FocusableElement>;
  icon?: ReactNode;
}) {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const { state, getOption, renderItem: RenderItem } = useOptions();

  const isSelected = state.selectionManager.isSelected(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  const option = getOption?.(item.key);

  if (option && RenderItem) {
    return (
      <li ref={liRef} {...menuItemProps}>
        <RenderItem
          isSelected={isSelected}
          isFocused={isFocused}
          option={option}
          rendered={item.rendered}
        />
      </li>
    );
  }

  return (
    <li
      ref={liRef}
      css={[
        cssFns.typo({ level: "body-1", weight: "regular" }),
        isMobile
          ? cssFns.padding("12px", "16px")
          : cssFns.padding("10px", "16px"),
        isMobile &&
          cssFns.borderBlockEnd({
            color: principalColors.grayline,
            style: "solid",
            width: "1px",
          }),
        {
          display: "grid",
          alignItems: "center",
          columnGap: "4px",
          gridTemplateColumns: "1fr 20px",
          backgroundColor: isMobile
            ? undefined
            : isFocused
              ? principalColors.grayline
              : undefined,
          outlineStyle: "none",
          cursor: "pointer",
          color: isSelected ? principalColors.ebblue : principalColors.gs2,
          gap: "4px",
          paddingInlineEnd: "16px",
          paddingInlineStart: (item.level + 1) * 16,
        },
      ]}
      {...menuItemProps}
    >
      {item.props.textValue ?? item.rendered}
      <div css={{ width: 20, height: 20 }}>
        {icon ??
          (isSelected && (
            <DesktopPopupDone aria-hidden color={principalColors.ebblue} />
          ))}
      </div>
    </li>
  );
}

import { type City, type CityArea, type DoctorsListOrder } from "@hermes/api";
import { Message, Temporal } from "@hermes/intl";
import type { GeoPosition } from "@hermes/shared";
import { cssFns, usePrincipalColors, useScreenDetector } from "@hermes/ui";
import { Fragment } from "react";

import { DoctorCard, type DoctorCardService } from "../doctor-card";
import { DoctorsListLoading } from "./loading";
import { useDoctorsPages } from "./request-blocks";

export const DoctorsList = ({
  sort,
  targetServices,
  position,
  city,
  cityArea,
  clinicId,
  isBordered,
  displayCount,
  slotDate,
  enablePromo,
  pageHeader,
  onPrerenderLoaded,
  language,
  insurance,
}: {
  sort?: DoctorsListOrder;
  targetServices?: DoctorCardService[];
  position?: GeoPosition;
  city: City;
  cityArea?: CityArea;
  clinicId?: number;
  isBordered?: boolean;
  displayCount?: boolean;
  slotDate?: Temporal.PlainDate;
  enablePromo?: boolean;
  pageHeader: string;
  onPrerenderLoaded?: () => void;
  language?: string;
  insurance?: number;
}) => {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();

  const { pages, count, fetchPage, limit, premiumCount } = useDoctorsPages({
    city,
    cityArea,
    targetServices,
    clinicId,
    sort,
    position,
    slotDate,
    enablePromo,
    onPrerenderLoaded,
    language,
    insurance,
  });

  const FETCH_ON_INDEX = isMobile ? 3 : 6;

  return (
    <div>
      {displayCount && (
        <div
          css={{
            margin: isMobile ? "0 0 10px" : "0 0 20px",
          }}
        >
          <h2
            css={[
              {
                margin: 0,
                color: principalColors.gs4,
              },
              cssFns.typo({ level: "body-1", weight: "regular" }),
            ]}
          >
            <Message
              id="2c5660dc-ff4f-4326-a6ba-93d3dba66f13"
              default="Найдено врачей: {count}"
              values={{
                count,
              }}
            />
          </h2>
        </div>
      )}

      <div
        css={{
          display: "flex",
          flexDirection: "column",
          rowGap: 16,
          marginBlockEnd: 16,
        }}
      >
        {pages.map((page, pageIndex) => (
          <Fragment key={pageIndex}>
            {!page.seen || !page.cards?.length ? (
              <DoctorsListLoading
                limit={page.size}
                isBordered
                onInView={() => fetchPage(pageIndex)}
              />
            ) : (
              page.cards.map(
                ({ doctor, branches_services, initialSlots }, index) => {
                  const list_position = doctor.premium
                    ? 0
                    : pageIndex * limit + index + 1 - premiumCount;
                  const shouldTriggerFetch =
                    pageIndex === 0
                      ? index + 1 - premiumCount >= FETCH_ON_INDEX
                      : index + 1 >= FETCH_ON_INDEX;

                  return (
                    <DoctorCard
                      key={`${doctor.id}-${doctor.premium}`}
                      defaultStartDate={slotDate}
                      doctor={doctor}
                      city={city}
                      cityArea={cityArea}
                      branches_services={branches_services}
                      initialSlots={initialSlots}
                      isBordered={isBordered}
                      request_meta={{
                        list_position: Math.max(list_position, 0),
                        booking_page: pageHeader,
                      }}
                      areSlotsEmpty={page.areSlotsEmpty}
                      areServicesLoading={page.areServicesFetching}
                      areBranchesLoading={page.areBranchesFetching}
                      areSlotsLoading={page.areSlotsFetching}
                      heading={!!clinicId ? "h3" : undefined}
                      noRedirectToClinic={!!clinicId}
                      premium={doctor.premium}
                      onInView={() => {
                        if (shouldTriggerFetch) {
                          fetchPage(pageIndex + 1);
                        }
                      }}
                      pageHeader={pageHeader}
                    />
                  );
                },
              )
            )}
          </Fragment>
        ))}
      </div>

      {count === undefined && <DoctorsListLoading limit={limit} isBordered />}
    </div>
  );
};

import type { Insurance } from "@hermes/api";
import { useFlag } from "@hermes/flags";
import type { TimeSlot } from "@hermes/shared";
import {
  type FormErrorsContainer,
  useButtonFieldState,
  useCheckboxState,
  useFormFields,
  useSelectState,
  useTextFieldState,
} from "@hermes/ui";

import type { BranchServices } from "#internal/features/doctor-card";

export const useAppointmentFormFields = ({
  branch_services,
  initialSlot,
  errors,
  enableConsent,
}: {
  branch_services: BranchServices;
  initialSlot: TimeSlot | undefined;
  errors: FormErrorsContainer;
  enableConsent: boolean;
}) => {
  const flag = useFlag();

  return useFormFields(() => ({
    service: useSelectState({
      value:
        initialSlot?.slot.service ??
        branch_services.services[0]?.service ??
        null,
      required: true,
      validateInitially: true,
      error: errors.get("doctor_service"),
    }),
    slot: useButtonFieldState({
      value: initialSlot ?? null,
      required: true,
      disabled: !branch_services.services[0]?.service,
      validateInitially: true,
      error: errors.get("slot"),
    }),
    name: useTextFieldState({
      value: "",
      required: true,
      error: errors.get("client_name"),
    }),
    surname: useTextFieldState({
      value: "",
      required: flag({ id: "appointment-modal.enable-surname" }),
      error: errors.get("full_name"),
    }),
    phone: useTextFieldState({
      value: "",
      required: true,
      rules: [(v) => !v.includes("_")],
      error: errors.get("client_phone"),
    }),
    consent: useCheckboxState({
      value: false,
      required: enableConsent,
      error: errors.get("consent"),
    }),
  }));
};

export const useAppointmentOtpFields = (errors: FormErrorsContainer) => {
  return useFormFields(() => ({
    code: useTextFieldState({
      value: "",
      required: true,
      validateInitially: false,
      showValidationStatus: false,
      rules: [(value) => !value.includes("-")],
      error: errors.get("otp"),
    }),
  }));
};

export const useAppointmentInsuranceFields = ({
  insurances,
}: {
  insurances: Insurance[];
}) => {
  return useFormFields(() => ({
    select: useSelectState({
      value: insurances[0] ?? null,
      required: true,
      validateInitially: false,
      showValidationStatus: false,
    }),
    checkbox: useCheckboxState({
      value: false,
    }),
  }));
};

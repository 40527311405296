import { usePatternFormat } from "react-number-format";

import {
  BaseTextField,
  type BaseTextFieldProps,
  type TextFieldState,
} from "./text-field";

type PhoneFieldState = TextFieldState;

export const PhoneField = ({
  pattern,
  ...props
}: BaseTextFieldProps<PhoneFieldState> & {
  pattern: string;
  unmask?: boolean;
}) => {
  const patternProps = usePatternFormat({
    format: pattern,
    allowEmptyFormatting: true,
    mask: "_",
    type: "tel",
    inputMode: "tel",
  });

  return (
    <BaseTextField
      type="tel"
      inputMode="tel"
      autoComplete="tel"
      patternProps={patternProps}
      {...props}
    />
  );
};

import { Temporal, useLocale } from "@hermes/intl";
// TODO! Remove after rewriting in react-aria
import { enUS as en, hu, kk, ro, ru } from "date-fns/locale";
import { type ButtonHTMLAttributes, type SVGProps } from "react";
import { DayPicker, type Matcher, type PropsBase } from "react-day-picker";

import { cssFns } from "../css";
import { usePrincipalColors } from "../theme";

const PickerRightButtonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" {...props}>
      <path
        d="M0.999999 1L1 13.5L10.5 7.5L0.999999 1Z"
        stroke="#B2B9BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PickerLeftButtonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="12" height="15" viewBox="0 0 12 15" fill="none" {...props}>
      <path
        d="M10.5 13.5V1L1 7L10.5 13.5Z"
        stroke="#B2B9BE"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const PickerButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <button {...props}>
      {props.className?.includes("previous") ? (
        <PickerLeftButtonIcon />
      ) : (
        <PickerRightButtonIcon />
      )}
    </button>
  );
};

export const Calendar = ({
  value,
  onChange,
  disabled,
  maxDate,
  minDate,
}: {
  value?: Temporal.PlainDate;
  onChange?: (value?: Temporal.PlainDate) => void;
  disabled?: PropsBase["disabled"];
  maxDate?: Temporal.PlainDate;
  minDate?: Temporal.PlainDate;
}) => {
  const [locale] = useLocale();
  const principalColors = usePrincipalColors();

  const date = value ? new Date(value.toString()) : undefined;

  const beforeMatcher: Matcher | undefined = minDate
    ? { before: new Date(minDate.toString()) }
    : undefined;

  const afterMatcher: Matcher | undefined = maxDate
    ? { after: new Date(maxDate.toString()) }
    : undefined;

  const hiddenDates: Matcher[] | undefined = [
    beforeMatcher,
    afterMatcher,
  ].filter(Boolean) as Matcher[];

  return (
    <DayPicker
      autoFocus={false}
      month={value ? new Date(value.toString()) : undefined}
      fixedWeeks
      showOutsideDays
      css={[
        cssFns.typo({ level: "body-1", weight: "regular" }),
        {
          "*:focus-visible": {
            outlineColor: principalColors.ebblue,
            outlineStyle: "solid",
            outlineWidth: 2,
            borderRadius: 4,
          },
          ".rdp-nav": {
            left: "0px",
            top: "5px",
          },
          ".rdp-button_previous, .rdp-button_next": {
            position: "absolute",
          },
          ".rdp-button_previous": {
            left: 60,
          },
          ".rdp-button_next": {
            right: 60,
          },
          ".rdp-months": {
            maxWidth: "none",
            "@media(max-width:1024px)": {
              display: "block",
              table: {
                width: "100%",
              },
            },
          },
          ".rdp-month_caption": [
            cssFns.padding("18px", "0px"),
            cssFns.borderBlockEnd({
              color: principalColors.grayline,
              style: "solid",
              width: "1px",
            }),
            {
              justifyContent: "center",
              height: "auto",
              marginBlockEnd: "12px",
            },
          ],
          ".rdp-caption_label, [role='columnheader']": [
            cssFns.typo({ level: "body-1", weight: "semibold" }),
            {
              color: principalColors.gs2,
              textTransform: "capitalize",
            },
          ],
          ".rdp-month_wrapper": {
            flexGrow: 1,
            paddingBlockEnd: "16px",
          },
          ".rdp-selected, .rdp-today, .rdp-day": [
            cssFns.typo({ level: "body-1", weight: "regular" }),
            {
              color: principalColors.gs2,
            },
          ],
          ".rdp-day": [
            cssFns.paddingInline(4),
            {
              width: 40,
              height: 40,
              "&_button": {
                width: 40,
                height: 40,
              },
            },
          ],
          ".rdp-selected": [
            cssFns.border({ radius: "4px", width: "0px" }),
            {
              color: principalColors.white,
              position: "relative",
              zIndex: 1,
              button: {
                borderColor: "transparent",
              },
              ":after": {
                backgroundColor: principalColors.ebblue,
                content: "' '",
                zIndex: -1,
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 40,
                height: 40,
                borderRadius: 4,
              },
            },
          ],
        },
      ]}
      mode="single"
      disabled={disabled}
      locale={
        {
          en,
          hu,
          kk,
          ro,
          ru,
        }[locale.baseName]
      }
      components={{
        Button: PickerButton,
      }}
      hidden={hiddenDates}
      selected={date}
      onSelect={(date) => {
        if (date) {
          const pre = (v: number) => (v < 10 ? "0" + v : v);
          const plainDate = `${date.getFullYear()}-${pre(date.getMonth() + 1)}-${pre(date.getDate())}`;

          onChange?.(Temporal.PlainDate.from(plainDate));
        } else {
          onChange?.(undefined);
        }
      }}
    />
  );
};

import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export const ImageLoader = ({
  src,
  alt,
  title,
  objectFit = "cover",
}: {
  src: string;
  alt: string;
  title?: string;
  objectFit?: "contain" | "cover" | "fill" | "none" | "scale-down";
}) => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {loading && <Skeleton height="100%" />}
      <img
        css={{
          objectFit: objectFit,
          width: "100%",
          height: "100%",
          opacity: loading ? 0 : 1,
        }}
        src={src}
        alt={alt}
        title={title}
        onLoadStart={() => {
          setLoading(true);
        }}
        onLoad={() => {
          setLoading(false);
        }}
        loading="lazy"
      />
    </>
  );
};

import "react-day-picker/dist/style.css";

import { Temporal } from "@hermes/intl";
import { type ReactNode, type SVGProps } from "react";
import type { Placement } from "react-aria";
import { type PropsBase } from "react-day-picker";

import type { TriggerChildrenProps } from "../button/trigger-button";
import { cssFns } from "../css";
import { Select } from "../fields/selectable/select";
import type { OverlayContentOptions } from "../overlays/content";
import { DynamicPopover } from "../overlays/dynamic-popover";
import { Calendar } from "./day-picker-wrapper";

const PickerCloseButtonIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M13.5303 1.53033C13.8232 1.23744 13.8232 0.762563 13.5303 0.46967C13.2374 0.176777 12.7626 0.176777 12.4697 0.46967L7 5.93934L1.53033 0.46967C1.23744 0.176777 0.762563 0.176777 0.46967 0.46967C0.176777 0.762563 0.176777 1.23744 0.46967 1.53033L5.93934 7L0.46967 12.4697C0.176777 12.7626 0.176777 13.2374 0.46967 13.5303C0.762563 13.8232 1.23744 13.8232 1.53033 13.5303L7 8.06066L12.4697 13.5303C12.7626 13.8232 13.2374 13.8232 13.5303 13.5303C13.8232 13.2374 13.8232 12.7626 13.5303 12.4697L8.06066 7L13.5303 1.53033Z"
        fill="#B2B9BE"
      />
    </svg>
  );
};

export const DatePicker = ({
  value,
  onChange,
  children,
  disabled,
  placement,
  offset,
  minDate,
  maxDate,
}: {
  value?: Temporal.PlainDate;
  onChange?: (value?: Temporal.PlainDate) => void;
  disabled?: PropsBase["disabled"];
  placement?: Placement;
  offset?: number;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
} & TriggerChildrenProps) => {
  return (
    <CalendarPopover
      calendar={(options) => (
        <Calendar
          minDate={minDate}
          maxDate={maxDate}
          value={value}
          onChange={(value) => {
            onChange?.(value);
            options.close();
          }}
          disabled={disabled}
        />
      )}
      placement={placement}
      offset={offset}
      children={children}
    />
  );
};

export const DateTimePicker = ({
  value = Temporal.Now.plainDateTimeISO(),
  onChange,
  children,
  disabled,
  placement,
  offset,
  minDate,
  maxDate,
}: {
  value?: Temporal.PlainDateTime;
  onChange?: (value?: Temporal.PlainDateTime) => void;
  disabled?: PropsBase["disabled"];
  placement?: Placement;
  offset?: number;
  minDate?: Temporal.PlainDate;
  maxDate?: Temporal.PlainDate;
} & TriggerChildrenProps) => {
  const date = value.toPlainDate();
  const time = value.toPlainTime();

  const renderTime = (time: number) =>
    (time < 10 ? "0" + time : time).toString();

  return (
    <CalendarPopover
      calendar={() => (
        <Calendar
          value={date}
          onChange={(date) => {
            if (!date) {
              onChange?.(undefined);
              return;
            }

            onChange?.(date.toPlainDateTime(value.toPlainTime()));
          }}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
        />
      )}
      time={
        <div css={[cssFns.padding(4), cssFns.center()]}>
          <div
            css={[
              {
                display: "grid",
                gridTemplateColumns: "80px max-content 80px",
                alignItems: "center",
                gap: 8,
              },
            ]}
          >
            <Select
              field={{
                value: renderTime(time.hour),
                onChange(state) {
                  if ("value" in state) {
                    onChange?.(
                      date.toPlainDateTime(
                        typeof state.value !== "undefined" &&
                          state.value !== null
                          ? value
                              .toPlainTime()
                              .with({ hour: parseInt(state.value) })
                          : value.toPlainTime(),
                      ),
                    );
                  }
                },
              }}
              options={Array.from({ length: 24 }).map((_, i) => {
                const time = renderTime(i);

                return {
                  label: time,
                  value: time,
                };
              })}
              showArrowDown={false}
            />
            <span css={{ fontSize: 20 }}>:</span>
            <Select
              field={{
                value: renderTime(time.minute),
                onChange(state) {
                  if ("value" in state) {
                    onChange?.(
                      date.toPlainDateTime(
                        typeof state.value !== "undefined" &&
                          state.value !== null
                          ? value
                              .toPlainTime()
                              .with({ minute: parseInt(state.value) })
                          : value.toPlainTime(),
                      ),
                    );
                  }
                },
              }}
              options={Array.from({ length: 60 }).map((_, i) => {
                const time = renderTime(i);

                return {
                  label: time,
                  value: time,
                };
              })}
              showArrowDown={false}
            />
          </div>
        </div>
      }
      placement={placement}
      offset={offset}
      children={children}
    />
  );
};

function CalendarPopover({
  children,
  placement,
  offset,
  calendar,
  time,
}: {
  placement?: Placement;
  offset?: number;
  calendar: (options: OverlayContentOptions) => ReactNode;
  time?: ReactNode;
} & TriggerChildrenProps) {
  return (
    <DynamicPopover
      mobile={{
        closeButton: false,
        height: "430px",
      }}
      desktop={{
        width: "auto",
        placement,
        offset,
      }}
      content={(options) => (
        <div
          css={[
            cssFns.boxShadow({
              offsetX: "0px",
              offsetY: "0px",
              blurRadius: "20px",
              color: "#f1f1f1",
            }),
            {
              width: "100%",
              height: "100%",
              "@media(max-width:1024px)": {
                boxShadow: "none",
              },
              position: "relative",
            },
          ]}
        >
          <button
            css={{
              position: "absolute",
              right: "16px",
              top: "18px",
              zIndex: 1,
              border: "none",
              background: "none",
              outline: "none",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              options.close();
            }}
          >
            <PickerCloseButtonIcon />
          </button>
          {calendar(options)}
          {time}
        </div>
      )}
    >
      {(triggerRef, triggerProps) => children?.(triggerRef, triggerProps)}
    </DynamicPopover>
  );
}

import {
  useQueries,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { PaginatedResponse } from "../common";
import type { Slot } from "./definitions";

type UseV1SlotsListParams = {
  service?: number | number[];
  doctor?: number | number[];
  clinic_branch?: number | number[];
  /**
   * From date (%Y-%m-%d) default Now
   */
  date_from?: string;
  /**
   * To date (%Y-%m-%d)
   */
  date_to?: string;
};
export type UseV1SlotsListResult = PaginatedResponse<Slot[]>;

type UseV1SlotsListOptions = UseQueryOptions<UseV1SlotsListResult>;

export const useV1SlotsList = (
  params: UseV1SlotsListParams,
  options?: Partial<UseV1SlotsListOptions>,
) => {
  const url = "/api/v1/slots/";
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api<UseV1SlotsListResult>(
        {
          url,
          method: "GET",
          params: {
            ...params,
            doctor: Array.isArray(params.doctor)
              ? params.doctor.join(",")
              : params.doctor,
            service: Array.isArray(params.service)
              ? params.service.join(",")
              : params.service,
            clinic_branch: Array.isArray(params.clinic_branch)
              ? params.clinic_branch.join(",")
              : params.clinic_branch,
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1SlotsListParallelParams = {
  dateRanges: {
    date_from: string;
    date_to: string;
    doctors: number[];
    services: number[];
  }[][];
};

export type UseV1SlotsListParallelResult = PaginatedResponse<Slot[]>;

type UseV1SlotsListParallelOptions =
  UseQueryOptions<UseV1SlotsListParallelResult>;

export const useV1SlotsListParallel = (
  { dateRanges }: UseV1SlotsListParallelParams,
  options?: Partial<UseV1SlotsListParallelOptions>,
) => {
  const url = "/api/v1/slots/";
  const { api } = useApi();

  return useQueries({
    queries: dateRanges.flatMap((page) => {
      const enabled = page.some(
        (range) => range.doctors.length > 0 && range.services.length > 0,
      );
      return {
        ...options,
        enabled:
          options?.enabled !== undefined ? options.enabled && enabled : enabled,
        queryKey: [url, page],
        queryFn: async () => {
          return Promise.all(
            page.map((range) =>
              api(
                {
                  url,
                  method: "GET",
                  params: {
                    doctor: range.doctors.join(","),
                    service: range.services.join(","),
                    date_from: range.date_from,
                    date_to: range.date_to,
                  },
                },
                {
                  200: async (r) => r.data as PaginatedResponse<Slot[]>,
                },
              ),
            ),
          ).then((query) => {
            return {
              count: query.reduce((acc, r) => acc + r.count, 0),
              results: query.flatMap((slots) => slots.results),
              next: null,
              previous: null,
            } as PaginatedResponse<Slot[]>;
          });
        },
      };
    }),
  });
};

import { Message, useFormatNumber, useMessage } from "@hermes/intl";
import { cssFns, usePrincipalColors, useSecondaryColors } from "@hermes/ui";
import type { SVGProps } from "react";

export const AppointmentInfoBlock = ({
  liveQueue = false,
  avgWaitingTime = 0,
  conditions,
}: {
  liveQueue?: boolean;
  avgWaitingTime?: number;
  conditions?: string;
}) => {
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const message = useMessage();
  const formatNumber = useFormatNumber();

  return (
    <div
      css={[
        cssFns.border({ radius: 12 }),
        cssFns.padding(16, 10),
        {
          display: "flex",
          flexDirection: "column",
          gap: 16,
          backgroundColor: cssFns.setOpacity(secondaryColors.magenta, 0.05),
          maxWidth: 540,
        },
      ]}
    >
      <div
        css={[
          cssFns.typo({ level: "body-1", weight: "semibold" }),
          {
            color: secondaryColors.magenta,
            display: "flex",
            gap: 10,
          },
        ]}
      >
        <InfoIcon />
        <Message
          id="8fa519ab-0d4c-4ed9-b700-94cb17870172"
          default="Важно, пожалуйста, прочитайте!"
        />
      </div>
      <div
        css={[
          cssFns.typo({ level: "body-1", weight: "regular" }),
          {
            color: principalColors.gs2,
            paddingInlineEnd: 20,
            paddingInlineStart: 28,
            strong: {
              fontWeight: "inherit",
            },
          },
        ]}
      >
        {liveQueue && (
          <p css={{ marginBlockEnd: 8 }}>
            {message({
              id: "54e47041-eaa7-47ff-84a5-9a1a5b7500bb",
              default:
                "Врач принимает пациентов по живой очереди. Запишитесь через наш сервис. Приходите вовремя и избегайте пересечения с другими пациентами.",
            })}
            {Boolean(avgWaitingTime) &&
              message({
                id: "65161dc6-1ddb-4bcd-a0d6-9b1b2313c2cd",
                default:
                  "Среднее время ожидания у данного врача - {avgWaitingTime} {avgWaitingTime, plural, one {минута} few {минуты} other {минут}}.",
                values: {
                  avgWaitingTime: formatNumber(avgWaitingTime, {
                    maximumFractionDigits: 0,
                    minimumFractionDigits: 0,
                  }),
                },
              })}
          </p>
        )}
        {conditions && <p dangerouslySetInnerHTML={{ __html: conditions }} />}
      </div>
    </div>
  );
};

const InfoIcon = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" {...props}>
      <circle cx="9" cy="9" r="8.25" stroke="currentColor" strokeWidth="1.5" />
      <path
        d="M9 13V7M9 6V5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

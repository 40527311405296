import { type UseQueryOptions } from "@tanstack/react-query";

import { useApi } from "../../context";
import { type PaginatedResponse, useHermesQuery } from "../common";
import type { Doctor } from "./definitions";

type UseV1PremiumDoctorsListParams = {
  city?: number;
  expand?: (
    | "clinic_branches"
    | "details"
    | "meta_title"
    | "meta_description"
    | "photos"
    | "videos"
  )[];
  service?: number[];
};
type UseV1PremiumDoctorsListResult = PaginatedResponse<Doctor[]>;
type UseV1PremiumDoctorsListOptions =
  UseQueryOptions<UseV1PremiumDoctorsListResult>;

export const useV1PremiumDoctorsList = (
  params: UseV1PremiumDoctorsListParams,
  options?: Partial<UseV1PremiumDoctorsListOptions>,
) => {
  const { api } = useApi();
  const url = `/api/v1/doctors/premium/`;

  return useHermesQuery({
    queryKey: [url, params],
    queryFn: async () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            service: params.service?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
    ...options,
  });
};

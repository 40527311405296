import { css } from "@emotion/react";
import { type ReactNode, useRef } from "react";
import {
  mergeProps,
  type Placement,
  useDialog,
  useOverlayTrigger,
} from "react-aria";
import { useOverlayTriggerState } from "react-stately";

import type { TriggerChildrenProps } from "../button/trigger-button";
import { useScreenDetector } from "../screen-type";
import type { SizeUnit } from "../utils";
import {
  type Content,
  type OverlayContentOptions,
  renderContent,
} from "./content";
import { Modal, useModalState } from "./modal";
import { Popover } from "./popover";

export const DynamicPopover = ({
  content,
  mobile,
  desktop,
  children,
}: {
  content?: Content<OverlayContentOptions>;
  mobile?: {
    title?: ReactNode;
    content?: Content<OverlayContentOptions>;
    footer?: Content<OverlayContentOptions>;
    closeButton?: boolean;
    height?: SizeUnit;
  };
  desktop?: {
    width?: string;
    placement?: Placement;
    offset?: number;
    isNonModal?: boolean;
  };
} & TriggerChildrenProps) => {
  const { isMobile } = useScreenDetector();

  const triggerRef = useRef<HTMLButtonElement>(null);
  const state = useOverlayTriggerState({
    defaultOpen: false,
  });
  const { triggerProps, overlayProps } = useOverlayTrigger(
    { type: "dialog" },
    state,
    triggerRef,
  );

  const dialogRef = useRef(null);
  const { dialogProps } = useDialog({}, dialogRef);

  const contentOptions = {
    close: () => {
      state.close();
    },
  };

  const modalState = useModalState({
    parentState: state,
  });

  return (
    <>
      {children?.(triggerRef, triggerProps)}
      {isMobile
        ? modalState.isOpen && (
            <Modal
              state={modalState}
              onClose={() => {
                modalState.close();
              }}
              title={mobile?.title}
              main={renderContent(mobile?.content ?? content, contentOptions)}
              footer={renderContent(mobile?.footer, contentOptions)}
              disableCloseButton={!mobile?.closeButton}
              styles={
                mobile?.height ? css({ height: mobile.height }) : undefined
              }
            />
          )
        : state.isOpen && (
            <Popover
              triggerRef={triggerRef}
              state={state}
              offset={desktop?.offset}
              placement={desktop?.placement}
              isNonModal={desktop?.isNonModal}
            >
              <div
                {...mergeProps(dialogProps, overlayProps)}
                ref={dialogRef}
                css={{
                  width: desktop?.width ?? triggerRef.current?.clientWidth,
                }}
              >
                {renderContent(content, contentOptions)}
              </div>
            </Popover>
          )}
    </>
  );
};

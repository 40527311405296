import { useMutation, type UseMutationOptions } from "@tanstack/react-query";

import { useApi } from "../../context";
import type { GenericErrorResponse } from "../../errors";
import type { CreatedAppointment } from "./definitions";
import type { UseV1AppointmentValidateParams } from "./validate";

type UseV1AppointmentCreateParams = UseV1AppointmentValidateParams & {
  code?: string;
};
type UseV1AppointmentCreateResult =
  | {
      ok: true;
      data: CreatedAppointment;
    }
  | {
      ok: false;
      error: GenericErrorResponse;
    };

type UseV1AppointmentCreateOptions = UseMutationOptions<
  UseV1AppointmentCreateResult,
  unknown,
  UseV1AppointmentCreateParams
>;

export const useV1AppointmentCreate = (
  options?: Partial<UseV1AppointmentCreateOptions>,
) => {
  const url = "/api/v1/appointments/";
  const { api } = useApi();

  return useMutation({
    ...options,
    mutationFn: (params) => {
      return api(
        {
          url,
          method: "POST",
          data: params,
        },
        {
          201: async (r) => ({
            ok: true as const,
            data: r.data,
          }),
          400: async (r) => ({
            ok: false as const,
            error: r.data,
          }),
          500: async (r) => ({
            ok: false as const,
            error: r.data,
          }),
        },
      );
    },
  });
};

import { css } from "@emotion/react";
import type { Node } from "@react-types/shared";
import { useRef } from "react";
import { useOption } from "react-aria";

import { cssFns } from "../css";
import { DesktopPopupDone } from "../icons";
import { useScreenDetector } from "../screen-type";
import { usePrincipalColors } from "../theme";
import { useOptions } from "./options";

export const useListBoxItemStyles = ({
  isFocused,
  isSelected,
  level = 0,
}: {
  isFocused?: boolean;
  isSelected?: boolean;
  level?: number;
}) => {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();

  return css([
    cssFns.typo({ level: "body-1", weight: "regular" }),
    isMobile ? cssFns.padding("12px", "16px") : cssFns.padding("10px", "16px"),
    isMobile &&
      cssFns.borderBlockEnd({
        color: principalColors.grayline,
        style: "solid",
        width: "1px",
      }),
    {
      display: "grid",
      alignItems: "center",
      columnGap: "4px",
      gridTemplateColumns: "1fr 20px",
      backgroundColor: isMobile
        ? undefined
        : isFocused
          ? principalColors.grayline
          : undefined,
      outlineStyle: "none",
      cursor: "pointer",
      color: isSelected ? principalColors.ebblue : principalColors.gs2,
      gap: "4px",
      paddingInlineEnd: "16px",
      paddingInlineStart: (level + 1) * 16,
      zIndex: 1,
    },
  ]);
};

export function ListBoxItem<Option>({ item }: { item: Node<Option> }) {
  const principalColors = usePrincipalColors();
  const { state, getOption, renderItem: RenderItem } = useOptions();

  const ref = useRef<HTMLLIElement>(null);

  const isSelected = state.selectionManager.isSelected(item.key);
  const isFocused = state.selectionManager.focusedKey === item.key;

  const { optionProps } = useOption({ key: item.key, isSelected }, state, ref);

  const option = getOption?.(item.key);

  const styles = useListBoxItemStyles({
    isFocused,
    isSelected,
    level: item.level,
  });

  if (option && RenderItem) {
    return (
      <li ref={ref} {...optionProps}>
        <RenderItem
          isSelected={isSelected}
          isFocused={isFocused}
          option={option}
          rendered={item.rendered}
          level={item.level}
        />
      </li>
    );
  }

  return (
    <li ref={ref} css={styles} {...optionProps}>
      {item.props.textValue ?? item.rendered}
      <div>
        {isSelected && (
          <DesktopPopupDone aria-hidden color={principalColors.ebblue} />
        )}
      </div>
    </li>
  );
}

export class AppointmentValidateError extends Error {
  public details: any;

  constructor(message: string | undefined) {
    super(message);
    this.name = "AppointmentValidateError";
    Object.setPrototypeOf(this, AppointmentValidateError.prototype);
  }
}

export class AppointmentCreateError extends Error {
  public details: any;

  constructor(message: string | undefined) {
    super(message);
    this.name = "AppointmentCreateError";
    Object.setPrototypeOf(this, AppointmentCreateError.prototype);
  }
}

import { type DoctorService } from "@hermes/api";

export const isChildService = ({ service_type, age_to }: DoctorService) =>
  age_to && age_to <= 216 && service_type === "initial-appointment";

export const isServiceHasChild = (
  doctor_services: DoctorService[],
  service?: DoctorService,
) => {
  return (
    service &&
    service.service_type === "initial-appointment" &&
    service.age_to &&
    service.age_to > 216 &&
    doctor_services.filter(
      (s) =>
        s.age_to && s.age_to <= 216 && s.service_type === "initial-appointment",
    )[0]
  );
};

import { Temporal } from "@hermes/intl";

import type { SimpleSchemaType } from "./types";

export const string = <T extends string | undefined>(options: {
  enum?: T[];
  default?: T;
}): SimpleSchemaType<T> => {
  return {
    format(value) {
      if (
        typeof value !== "undefined" &&
        options.enum &&
        !options.enum.includes(value)
      ) {
        return options.default;
      }

      return value ?? options.default;
    },
    parse(input) {
      if (
        typeof input !== "undefined" &&
        options.enum &&
        !options.enum.includes(input as T)
      ) {
        return options.default;
      }

      return (input as T) ?? options.default;
    },
    isArray: false,
  };
};

export const number = <T extends number | undefined>(): SimpleSchemaType<T> => {
  return {
    format(value) {
      return value ? value.toString() : undefined;
    },
    parse(input) {
      if (!input) {
        return undefined;
      }
      return Number(input) as T;
    },
    isArray: false,
  };
};

export const boolean = (): SimpleSchemaType<boolean | undefined> => {
  return {
    format(value) {
      return value ? "" : undefined;
    },
    parse(input) {
      return Boolean(typeof input !== "undefined");
    },
    isArray: false,
  };
};

export const plainDate = (): SimpleSchemaType<
  Temporal.PlainDate | undefined
> => {
  return {
    format(value) {
      return value ? value.toString() : undefined;
    },
    parse(input) {
      if (!input) {
        return undefined;
      }

      return Temporal.PlainDate.from(input);
    },
    isArray: false,
  };
};

import type { City, CityArea, Doctor, DoctorService, Slot } from "@hermes/api";
import type { Temporal } from "@hermes/intl";
import { type TimeSlot, TimeSlots } from "@hermes/shared";
import { useModalState } from "@hermes/ui";
import { Suspense, useState } from "react";

import {
  AppointmentModal,
  type AppointmentModalProps,
} from "../appointment-modal";
import type { BranchServices } from "../doctor-card";

export const AppointmentSlots = ({
  doctor,
  city,
  cityArea,
  initialSlots,
  branch_services,
  request_meta,
  variant,
  defaultStartDate,
}: {
  doctor: Doctor;
  city?: City;
  cityArea?: CityArea;
  initialSlots?: Slot[];
  branch_services?: BranchServices;
  request_meta?: AppointmentModalProps["request_meta"];
  variant?: "mobile" | "desktop";
  selectedDoctorService?: DoctorService | null;
  defaultStartDate?: Temporal.PlainDate;
}) => {
  const [startDate, setStartDate] = useState<Temporal.PlainDate | undefined>(
    defaultStartDate,
  );
  const [endDate, setEndDate] = useState<Temporal.PlainDate | undefined>();

  const modalState = useModalState<TimeSlot>({});

  const hasServices = branch_services && branch_services.services.length > 0;

  return (
    <>
      <TimeSlots
        initialSlots={initialSlots}
        startDate={startDate}
        endDate={endDate}
        doctor={doctor.id}
        clinic_branch={branch_services?.branch.id}
        service={branch_services?.services.map((service) => service.service)}
        slotsLimit={5}
        onSlotPress={(slot) => {
          modalState.open(slot);
        }}
        onPrevPress={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        onNextPress={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        variant={variant}
      />
      {hasServices && modalState.isOpen && (
        <Suspense>
          <AppointmentModal
            state={modalState}
            initialSlot={modalState.value}
            doctor={doctor}
            city={city}
            cityArea={cityArea}
            startDate={startDate}
            endDate={endDate}
            branch_services={branch_services}
            branchSlots={initialSlots}
            request_meta={request_meta}
          />
        </Suspense>
      )}
    </>
  );
};

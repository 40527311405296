import { type Slot, useV1SlotsList } from "@hermes/api";
import {
  Message,
  Temporal,
  useFormatDateTime,
  useFormatRelativeTime,
  useMessage,
  useNow,
} from "@hermes/intl";
import {
  ButtonV2,
  cssFns,
  icons,
  Spinner,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import { keepPreviousData } from "@tanstack/react-query";
import { useMemo, useState } from "react";

import { Placeholder } from "./placeholder";
import { TabButton } from "./tab-button";

export type TimeSlot = {
  time: Temporal.PlainDateTime;
  slot: Slot;
};

export type TimeSlotDay = {
  date: Temporal.PlainDate;
  slots: TimeSlot[];
};

const DesktopLayout = ({
  days,
  nearestDay,
  slotsLimit,
  onSlotPress,
  onNextPress,
  onPrevPress,
  isLoading,
}: {
  days: TimeSlotDay[];
  nearestDay?: Temporal.PlainDate;
  slotsLimit: number | false;
  onSlotPress: (slot?: TimeSlot) => void;
  onPrevPress: (
    startDate: Temporal.PlainDate,
    endDate: Temporal.PlainDate,
  ) => void;
  onNextPress: (
    startDate: Temporal.PlainDate,
    endDate: Temporal.PlainDate,
  ) => void;
  isLoading?: boolean;
}) => {
  const now = useNow("day");
  const message = useMessage();
  const principalColors = usePrincipalColors();
  const formatDateTime = useFormatDateTime();
  const formatRelativeTime = useFormatRelativeTime();
  const { isMobile } = useScreenDetector();

  const [expanded, setExpanded] = useState(false);

  if (!days.length && !nearestDay) {
    return <Placeholder />;
  }

  const areDaysEmpty = days.every((day) => day.slots.length === 0);
  const canExpand = slotsLimit
    ? days.some((day) => day.slots.length > slotsLimit)
    : false;
  const maxSlots = days.reduce((max, day) => {
    return Math.max(max, day.slots.length);
  }, 4);

  const startDate = days[0]?.date!;
  const endDate = days[3]?.date!;

  const daysDiff = nearestDay?.since(startDate).days;

  const relativeTime =
    typeof daysDiff !== "undefined"
      ? formatRelativeTime(daysDiff, "day")
      : undefined;

  return (
    <div>
      <div
        css={{
          display: "grid",
          gridTemplateColumns: `max-content repeat(${isLoading ? 1 : days.length}, minmax(50px, 1fr)) max-content`,
          columnGap: isMobile ? 5 : 10,
          marginBlockEnd: 12,
        }}
      >
        <TabButton
          direction="left"
          active={Temporal.PlainDate.compare(now, startDate) < 0}
          onPress={() => {
            setExpanded(false);

            const newEndDate = startDate.subtract({ days: 1 });
            const newStartDate = newEndDate.subtract({ days: 3 });

            if (Temporal.PlainDate.compare(now, newStartDate) >= 0) {
              onPrevPress(
                now.toPlainDate(),
                now.toPlainDate().add({ days: 3 }),
              );
              return;
            }
            onPrevPress(newStartDate, newEndDate);
          }}
        />
        {isLoading ? (
          <div css={cssFns.center()}>
            <Spinner />
          </div>
        ) : (
          days.map((day) => {
            const daysDiff = day.date.since(now).days;
            // Разница в количестве слотов на этот день в сравнении с максимальным доступным
            // Нужно чтобы при расширении дорисовать пустые слоты
            const maxSlotsDiff = Math.abs(maxSlots - day.slots.length);
            // Разница в количестве слотов на этот день в сравнении с лимитом
            const limitSlotsDiff = slotsLimit
              ? Math.max(slotsLimit - day.slots.length, 0)
              : 0;

            const title =
              Math.abs(daysDiff) <= 1
                ? formatRelativeTime(daysDiff, "day")
                : formatDateTime(day.date, { weekday: "short" });
            const subtitle = formatDateTime(day.date, {
              day: "numeric",
              month: "short",
            });

            return (
              <div
                key={day.date.toString()}
                css={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "12px",
                }}
              >
                <div
                  css={[
                    cssFns.typo({
                      level: "body-3",
                      weight: "regular",
                    }),
                    {
                      color:
                        now.toPlainDate() === day.date
                          ? principalColors.ebblue
                          : day.slots.length
                            ? principalColors.gs2
                            : principalColors.gs8,
                      textAlign: "center",
                    },
                  ]}
                >
                  <div>{title}</div>
                  <div>{subtitle}</div>
                </div>
                {/* Рисуем сколько есть слотов до лимита если expanded = true */}
                {day.slots
                  .slice(
                    0,
                    !expanded && slotsLimit ? slotsLimit : day.slots.length,
                  )
                  .map((slot) => (
                    <ButtonV2
                      key={slot.slot.id}
                      variant="timeslot"
                      text={formatDateTime(slot.time, {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: false,
                      })}
                      onPress={() => {
                        onSlotPress(slot);
                      }}
                    />
                  ))}
                {/* Рисуем пустой слот до лимита если expanded = true */}
                {(!areDaysEmpty || !nearestDay) &&
                  Array.from({ length: maxSlots - day.slots.length })
                    .slice(0, expanded ? maxSlotsDiff : limitSlotsDiff)
                    .map((_, i) => (
                      <div key={i} css={[cssFns.center(), { height: 24 }]}>
                        <div
                          css={[
                            cssFns.borderBlockEnd({
                              radius: "1.5px",
                              color: principalColors.grayline,
                              style: "solid",
                            }),
                            { width: 20 },
                          ]}
                        />
                      </div>
                    ))}
              </div>
            );
          })
        )}
        <TabButton
          direction="right"
          active
          onPress={() => {
            setExpanded(false);
            const newStartDate = endDate.add({ days: 1 });
            onNextPress(newStartDate, newStartDate.add({ days: 3 }));
          }}
        />
      </div>

      {!isLoading && areDaysEmpty && nearestDay && (
        <div css={[cssFns.center(), { marginBlockStart: 40 }]}>
          <div
            css={[
              cssFns.typo({ level: "body-2", weight: "regular" }),
              {
                color: principalColors.gs4,
              },
            ]}
          >
            {startDate.since(now).days > 0 ? (
              <Message
                id="edf8904a-f73c-4385-bd97-e79d7a6b8081"
                default="Запись на {date}"
                values={{
                  date: formatDateTime(nearestDay, {
                    day: "numeric",
                    month: "long",
                  }),
                }}
              />
            ) : (
              <Message
                id="slot.nearest-date"
                default="Ближайшая запись на {date}"
                values={{
                  date:
                    daysDiff && daysDiff > 1
                      ? formatDateTime(nearestDay, {
                          day: "numeric",
                          month: "long",
                        })
                      : relativeTime,
                }}
              />
            )}
          </div>
          <ButtonV2
            text={message({
              id: "d256dcd3-0ee4-4602-8d3e-33a23f49b43d",
              default: "Посмотреть",
            })}
            variant="link-icon"
            icon={<icons.MobileArrowRight />}
            onPress={() => {
              const newStartDate = nearestDay;
              onNextPress(newStartDate, newStartDate.add({ days: 3 }));
            }}
          />
        </div>
      )}
      {!isLoading && canExpand && (
        <div css={cssFns.center()}>
          <ButtonV2
            text={
              expanded
                ? message({
                    id: "f153501c-2b39-42f2-8146-5d2666250f35",
                    default: "Скрыть",
                  })
                : message({
                    id: "fe3aeb45-3822-46d6-87c6-9574d72549e8",
                    default: "Показать еще",
                  })
            }
            variant="link-icon"
            onPress={() => setExpanded(!expanded)}
            dropDownIconState={expanded}
          />
        </div>
      )}
    </div>
  );
};

const MobileLayout = ({
  day,
  onSlotPress,
}: {
  day?: TimeSlotDay;
  onSlotPress?: (slot?: TimeSlot) => void;
}) => {
  const now = useNow("day");
  const message = useMessage();
  const formatRelativeTime = useFormatRelativeTime();
  const formatDateTime = useFormatDateTime();
  const principalColors = usePrincipalColors();

  if (!day || !day.slots.length) {
    return <Placeholder />;
  }

  const daysDiff = day.date.since(now).days;
  const relativeTime =
    daysDiff < 2
      ? formatRelativeTime(daysDiff, "day")
      : formatDateTime(day.date, {
          weekday: "long",
          day: "2-digit",
          month: "long",
        });

  return (
    <div>
      <div
        css={[
          cssFns.typo({
            level: "body-1",
            weight: "regular",
          }),
          { marginBlockEnd: 6, color: principalColors.gs4 },
        ]}
      >
        <Message
          id="slot.nearest-date"
          values={{
            date: (
              <span
                key="time"
                css={{ fontWeight: 600, color: principalColors.gs2 }}
              >
                {relativeTime}
              </span>
            ),
          }}
        />
      </div>
      <div css={{ display: "flex", columnGap: "8px" }}>
        {day.slots.slice(0, 3).map((slot) => (
          <ButtonV2
            key={slot.slot.id}
            text={formatDateTime(slot.time, {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            })}
            variant="timeslot"
            onPress={() => {
              onSlotPress?.(slot);
            }}
          />
        ))}
        <ButtonV2
          text={message({
            id: "dd1c8b13-3d03-4324-8d15-c3acf311ec97",
            default: "Еще",
          })}
          variant="timeslot"
          icon={<icons.ChevronRight />}
          onPress={() => onSlotPress?.()}
        />
      </div>
    </div>
  );
};

export const TimeSlots = ({
  initialSlots = [],
  startDate,
  endDate,
  clinic_branch,
  doctor,
  service,
  slotsLimit = 4,
  onSlotPress,
  onNextPress,
  onPrevPress,
  variant,
}: {
  initialSlots?: Slot[];
  startDate?: Temporal.PlainDate;
  endDate?: Temporal.PlainDate;
  service?: number[];
  clinic_branch?: number;
  doctor: number;
  slotsLimit?: number | false;
  onSlotPress: (slot?: TimeSlot) => void;
  onPrevPress: (
    startDate: Temporal.PlainDate,
    endDate: Temporal.PlainDate,
  ) => void;
  onNextPress: (
    startDate: Temporal.PlainDate,
    endDate: Temporal.PlainDate,
  ) => void;
  variant?: "mobile" | "desktop";
}) => {
  const now = useNow("day");

  const { isMobile } = useScreenDetector();

  const { data: slotsListData, isFetching: areSlotsFetching } = useV1SlotsList(
    {
      service,
      doctor: [doctor],
      clinic_branch,
      date_from: startDate?.toString(),
      date_to: endDate?.toString(),
    },
    {
      enabled: Boolean(startDate && endDate),
      placeholderData: keepPreviousData,
    },
  );
  const slotsList = slotsListData?.results;

  const { days, nearestDay } = useMemo(() => {
    const groupSlots = (slots: Slot[]) =>
      slots.reduce(
        (acc, slot) => {
          const date = Temporal.PlainDate.from(slot.datetime);

          const day = acc[date.toString()] || {
            date,
            slots: [],
          };

          day.slots.push({
            time: Temporal.PlainDateTime.from(slot.datetime),
            slot,
          });

          acc[date.toString()] = day;
          return acc;
        },
        {} as Record<string, TimeSlotDay>,
      );

    // Группируем слоты по дням
    const initialGroup = groupSlots(
      filterSlotsByDefaultService({ slots: initialSlots, service }),
    );
    const groups = groupSlots(
      filterSlotsByDefaultService({ slots: slotsList || [], service }),
    );

    const days: TimeSlotDay[] = Array.from({ length: 4 }, (_, i) => {
      const date = (startDate || now.toPlainDate()).add({ days: i });

      const slots =
        groups[date.toString()]?.slots ||
        initialGroup[date.toString()]?.slots ||
        [];
      return {
        date,
        slots,
      };
    });

    const nearestDay = Object.values(initialGroup)[0];
    const areDaysEmpty = days.every((day) => day.slots.length === 0);

    if (areDaysEmpty && !nearestDay) {
      return {
        days: [] as TimeSlotDay[],
      };
    }

    return {
      days: days.map((day) => {
        return {
          ...day,
          slots: day.slots.sort((a, b) => {
            return Temporal.PlainTime.compare(a.time, b.time);
          }),
        };
      }),
      nearestDay,
    };
  }, [initialSlots, slotsList, startDate, endDate, now]);

  return (isMobile && variant !== "desktop") || variant === "mobile" ? (
    <MobileLayout
      day={days.find((day) => day.slots.length > 0) || nearestDay}
      onSlotPress={onSlotPress}
    />
  ) : (
    <DesktopLayout
      days={days}
      nearestDay={nearestDay?.date}
      slotsLimit={slotsLimit}
      onSlotPress={onSlotPress}
      onNextPress={onNextPress}
      onPrevPress={onPrevPress}
      isLoading={areSlotsFetching}
    />
  );
};

// FIXME: This is a temporary hack due to the interface not supporting multiple services
const filterSlotsByDefaultService = ({
  slots,
  service,
}: {
  slots: Slot[];
  service?: number[];
}) => {
  if (!service) return slots;
  const defaultServiceId = service[0];

  return slots.filter((slot) => {
    if (slot.service) {
      return slot.service === defaultServiceId;
    }
    // If slot doesn't have a service, we assume it's a default service
    return true;
  });
};

import type { Key } from "@react-types/shared";
import { type ReactNode } from "react";
import { type ListState } from "react-stately";

import { makeOptions } from "../options-context";
import type { OverlayContentOptions } from "../overlays/content";

export type ListBoxOptions<Option> = {
  state: ListState<unknown>;
  getOption?: (key: Key) => Option | undefined;
  renderItem?: (props: {
    option: Option;
    rendered: ReactNode;
    isSelected: boolean;
    isFocused: boolean;
    level: number;
  }) => ReactNode;
  overlayOptions: OverlayContentOptions;
};

export const { OptionsProvider, useOptions } =
  makeOptions<ListBoxOptions<unknown>>("Menu");

import { useLocale } from "@hermes/intl";
import { type ReactNode, useRef } from "react";
import { useFocusRing, useNumberField } from "react-aria";
import {
  type NumberFieldStateOptions,
  useNumberFieldState as useAriaNumberFieldState,
} from "react-stately";

import { FieldDescription } from "../common/description";
import { FieldError } from "../common/error";
import { BaseFieldInput, BaseFieldLayout } from "../common/field";
import { type Field, type FieldState, useBaseFieldState } from "../common/form";
import { FieldLabel } from "../common/label";
import { useTextFieldStyles } from "./text-field";

export type NumberFieldState = FieldState<number>;

export const NumberField = ({
  field,
  label,
  autoFocus,
  clearable,
  placeholder,
  description,
  icon,
  iconPosition,
  onIconPress,
  maxValue,
  minValue,
  formatOptions,
}: {
  label?: string;
  description?: string;
  placeholder?: string;
  field: Field<NumberFieldState>;
  icon?: ReactNode;
  onIconPress?: () => void;
  iconPosition?: "start" | "end";
  autoFocus?: boolean;
  clearable?: boolean;
  maxValue?: number;
  minValue?: number;
  formatOptions?: Intl.NumberFormatOptions;
}) => {
  const styles = useTextFieldStyles({
    disabled: field.disabled,
    hasIcon: !!icon,
  });

  const ref = useRef<HTMLInputElement>(null);
  const { isFocused, focusProps } = useFocusRing({
    isTextInput: true,
    within: true,
  });
  const [locale] = useLocale();

  const options: NumberFieldStateOptions = {
    label,
    locale: locale.toString(),
    description,
    isRequired: field.required,
    isDisabled: field.disabled,
    value: field.value,
    isInvalid: !!field.error?.visible,
    placeholder,
    autoFocus,
    maxValue,
    minValue,
    onChange: (value: number) => {
      field.onChange({
        ...field,
        value,
      });
    },
  };

  const state = useAriaNumberFieldState(options);

  const { labelProps, inputProps, descriptionProps, errorMessageProps } =
    useNumberField(
      {
        ...options,
        "aria-label": label ? undefined : (placeholder ?? "number-field"),
        isWheelDisabled: true,
        formatOptions,
      },
      state,
      ref,
    );

  return (
    <BaseFieldLayout
      label={
        label && (
          <FieldLabel
            labelProps={labelProps}
            disabled={field.disabled}
            required={field.required}
          >
            {label}
          </FieldLabel>
        )
      }
      input={
        <BaseFieldInput
          field={field}
          focused={isFocused}
          fieldProps={focusProps}
          input={
            <input
              {...inputProps}
              ref={ref}
              placeholder={placeholder}
              css={styles}
            />
          }
          onClear={
            clearable && field.value
              ? () => {
                  field.onChange({
                    ...field,
                    value: NaN,
                    error: undefined,
                  });
                }
              : undefined
          }
          startIcon={iconPosition === "start" ? icon : undefined}
          onStartIconPress={onIconPress}
          endIcon={iconPosition === "end" ? icon : undefined}
          disabled={field.disabled}
        />
      }
      description={
        description && (
          <FieldDescription
            disabled={field.disabled}
            descriptionProps={descriptionProps}
          >
            {description}
          </FieldDescription>
        )
      }
      error={
        field.error?.visible && (
          <FieldError
            errorMessageProps={errorMessageProps}
            errorMessage={field.error.message}
            fieldRequired={field.required}
          />
        )
      }
    />
  );
};

export const useNumberFieldState = (defaultState?: NumberFieldState) => {
  return useBaseFieldState<NumberFieldState>({
    value: NaN,
    error: undefined,
    ...defaultState,
  });
};

import {
  useV1CitiesSuspense,
  useV1CityAreas,
  useV1Insurances,
} from "@hermes/api";
import { useLocale, useMessage } from "@hermes/intl";
import { availableCountries, resolveCountryInfo } from "@hermes/shared/schemas";
import { useLocalStorage } from "@rehooks/local-storage";
import { type ReactNode, useEffect, useMemo, useState } from "react";

import { useCityRouteInfo } from "#internal/features/search-bar/city-select";

import { Context, type CountryInfo, type SearchLabel } from "./context";

export const AppConfigContext = ({ children }: { children: ReactNode }) => {
  const message = useMessage();
  const [locale] = useLocale();

  const { routeCitySlug, routeCityAreaSlug } = useCityRouteInfo();

  const { data: citiesData } = useV1CitiesSuspense({ locale: locale.baseName });
  const { data: insurancesData } = useV1Insurances({ limit: "none" });

  const [localCitySlug, setLocalCitySlug] =
    useLocalStorage<string>("city-slug");
  const [localCityAreaSlug, setLocalCityAreaSlug] =
    useLocalStorage<string>("city-area-slug");

  const city = useMemo(
    () =>
      citiesData.results.find((city) => city.slug === localCitySlug) ||
      citiesData.results.find(
        (city) => city.id === Number(import.meta.env.DOQ_DEFAULT_CITY_ID),
      ) ||
      citiesData.results[0]!,
    [localCitySlug, citiesData.results],
  );
  useEffect(() => {
    const routeCity = citiesData.results.find(
      (city) => city.slug === routeCitySlug,
    );
    // Синхронизировать город из url только один раз во время инициализации приложения
    if (routeCity && routeCity.slug !== localCitySlug) {
      setLocalCitySlug(routeCity.slug);
    }
  }, []);

  const { data: cityAreasData, isFetched: areCityAreasFetched } =
    useV1CityAreas(
      { city: city.id },
      { enabled: Boolean(routeCityAreaSlug || localCityAreaSlug) },
    );

  const cityArea = useMemo(() => {
    return cityAreasData?.results.find(
      (cityArea) => cityArea.slug === localCityAreaSlug,
    );
  }, [localCityAreaSlug, cityAreasData?.results]);

  const [areaSync, setAreaSync] = useState(false);
  useEffect(() => {
    if (!areCityAreasFetched || areaSync) return;

    const routeCityArea = cityAreasData?.results.find(
      (cityArea) => cityArea.slug === routeCityAreaSlug,
    );
    // Синхронизировать район из url только один раз во время инициализации приложения
    if (routeCityArea && routeCityArea.slug !== localCityAreaSlug) {
      setLocalCityAreaSlug(routeCityArea.slug);
    } else if (!routeCityArea) {
      setLocalCityAreaSlug(null);
    }
    setAreaSync(true);
  }, [cityAreasData?.results, areCityAreasFetched, areaSync]);

  const availableCountriesInfo = useMemo(() => {
    const countryIds = availableCountries.codes;

    const infos: CountryInfo[] = countryIds.map((key) => {
      const countryInfo = resolveCountryInfo(key);

      switch (key) {
        case "kz":
          return {
            ...countryInfo,
            name: message({
              id: "dfa4330f-e89b-4ad8-859b-8008a7652c30",
              default: "Казахстан",
            }),
            region: "kz",
            lang: "ru-kz",
          };
        case "kg":
          return {
            ...countryInfo,
            name: message({
              id: "cc4c1d9b-36aa-4bc7-a710-c1353038ca44",
              default: "Кыргызстан",
            }),
            region: "kg",
            lang: "ru-kg",
          };
        case "hu":
          return {
            ...countryInfo,
            name: message({
              id: "da854328-dd47-41b9-a2e8-55008a49a5e0",
              default: "Венгрия",
            }),
            region: "hu",
            lang: "hu-hu",
          };
        case "ro":
          return {
            ...countryInfo,
            name: message({
              id: "bb29373e-7517-4469-ba3d-84af12cd2412",
              default: "Румыния",
            }),
            region: "ro",
            lang: "ro-ro",
          };

        case "ae":
          return {
            ...countryInfo,
            name: message({
              id: "common.dubai",
              default: "Дубай",
            }),
            region: "ae",
            lang: "en-ae",
          };
      }
    });

    return infos;
  }, [message]);

  const country = useMemo(() => {
    const id = availableCountries.parse(import.meta.env.DOQ_COUNTRY_ID);

    return availableCountriesInfo.find((country) => country.countryId === id)!;
  }, [availableCountriesInfo]);

  const [searchLabel, setSearchLabel] = useState<SearchLabel>();

  return (
    <Context.Provider
      value={{
        isPrerender: window.navigator.userAgent.indexOf("Prerender") !== -1,
        country,
        city,
        cities: citiesData.results,
        availableCountriesInfo,
        countryPhone: {
          code: import.meta.env.DOQ_COUNTRY_PHONE_CODE,
          cityCodeLength: Number(import.meta.env.DOQ_PHONE_CITY_CODE_LENGTH),
          mask: import.meta.env.DOQ_COUNTRY_PHONE_MASK,
        },
        cityArea,
        cityAreas: cityAreasData?.results,
        areCityAreasFetched,
        changeCity: (slug, areaSlug) => {
          setLocalCitySlug(slug);
          setLocalCityAreaSlug(areaSlug ?? null);
        },
        changeCityArea: (slug) => {
          setLocalCityAreaSlug(slug);
        },
        searchLabel,
        changeSearchLabel: setSearchLabel,
        socialMediaLinks: {
          facebook: import.meta.env.DOQ_FACEBOOK,
          instagram: import.meta.env.DOQ_INSTAGRAM,
        },
        insurances: insurancesData?.results || [],
      }}
    >
      {children}
    </Context.Provider>
  );
};

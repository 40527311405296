import type { City, CityArea, ClinicBranch } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import { cssFns, Select, TriggerButton, usePrincipalColors } from "@hermes/ui";
import { DesktopArrowDown } from "@hermes/ui/src/icons";

import { useAppConfig } from "#internal/app/config";

import { DoctorClinicInfo } from "./clinic-info";

export const MobileBranchSelect = ({
  selected,
  onSelectionChange,
  branches,
}: {
  selected?: ClinicBranch;
  onSelectionChange?: (branchId: number) => void;
  branches: ClinicBranch[];
  city?: City;
  cityArea?: CityArea;
}) => {
  const message = useMessage();

  const { cities } = useAppConfig();

  const grouppedBranches = groupClinics(branches);

  const principalColors = usePrincipalColors();

  return (
    <Select
      field={{
        value: selected,
        onChange(value) {
          if ("value" in value) {
            onSelectionChange?.(value.value?.id || 0);
          }
        },
      }}
      fontWeight="regular"
      mobile={{
        title: message({
          id: "abb71931-c800-4449-8eb4-b4f9a955a649",
          default: "Выберите адрес",
        }),
      }}
      showParent
      showArrowDown
      children={(triggerRef, triggerProps) => (
        <TriggerButton ref={triggerRef} triggerProps={triggerProps}>
          <div
            css={[
              {
                width: "100%",
                display: "grid",
                gridTemplateColumns: "1fr auto",
                columnGap: "8px",
                textAlign: "left",
                alignItems: "center",
              },
              cssFns.border({
                width: 1,
                style: "solid",
                color: principalColors.grayline,
                radius: 4,
              }),
              cssFns.padding(6, 10),
            ]}
          >
            <DoctorClinicInfo
              name={selected?.name}
              address={selected?.address}
              city={cities.find(({ id }) => selected?.city === id)}
              cityArea={selected?.city_area}
              noRedirectToClinic
            />

            <div
              css={{
                width: 20,
                height: 20,
              }}
            >
              <DesktopArrowDown color={principalColors.gs8} />
            </div>
          </div>
        </TriggerButton>
      )}
      options={grouppedBranches.map(({ label, branches }) => ({
        label,
        options: branches.map((branch) => ({
          label: [
            branch.address,
            branch.city_area?.name,
            cities.find(({ id }) => id === branch.city)?.name,
          ].join(", "),
          value: branch,
        })),
      }))}
    />
  );
};

const groupClinics = (data: ClinicBranch[]) => {
  return Object.values(
    data.reduce<
      Record<
        number,
        { clinic: number; label: string; branches: ClinicBranch[] }
      >
    >((acc, item) => {
      if (!acc[item.clinic]) {
        acc[item.clinic] = {
          clinic: item.clinic,
          label: item.name,
          branches: [],
        };
      }

      acc[item.clinic]?.branches.push(item);

      return acc;
    }, {}),
  );
};

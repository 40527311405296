import { useV1Services } from "@hermes/api";

export function useHomePagePrefetchData({
  city,
  cityArea,
}: {
  city: number;
  cityArea?: number;
}) {
  return useV1Services({
    city: city,
    is_primary: true,
    city_area: cityArea,
    type: "initial-appointment",
    has_adult_service: false,
  });
}

import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

import { PollyFillLoader, type PolyfillExclude } from "./polyfill/loader";

const Context = createContext<
  [Intl.Locale, (locale: Intl.Locale | string) => void, string[]] | undefined
>(undefined);

export const withNumberingSystem = (locale: Intl.Locale) =>
  new Intl.Locale(locale, { numberingSystem: "latn" });

export const LocaleProvider = ({
  value,
  children,
  polyfill,
}: {
  polyfill: {
    locales: string[];
    exclude?: PolyfillExclude[];
  };
  value: Intl.Locale;
  children: ReactNode;
}) => {
  const [locale, setLocale] = useState(withNumberingSystem(value));

  useEffect(() => {
    setLocale(withNumberingSystem(value));
  }, [value.baseName]);

  return (
    <Context.Provider
      value={[
        locale,
        useCallback((locale) => {
          setLocale(
            withNumberingSystem(
              typeof locale === "string" ? new Intl.Locale(locale) : locale,
            ),
          );
        }, []),
        polyfill.locales,
      ]}
    >
      <PollyFillLoader
        availableLocales={polyfill.locales}
        exclude={polyfill.exclude}
      >
        {children}
      </PollyFillLoader>
    </Context.Provider>
  );
};

export const useLocale = () => {
  const locale = useContext(Context);

  if (!locale) {
    throw Error("useLocale requires LocaleProvider in the react tree");
  }

  return locale;
};

export const useNegotiatedLocale = ({
  requested,
  available,
  default: default_,
}: {
  requested: Intl.Locale | string | (Intl.Locale | string)[];
  available: Intl.Locale | string | (Intl.Locale | string)[];
  default: Intl.Locale | string;
}) => {
  return useMemo(() => {
    const set = new Set([available].flat());
    const key =
      [requested].flat().filter((locale) => set.has(locale))[0] || default_;
    return new Intl.Locale(key);
  }, [requested, available, default_]);
};

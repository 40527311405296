import { css } from "@emotion/react";
import { cssFns, icons, TriggerButton, usePrincipalColors } from "@hermes/ui";

export const TabButton = ({
  direction,
  active,
  onPress,
}: {
  direction: "left" | "right";
  active?: boolean;
  onPress?: () => void;
}) => {
  const principalColors = usePrincipalColors();

  return (
    <TriggerButton
      onPress={onPress}
      disabled={!onPress || !active}
      styles={css([
        cssFns.padding("0px"),
        cssFns.border({
          radius: "50%",
          width: active ? "0px" : "1px",
          color: principalColors.gs8,
          style: "solid",
        }),
        cssFns.center(),
        {
          width: "28px",
          height: "28px",
          backgroundColor: active
            ? principalColors.grayline
            : principalColors.white,
          outline: "none",
          "&:hover": {
            cursor: active ? "pointer" : "default",
          },
        },
      ])}
    >
      {direction === "left" ? (
        <icons.DesktopArrowLeft
          width={15}
          height={15}
          color={active ? principalColors.ebblue : principalColors.gs8}
        />
      ) : (
        <icons.DesktopArrowRight
          width={15}
          height={15}
          color={active ? principalColors.ebblue : principalColors.gs8}
        />
      )}
    </TriggerButton>
  );
};

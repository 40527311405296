import {
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  BrowserClient,
  browserSessionIntegration,
  dedupeIntegration,
  defaultStackParser,
  functionToStringIntegration,
  getCurrentScope,
  globalHandlersIntegration,
  httpContextIntegration,
  inboundFiltersIntegration,
  linkedErrorsIntegration,
  makeFetchTransport,
  reactRouterV6BrowserTracingIntegration,
} from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (import.meta.env.DOQ_ENVIRONMENT === "production") {
  const client = new BrowserClient({
    environment: import.meta.env.DOQ_ENVIRONMENT,
    dsn: import.meta.env.DOQ_SENTRY_API_KEY,
    transport: makeFetchTransport,
    stackParser: defaultStackParser,
    integrations: [
      // TODO: review list of integrations, leave only necessary
      globalHandlersIntegration(),
      httpContextIntegration(),
      inboundFiltersIntegration(),
      linkedErrorsIntegration(),
      browserSessionIntegration(),
      functionToStringIntegration(),
      dedupeIntegration(),
      breadcrumbsIntegration(),
      browserApiErrorsIntegration(),
      reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    normalizeDepth: 10,
    tracesSampleRate: 0.05,
    tracePropagationTargets: [
      /^\//,
      new RegExp(`^${import.meta.env.DOQ_API_URL}`),
    ],
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 0.05,
  });
  getCurrentScope().setClient(client);
  client.init();
}

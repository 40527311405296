declare global {
  namespace Intl {
    interface Locale extends LocaleOptions {
      getWeekInfo: () => {
        firstDay: 1 | 7;
        weekend: number[];
        minimalDays: number;
      };
    }
  }
}

// Forcefully set the function, Firefox seems to ignore the getter
Intl.Locale.prototype["getWeekInfo"] = function () {
  return this.weekInfo;
};

export {};

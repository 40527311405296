import {
  type BaseDoctorsListParams,
  type City,
  type CityArea,
  type ClinicBranch,
  type Doctor,
  type DoctorService,
  type DoctorsListOrder,
  type PaginatedResponse,
  type Slot,
  useV1ClinicBranchesListParallel,
  useV1DoctorsListParallel,
  useV1DoctorsServicesListParallel,
  useV1PremiumDoctorsList,
  useV1SlotsListParallel,
} from "@hermes/api";
import { Temporal, useNow } from "@hermes/intl";
import type { GeoPosition } from "@hermes/shared";
import { usePreviousState, useScreenDetector } from "@hermes/ui";
import { keepPreviousData, type UseQueryResult } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";

import { useAppConfig } from "#internal/app/config";
import { type DoctorCardService } from "#internal/features/doctor-card";

import { getSlotsDateRanges } from "./date-range";
import { mergeDoctorsPages } from "./merging";

type PageQuery<T> = {
  isFetched?: boolean;
  isFetching?: boolean;
  results: T[];
};

const toPageQuery = <T>(
  query?: UseQueryResult<PaginatedResponse<T[]>, Error>,
  pendingState?: boolean,
): PageQuery<T> | undefined =>
  query
    ? {
        results: query.data?.results || [],
        isFetched: query.isFetched,
        isFetching: pendingState
          ? query.isFetching || query.isPending
          : query.isFetching,
      }
    : undefined;

export type Page = {
  // Triggers loading doctors/services/clinic branches/slots
  seen: boolean;
  size?: number;
  offset?: number;
  doctors?: PageQuery<Doctor & { premium?: boolean }>;
  branches?: PageQuery<ClinicBranch>;
  services?: PageQuery<DoctorService>;
  slots?: PageQuery<Slot>;
  isFetched?: boolean;
  isFetching?: boolean;
};

/**
 * Обрабатывает страницы запросов от основного запроса списка докторов
 */
export const useDoctorsPages = ({
  sort,
  targetServices,
  city,
  cityArea,
  clinicId,
  position,
  slotDate,
  enablePromo,
  onPrerenderLoaded,
  language,
  insurance,
}: {
  sort?: DoctorsListOrder;
  targetServices?: DoctorCardService[];
  city: City;
  cityArea?: CityArea;
  clinicId?: number;
  position?: GeoPosition;
  slotDate?: Temporal.PlainDate;
  enablePromo?: boolean;
  onPrerenderLoaded?: () => void;
  language?: string;
  insurance?: number;
}) => {
  const { isPrerender } = useAppConfig();
  const { isMobile } = useScreenDetector();

  const now = useNow("day");
  const serviceIds = targetServices?.map((service) => service.id);

  const limit = isPrerender ? 100 : isMobile ? 5 : 8;

  const [seen, setSeen] = useState(new Set<number>());
  const params = {
    ordering: sort,
    limit,
    service: serviceIds,
    city: city.id,
    city_area: cityArea?.id,
    clinic: clinicId,
    lat: position?.lat,
    lng: position?.lng,
    slot_date: slotDate?.toString(),
    language,
    insurance,
    expand: ["clinic_branches"],
  } as BaseDoctorsListParams;
  const prevParams = usePreviousState(params);
  const paramsToken = JSON.stringify(params);
  const prevParamsToken = JSON.stringify(prevParams);

  useEffect(() => {
    if (paramsToken !== prevParamsToken) {
      setSeen(new Set());
    }
  }, [paramsToken, prevParamsToken]);

  const isPromoEnabled = Boolean(
    enablePromo &&
      (sort === "default" || sort === undefined) &&
      !position &&
      !slotDate &&
      !clinicId &&
      !cityArea &&
      !language &&
      !insurance,
  );

  const premiumQuery = useV1PremiumDoctorsList(
    {
      city: city.id,
      expand: ["clinic_branches"],
      service: serviceIds,
    },
    {
      enabled: isPromoEnabled,
    },
  );

  const firstDoctorsQueries = useV1DoctorsListParallel({
    pages: [{ offset: 0, seen: true }],
    ...params,
  });
  const firstDoctorsQuery = firstDoctorsQueries[0];

  const firstPage = useMemo(() => {
    return {
      seen: true,
      offset: 0,
      doctors: {
        isFetched: isPromoEnabled
          ? premiumQuery.isFetched && firstDoctorsQuery?.isFetched
          : firstDoctorsQuery?.isFetched,
        isFetching: isPromoEnabled
          ? premiumQuery.isFetching || firstDoctorsQuery?.isFetching
          : firstDoctorsQuery?.isFetching,
        results: [
          ...((isPromoEnabled &&
            premiumQuery.data?.results.map((doctor) => ({
              ...doctor,
              premium: true,
            }))) ||
            []),
          ...(firstDoctorsQuery?.data?.results ?? []),
        ],
      },
      size:
        (premiumQuery.data?.results.length ?? 3) +
        (firstDoctorsQuery?.data?.results.length ?? limit),
    };
  }, [isPromoEnabled, premiumQuery.state, firstDoctorsQueries.state, limit]);

  // Премиум врачи и первая страница всегда на 0 индексе
  let pages: Page[] = [firstPage];
  const RESERVED_PAGES = 1;
  const firstPageReady =
    pages[0]?.doctors?.isFetched !== undefined && pages[0].doctors.isFetched;

  const count = firstDoctorsQuery?.data?.count;

  const additionalPages = useMemo(() => {
    if (count) {
      // Остальные страницы минус первая
      return Array.from({ length: Math.ceil(count / limit) - 1 }, (_, i) => {
        // Добавляем 1 с учетом offset первой страницы
        const offset = (i + 1) * limit;
        const leftover = count - offset;

        return {
          offset,
          seen: false,
          size: leftover >= limit ? limit : leftover,
        };
      });
    }
    return [];
  }, [count, limit, isPrerender]);

  pages = pages.concat(
    additionalPages.map((page, i) => {
      page.seen = isPrerender ? true : seen.has(i + RESERVED_PAGES);
      return page;
    }),
  );

  const doctorsQueries = useV1DoctorsListParallel({
    pages: pages.slice(RESERVED_PAGES),
    ...params,
  });

  // Пропускаем первую страницу, затем заполняем запросы докторов в остальных страницах если они доступны
  for (let i = RESERVED_PAGES; i < pages.length; i++) {
    if (!pages[i]) continue;
    pages[i]!.doctors = toPageQuery(doctorsQueries[i - RESERVED_PAGES]);
  }

  const seenClinicBranches = new Set<number>();
  const clinicBranchesIds = pages.map((page) => {
    if (!page.doctors?.results) return [];
    return (
      page.doctors.results
        .flatMap((doctor) => doctor.clinic_branches || [])
        // Нам не нужно отправлять запрос на clinic_branch который мы уже видели
        .filter((branchId) => {
          if (seenClinicBranches.has(branchId)) {
            return false;
          }
          seenClinicBranches.add(branchId);
          return true;
        })
    );
  });

  const branchesQueries = useV1ClinicBranchesListParallel(
    {
      groupedIds: clinicBranchesIds,
      expand: ["clinic_slug", "closest_stations", "city", "city_area"],
    },
    {
      placeholderData: keepPreviousData,
      enabled: firstPageReady,
    },
  );

  for (let i = 0; i < pages.length; i++) {
    if (!pages[i]) continue;
    pages[i]!.branches = toPageQuery(branchesQueries[i]);
  }

  // Аналогично, отдельно возьмем id от премиум и первой страницы чтобы объединить их в один запрос
  const doctorsIds = pages.map(
    (page) => page.doctors?.results.map((doctor) => doctor.id) || [],
  );

  const servicesQueries = useV1DoctorsServicesListParallel(
    {
      groupedDoctorIds: doctorsIds,
      service: serviceIds,
      clinic_branch: clinicId ? clinicBranchesIds[0] : undefined,
    },
    {
      placeholderData: keepPreviousData,
      enabled: firstPageReady,
    },
  );
  for (let i = 0; i < pages.length; i++) {
    if (!pages[i]) continue;
    pages[i]!.services = toPageQuery(servicesQueries[i]);
  }

  const dateRanges = getSlotsDateRanges({
    queries: servicesQueries,
    today: slotDate ? slotDate : now.toPlainDate(),
    dateRangeLength: 4,
    maxDaysPerRange: 7,
  });

  const slotsQueries = useV1SlotsListParallel(
    { dateRanges },
    {
      placeholderData: keepPreviousData,
      enabled: firstPageReady,
    },
  );
  for (let i = 0; i < pages.length; i++) {
    if (!pages[i]) continue;
    pages[i]!.slots = toPageQuery(slotsQueries[i], true);
  }

  const doctorsPages = mergeDoctorsPages({
    targetServices,
    dateRanges,
    pages,
  });

  useEffect(() => {
    if (!isPrerender) return;
    if (doctorsPages[0]?.isFetching) return;
    onPrerenderLoaded?.();
  }, [isPrerender, doctorsPages[0]?.isFetching]);

  return {
    count,
    limit,
    pages: doctorsPages,
    premiumCount: premiumQuery.data?.count ?? 0,
    fetchPage(page: number) {
      if (!seen.has(page)) {
        setSeen((seen) => new Set([...seen, page]));
      }
    },
  };
};

import { useFlag } from "@hermes/flags";
import { useLocale } from "@hermes/intl";
import { useAuth } from "@hermes/shared";
import { type ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";

export type GTMEcommerceImpression = {
  name: string;
  id: number;
  price: number;
  brand: string;
  category: string;
  variant: string;
  list: string;
  position?: number;
};

export type GTMEcommerceLayer =
  | {
      ecommerce: {
        currencyCode: string;
        impressions: GTMEcommerceImpression[];
      };
      event: "gtm-ee-event";
      "gtm-ee-event-category": string;
      "gtm-ee-event-action": "Product Impressions";
      "gtm-ee-event-non-interaction": string;
      "gtm.uniqueEventId": number;
    }
  | {
      ecommerce: {
        currencyCode: string;
        detail: {
          actionField: {
            list: string;
          };
          products: {
            name: string;
            id: number;
            price: number;
            brand: string;
            category: string;
            variant: string;
          }[];
        };
      };
      event: "gtm-ee-event";
      "gtm-ee-event-category": string;
      "gtm-ee-event-action": "Product Details";
      "gtm-ee-event-non-interaction": string;
      "gtm.uniqueEventId": number;
    };

export type GTMDataLayer =
  | {
      event: string;
      [key: string]: any;
    }
  | {
      event: "virtualPageview";
      pageUrl: string;
      pageTitle: string;
      [key: string]: any;
    }
  | GTMEcommerceLayer;

declare global {
  interface Window {
    dataLayer: GTMDataLayer[];
    gtag?: (...args: any[]) => void;
    gtag1?: (...args: any[]) => void;
    [index: string]: any;
  }
}

function getSessionData() {
  const pattern = new RegExp(
    `_ga_${import.meta.env.DOQ_GOOGLE_TAG_ID}=GS\\d.\\d.(\\w+).(\\d+)`,
  );
  const match = document.cookie.match(pattern);

  const parts = match?.[0]?.split(".");

  if (!parts) {
    return;
  }

  return {
    ga_session_number: parts.pop(),
    ga_session_id: parts.pop(),
  };
}

function get_ga_client_id() {
  const cookie: Record<string, string> = {};
  document.cookie.split(";").forEach(function (el) {
    const [key, value] = el.split("=");
    if (key?.trim() && value) {
      cookie[key.trim()] = value;
    }
  });

  return cookie["_ga"] ? cookie["_ga"].substring(6) : undefined;
}

export const useGClientInfo = () => {
  const sessionData = getSessionData();

  return {
    getGAClientInfo() {
      if (!import.meta.env.DOQ_GOOGLE_TAG_ID) return {};

      const ga_client_id = get_ga_client_id();
      if (!ga_client_id) return {};

      return {
        google_client_id: ga_client_id,
        google_session_id: sessionData?.ga_session_id,
        google_session_number: sessionData?.ga_session_number || undefined,
      };
    },
  };
};

const tagId = `G-${import.meta.env.DOQ_GOOGLE_TAG_ID}`;

export const GTagWrapper = ({ children }: { children: ReactNode }) => {
  const [locale] = useLocale();
  const { user } = useAuth();
  const flag = useFlag();

  useEffect(() => {
    window.gtag?.("set", "language", locale.baseName);
  }, [locale.baseName]);

  const enableDebug = import.meta.env.DOQ_ENVIRONMENT === "development";

  const config = JSON.stringify({
    send_page_view: false,
    allow_google_signals: true,
    allow_ad_personalization_signals: true,
    cookie_domain: "auto",
    anonymize_ip: true,
    ...(enableDebug && {
      debug_mode: true,
    }),
  });

  return (
    <>
      {!user && Boolean(import.meta.env.DOQ_GOOGLE_TAG_ID) && (
        <Helmet>
          {/* Cookie-bot */}
          {flag({
            id: "abcbff01-5a83-4652-8afd-2180a7c9f9e1",
            description: "enable cookie-bot",
          }) && (
            <script
              id="Cookiebot"
              src="https://consent.cookiebot.com/uc.js"
              data-cbid={import.meta.env.DOQ_COOKIE_BOT_ID}
              type="text/javascript"
            ></script>
          )}
          {/* <!-- Google tag (gtag.js) --> */}
          <script
            async
            src={`https://www.googletagmanager.com/gtag/js?id=${tagId}`}
          ></script>
          <script>
            {`window.dataLayer = window.dataLayer || [];
              window.gtag = function gtag(){dataLayer.push(arguments);}
              window.gtag1 = function gtag(){dataLayer.push(arguments);}
              gtag1('set', ${config});
              gtag1('js', new Date());

              gtag1('config', '${tagId}', ${config});
            `}
          </script>
        </Helmet>
      )}
      {children}
    </>
  );
};

type GTEvent =
  | {
      event: "page_view";
      page_location: string;
      page_title: string;
    }
  | {
      event: "search";
      search_term: string;
    }
  | {
      event: "submit-appointment-form" | "submit-appointment-form-success";
    }
  | {
      event: "begin_checkout";
      currency: string;
      shipping: number;
      tax: number;
      value: number;
    };

const last: Record<string, string> = {};
export const useSendGTagEvent = () => {
  return {
    sendGTagEvent: ({
      event,
      allowDuplicate = true,
      ...params
    }: GTEvent & {
      /**
       * Whether to allow for back-to-back duplicate events or not
       *
       * @default true
       */
      allowDuplicate?: boolean;
    }) => {
      const token = JSON.stringify(params);
      if (!allowDuplicate && last[event] === token) return;
      last[event] = token;
      window.gtag1?.("event", event, params);
    },
  };
};

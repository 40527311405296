import { useMatches } from "react-router-dom";

import { RoutesIdEnum } from "./types";

export { useRoutePath } from "./route-path";
export {
  cityAreasRoutes,
  cityRoutes,
  mobileSearchableRoutes,
  router,
} from "./router";
export { RoutesIdEnum } from "./types";

const ids = new Set(Object.values(RoutesIdEnum));
export const useRouteId = () => {
  const matches = useMatches();
  const outletRoute = matches[1];

  const routeId = outletRoute?.id as unknown as RoutesIdEnum;

  return ids.has(routeId) ? routeId : null;
};

import { HttpError } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import { ButtonV2, CenteredSpinner, cssFns } from "@hermes/ui";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { type ReactNode, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";

type ErrorBoundaryProps = {
  error: Error;
  onReset: () => void;
};

export type ErrorBoundaryComponent = (props: ErrorBoundaryProps) => ReactNode;

const DefaultErrorBoundaryComponent: ErrorBoundaryComponent = ({
  error,
  onReset,
}) => {
  const message = useMessage();

  return (
    <div css={[cssFns.center(), { marginBlockStart: 100 }]}>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          maxWidth: 600,
          padding: 24,
        }}
      >
        <span css={cssFns.typo({ level: "title-4" })}>
          {message({
            id: "827f00b8-6d63-44a2-8dba-a04db10daa21",
            default: "Что-то пошло не так",
          })}
        </span>
        <span css={cssFns.typo({ level: "body-2" })}>
          {message({
            id: "14eacbbd-bf7a-4e3b-8ede-a50de101679c",
            default: "Ошибка",
          })}
          : {error.message}
        </span>
        <ButtonV2
          variant="solid"
          text={message({
            id: "44625501-c948-4fe3-a46a-422e5fe94a87",
            default: "Попробовать перезагрузить страницу",
          })}
          onPress={() => {
            onReset();
            window.location.reload();
          }}
        />
        <ButtonV2
          variant="solid"
          text={message({
            id: "524eddde-ed07-4715-86c4-55d86ad1dfad",
            default: "На главную",
          })}
          onPress={() => {
            window.location.href = "/";
          }}
        />
      </div>
    </div>
  );
};

const RELOAD_MESSAGES = [
  "Unable to preload CSS",
  "Failed to fetch dynamically imported module",
];

window.addEventListener("vite:preloadError", () => {
  window.location.reload(); // for example, refresh the page
});

const ErrorBoundaryConditions = ({
  children,
  error,
  resetCount,
  onReset,
}: {
  children: ReactNode;
  resetCount: number;
} & ErrorBoundaryProps) => {
  const shouldAutoReset =
    resetCount < 3 && error instanceof HttpError && error.ignore;
  const shouldAutoReload = RELOAD_MESSAGES.some((message) =>
    error.message.includes(message),
  );

  if (shouldAutoReload) {
    return <CenteredSpinner />;
  }

  useEffect(() => {
    if (shouldAutoReset) {
      onReset();
    }
  }, [shouldAutoReset]);

  if (shouldAutoReset) {
    return <CenteredSpinner />;
  }

  return children;
};

export const AppErrorBoundary = ({
  children,
  errorBoundaryComponent: Content,
}: {
  children: ReactNode;
  errorBoundaryComponent?: ErrorBoundaryComponent;
}) => {
  const [count, setCount] = useState(0);

  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          onReset={reset}
          fallbackRender={({ error, resetErrorBoundary }) => {
            return (
              <ErrorBoundaryConditions
                error={error}
                resetCount={count}
                onReset={() => {
                  resetErrorBoundary();
                  setCount((c) => c + 1);
                }}
              >
                {Content ? (
                  <Content error={error} onReset={resetErrorBoundary} />
                ) : (
                  <DefaultErrorBoundaryComponent
                    error={error}
                    onReset={resetErrorBoundary}
                  />
                )}
              </ErrorBoundaryConditions>
            );
          }}
        >
          {children}
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};

import { Message, useMessage } from "@hermes/intl";
import {
  Checkbox,
  cssFns,
  type Field,
  type FieldState,
  usePrincipalColors,
} from "@hermes/ui";
import { Link } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";

export const AppointmentConsentDataBlock = ({
  field,
}: {
  field: Field<FieldState<any>>;
}) => {
  const { routePath } = useRoutePath();
  const principalColors = usePrincipalColors();
  const message = useMessage();
  return (
    <div>
      <Checkbox
        field={field}
        label={message({
          id: "7ea4a084-8d3a-4fc6-bf63-575b64856ad7",
          default: "Accept data management declaration",
        })}
      />

      <p
        css={[
          cssFns.typo({
            level: "body-1",
            weight: "regular",
            color: principalColors.gs4,
          }),
          {
            marginBlockStart: 16,
          },
        ]}
      >
        <Message
          id="appointment.consent-condition"
          default="I have reviewed the information regarding the processing of my data and the details of the data transfer. I acknowledge that my Personal Data may be transferred to a non-EU country as outlined in the {privacyPolicy}, and hereby explicitly consent thereto."
          values={{
            privacyPolicy: (
              <Link
                key="privacy-policy"
                to={routePath({ path: "/privacy_policy" })}
                css={{
                  textDecoration: "none",
                  color: principalColors.ebblue,
                }}
                target="_blank"
              >
                {message({
                  id: "appointment.privacy-policy",
                  default: "Data Processing Policy",
                })}
              </Link>
            ),
          }}
        />
      </p>
    </div>
  );
};

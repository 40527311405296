import { type City, type CityArea, type Doctor, type Slot } from "@hermes/api";
import { Temporal } from "@hermes/intl";
import { type TimeSlot } from "@hermes/shared";
import { cssFns, Modal, type ModalState, Spinner } from "@hermes/ui";
import { Suspense } from "react";

import type { BranchServices } from "../doctor-card";
import { AppointmentModalAsyncContent } from "./async-content";

export type AppointmentModalProps = {
  doctor: Doctor;
  city?: City;
  cityArea?: CityArea;
  branch_services: BranchServices;
  initialSlot?: TimeSlot;
  branchSlots?: Slot[];
  state: ModalState<TimeSlot>;
  request_meta?: {
    booking_page?: string;
    list_position?: number;
  };
  startDate?: Temporal.PlainDate;
  endDate?: Temporal.PlainDate;
};

export const AppointmentModal = (props: AppointmentModalProps) => {
  return (
    <Modal
      state={props.state}
      content={
        <Suspense
          fallback={
            <div css={[cssFns.center(), { minHeight: 700 }]}>
              <Spinner />
            </div>
          }
        >
          <AppointmentModalAsyncContent {...props} />
        </Suspense>
      }
    />
  );
};

import { css, type SerializedStyles } from "@emotion/react";
import { Message } from "@hermes/intl";
import { cssFns, usePrincipalColors } from "@hermes/ui";

import { DoctorServicePrice } from "../doctor-card/service-price";

export const ModalPrice = ({
  price,
  discount,
  total,
  styles,
  service_type,
  insurances,
}: {
  price: number | null;
  discount: number | null;
  total: number | null;
  styles?: SerializedStyles;
  service_type?: "initial-appointment" | "procedure";
  insurances?: number[];
}) => {
  const principalColors = usePrincipalColors();

  return (
    <div
      css={[
        styles,
        cssFns.typo({ level: "body-1", weight: "regular" }),
        {
          color: principalColors.gs4,
        },
      ]}
    >
      <div css={{ marginBlockStart: 2 }}>
        <Message id="ad3ffe56-caf2-4aa0-bb9c-dadb0f49e8d4" default="Прием" />
      </div>
      <DoctorServicePrice
        insurances={insurances}
        total={total}
        discount={discount}
        price={price}
        service_type={service_type}
        styles={css({
          marginBlockEnd: -4,
        })}
      />
    </div>
  );
};

import type { SerializedStyles } from "@emotion/react";
import {
  type DOMAttributes,
  forwardRef,
  type ReactNode,
  type RefObject,
} from "react";
import { type AriaButtonOptions, mergeProps, useButton } from "react-aria";

interface FocusableElement extends Element, HTMLOrSVGElement {}

type TriggerButtonProps = {
  disabled?: boolean;
  onPress?: () => void;
  triggerProps?: AriaButtonOptions<"button">;
  hoverProps?: DOMAttributes<FocusableElement>;
  children?: ReactNode;
  styles?: SerializedStyles;
};

export type TriggerChildrenProps = {
  children?: (
    triggerRef: RefObject<HTMLButtonElement>,
    triggerProps: AriaButtonOptions<"button">,
  ) => ReactNode;
};

export const TriggerButton = forwardRef<HTMLButtonElement, TriggerButtonProps>(
  ({ disabled, onPress, triggerProps, hoverProps, children, styles }, ref) => {
    const { buttonProps } = useButton(
      { onPress, isDisabled: disabled, ...triggerProps },
      ref as RefObject<HTMLButtonElement>,
    );

    return (
      <button
        ref={ref}
        css={[
          {
            padding: "0px",
            border: "none",
            outline: "none",
            display: "flex",
            columnGap: "12px",
            background: "none",
            "&:hover": {
              cursor: "pointer",
            },
          },
          styles,
        ]}
        {...mergeProps(buttonProps, hoverProps)}
      >
        {children}
      </button>
    );
  },
);

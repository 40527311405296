import type { ClinicBranch } from "@hermes/api";
import { cssFns, usePrincipalColors } from "@hermes/ui";

import { ClinicBranchPointIcon } from "#internal/shared/ui/icons";

export const DoctorDesktopBranchSelect = ({
  branches,
  selected,
  onSelectionChange,
}: {
  branches: ClinicBranch[];
  selected?: ClinicBranch;
  onSelectionChange?: (branchId: number) => void;
}) => {
  return (
    <>
      {branches.length > 1 &&
        branches.map((branch, index) => (
          <DoctorDesktopBranchSelectItem
            key={branch.id}
            number={index + 1}
            onPress={() => {
              onSelectionChange?.(branch.id);
            }}
            position={
              index === 0
                ? "start"
                : index === branches.length - 1
                  ? "end"
                  : "middle"
            }
            selected={selected?.id === branch.id}
          />
        ))}
    </>
  );
};

const DoctorDesktopBranchSelectItem = ({
  number,
  selected,
  position,
  onPress,
}: {
  number: number;
  selected?: boolean;
  position?: "start" | "middle" | "end";
  onPress?: () => void;
}) => {
  const principalColors = usePrincipalColors();

  return (
    <div
      css={[
        cssFns.typo({ weight: "regular", level: "body-1" }),
        cssFns.border({
          color: principalColors.grayline,
          width: "1px",
          style: "solid",
        }),
        cssFns.center(),
        {
          width: "35px",
          height: "35px",
          color: principalColors.gs2,
          borderBlockStartWidth: position === "start" ? "1px" : "0px",
          borderBlockEndWidth: position === "end" ? "1px" : "0px",
          borderStartStartRadius: position === "start" ? "4px" : undefined,
          borderEndStartRadius: position === "end" ? "4px" : undefined,
          borderInlineEndWidth: "0px",
          cursor: "pointer",
          backgroundColor: selected
            ? principalColors.white
            : principalColors.grayline,
          position: "relative",
        },
      ]}
      onClick={() => {
        onPress?.();
      }}
    >
      <ClinicBranchPointIcon
        css={{
          position: "absolute",
          left: "50%",
          top: "53%",
          transform: "translate(-50%, -50%)",
          zIndex: 0,
        }}
      />
      <span css={{ zIndex: 2, position: "relative" }}>{number}</span>
    </div>
  );
};

import { css } from "@emotion/react";
import {
  type ReactNode,
  type RefObject,
  useImperativeHandle,
  useMemo,
  useRef,
} from "react";
import { HiddenSelect, useFocusRing, useSelect } from "react-aria";
import {
  Item,
  Section,
  type SelectProps,
  useMenuTriggerState,
  useSelectState as useAriaSelectState,
  useTreeState,
} from "react-stately";

import { ButtonV2 } from "../../button";
import {
  TriggerButton,
  type TriggerChildrenProps,
} from "../../button/trigger-button";
import { cssFns } from "../../css";
import {
  DesktopArrowDown,
  DesktopArrowUp,
  MobileMenuSearch,
} from "../../icons";
import { Menu, type MenuOptions } from "../../menu";
import {
  type Content,
  type OverlayContentOptions,
  renderContent,
} from "../../overlays/content";
import { Modal, useModalState } from "../../overlays/modal";
import { Popover } from "../../overlays/popover";
import { useScreenDetector } from "../../screen-type";
import { usePrincipalColors, useSecondaryColors } from "../../theme";
import type { SizeUnit } from "../../utils";
import { FieldDescription } from "../common/description";
import { FieldError } from "../common/error";
import { BaseFieldInput, BaseFieldLayout } from "../common/field";
import { type Field, type FieldState, useBaseFieldState } from "../common/form";
import { FieldLabel } from "../common/label";

export type SelectState<T> = FieldState<T | null>;

export type SelectOption<T> = {
  value: T;
  label: string;
  hasSubOptions?: boolean;
  subOptions?: SelectOption<T>[];
  areSubOptionsLoading?: boolean;
};

export type SelectGroup<T> = {
  options: SelectOption<T>[];
  label: string;
};

type SelectItemOption<T> = {
  type: "option";
  key: string;
  label: string;
  value: T;
  item: JSX.Element;
  parent?: T;
  subOptions?: SelectItemOption<T>[];
};

type SelectItemGroup<T> = {
  type: "group";
  key: undefined;
  label: string;
  value: undefined;
  item: JSX.Element;
  options: SelectItemOption<T>[];
};

type SelectItem<T> = SelectItemGroup<T> | SelectItemOption<T>;

export type SelectImperativeRef = {
  open: () => void;
  close: () => void;
};

export function Select<T>({
  label,
  description,
  placeholder,
  field,
  onSelectionChange,
  options,
  renderItem,
  icon,
  children,
  mobile,
  desktop,
  variant = "auto",
  showArrowDown = true,
  showSearchIcon,
  showParent,
  toKeyString = (value: T) => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    }

    return String(value);
  },
  onClose,
  onSubmenuOpen,
  onSubmenuClose,
  closeOnSelect = true,
  minWidth,
  fontWeight = "regular",
  imperativeRef,
  isInputRef,
}: {
  label?: string;
  description?: string;
  placeholder?: string;
  field: Field<SelectState<T>>;
  onSelectionChange?: (option: SelectItemOption<T>) => void;
  options: (SelectOption<T> | SelectGroup<T>)[];
  renderItem?: MenuOptions<SelectOption<T>>["renderItem"];
  mobile?: {
    title?: Content<OverlayContentOptions>;
    submenuTitle?: Content<OverlayContentOptions>;
    height?: SizeUnit;
    minHeight?: SizeUnit;
    footer?: Content<OverlayContentOptions>;
  };
  desktop?: {
    width?: SizeUnit;
  };
  variant?: "auto" | "desktop" | "mobile";
  showSearchIcon?: ReactNode;
  showArrowDown?: boolean;
  showParent?: boolean;
  icon?: ReactNode;
  fontWeight?: "regular" | "semibold";
  /**
   * Get unique key string from value
   * By default, converts objects by JSON.stringify which can be slow
   *
   * If can, please, provide simple string value thats unique with each value
   */
  toKeyString?: (value: T) => string;
  onClose?: () => void;
  onSubmenuOpen?: (key: string) => void;
  onSubmenuClose?: (key: string) => void;
  closeOnSelect?: boolean;
  minWidth?: SizeUnit;
  imperativeRef?: RefObject<SelectImperativeRef>;
  isInputRef?: boolean;
} & TriggerChildrenProps) {
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();
  const { isMobile } = useScreenDetector();

  const fieldRef = useRef<HTMLDivElement>(null);
  const triggerRef = useRef<HTMLButtonElement>(null);

  const { isFocused, focusProps } = useFocusRing({
    within: true,
  });

  const { items, flatOptions } = useMemo(() => {
    const items = options.map((option) => {
      function processOption(
        option: SelectOption<T>,
        parent?: T,
      ): SelectItemOption<T> {
        const key = toKeyString(option.value);

        const subOptions = option.subOptions?.map((subOption) =>
          processOption(subOption, option.value),
        );

        return {
          type: "option" as const,
          key,
          label: option.label,
          value: option.value,
          subOptions,
          parent,
          item: (
            <Item
              key={key}
              textValue={option.label}
              hasChildItems={option.hasSubOptions}
              childItems={
                option.areSubOptionsLoading
                  ? []
                  : subOptions?.map(({ item }) => item)
              }
              {...{
                areChildItemsLoading: option.areSubOptionsLoading,
              }}
            >
              {option.label}
            </Item>
          ),
        };
      }

      if ("options" in option) {
        const groupOptions = option.options.map((subOption) =>
          processOption(subOption),
        );

        return {
          type: "group",
          key: undefined,
          label: option.label,
          value: undefined,
          item: (
            <Section key={option.label} title={option.label}>
              {groupOptions.map(({ item }) => item)}
            </Section>
          ),
          options: groupOptions,
        } as SelectItem<T>;
      }

      return processOption(option);
    });

    const flatOptions = items.flatMap((item) => {
      if (item.type === "group") {
        return [...item.options];
      }
      return [item, ...(item.subOptions ?? [])];
    });

    return { items, flatOptions };
  }, [options]);

  const getParents = (option: SelectItemOption<T>): string[] => {
    const line: string[] = [option.label];
    const parent = option.parent
      ? flatOptions.find((o) => o.value === option.parent)
      : undefined;

    if (parent) {
      line.push(...getParents(parent));
    }

    return line;
  };

  const ariaProps: SelectProps<String> = {
    label: label ?? placeholder ?? "select",
    description,
    errorMessage: field.error?.message,
    selectedKey:
      field.value !== null && field.value !== undefined
        ? toKeyString(field.value)
        : null,
    isRequired: field.required,
    isDisabled: field.disabled,
    isInvalid: !!field.error?.visible,
    placeholder,
    children: items.map(({ item }) => item),
    onSelectionChange: (key) => {
      const item = flatOptions.find((option) => option.key === key);
      if (!item) return;

      field.onChange({
        ...field,
        value: item.value ?? null,
      });
      onSelectionChange?.(item);
    },
    onOpenChange(isOpen) {
      if (!isOpen) {
        onClose?.();
      }
    },
  };

  const selectState = useAriaSelectState(ariaProps);
  const selectedItem = flatOptions.find(
    (option) => option.key === selectState.selectedKey,
  );

  const {
    labelProps,
    descriptionProps,
    errorMessageProps,
    triggerProps,
    valueProps,
    menuProps: selectMenuProps,
  } = useSelect(ariaProps, selectState, triggerRef);
  const menuTriggerState = useMenuTriggerState(selectState);
  const treeState = useTreeState({
    ...selectMenuProps,
    selectionMode: "single",
    selectedKeys: selectState.selectedKey ? [selectState.selectedKey] : [],
    collection: selectState.collection,
    onSelectionChange([key]) {
      if (closeOnSelect) {
        selectState.setSelectedKey(key ?? null);
      } else if (key) {
        ariaProps.onSelectionChange?.(key);
      }
    },
  });

  const modalState = useModalState({
    parentState: selectState,
  });

  const contentOptions = {
    close: () => {
      modalState.close();
    },
  };

  const isModalVariant =
    (isMobile && variant !== "desktop") || variant === "mobile";

  const menuList = (
    <Menu
      menuProps={selectMenuProps}
      options={{
        state: treeState,
        rootTriggerState: menuTriggerState,
        onSubmenuOpen: onSubmenuOpen,
        onSubmenuClose: onSubmenuClose,
        isModalSubmenu: isModalVariant,
        mobile,
        renderItem,
        getOption(key) {
          return flatOptions.find(
            (option) => toKeyString(option.value) === key,
          );
        },
        closeOnSelect,
        onClose() {
          selectState.close();
        },
      }}
    />
  );

  useImperativeHandle(imperativeRef, () => {
    return {
      open() {
        modalState.open();
      },
      close() {
        modalState.close();
      },
    };
  });

  return (
    <>
      <BaseFieldLayout
        fieldRef={isInputRef ? undefined : fieldRef}
        label={
          label && (
            <FieldLabel
              labelProps={labelProps}
              disabled={field.disabled}
              required={field.required}
            >
              {label}
            </FieldLabel>
          )
        }
        input={
          <>
            <HiddenSelect
              label={label}
              state={selectState}
              triggerRef={triggerRef}
            />
            {children ? (
              children(triggerRef, triggerProps)
            ) : (
              <BaseFieldInput
                fieldRef={isInputRef ? fieldRef : undefined}
                field={field}
                focused={isFocused}
                fieldProps={focusProps}
                input={
                  <TriggerButton
                    triggerProps={triggerProps}
                    ref={triggerRef}
                    styles={css([
                      cssFns.margin("0px"),
                      cssFns.border({ width: "0px", style: "none" }),
                      cssFns.padding("0px", "10px"),
                      {
                        minWidth: minWidth ?? 50,
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "transparent",
                        outlineStyle: "none",
                        "&:hover": {
                          cursor: "pointer",
                        },
                      },
                    ])}
                  >
                    <div
                      css={{
                        display: "grid",
                      }}
                    >
                      <span
                        {...valueProps}
                        css={[
                          cssFns.typo({
                            level: "body-1",
                            weight: fontWeight,
                          }),
                          {
                            color: selectedItem
                              ? principalColors.gs2
                              : principalColors.gs4,
                            overflowX: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          },
                        ]}
                      >
                        {typeof selectedItem !== "undefined"
                          ? showParent
                            ? getParents(selectedItem).join(", ")
                            : selectedItem.label
                          : placeholder}
                      </span>
                    </div>
                  </TriggerButton>
                }
                startIcon={
                  showSearchIcon ? (
                    <MobileMenuSearch color={secondaryColors.magenta} />
                  ) : (
                    icon
                  )
                }
                endIcon={
                  showArrowDown && (
                    <ButtonV2
                      text={`select arrow ${selectState.isOpen ? "up" : "down"}`}
                      variant="icon"
                      onPress={() => selectState.toggle()}
                      icon={
                        selectState.isOpen ? (
                          <DesktopArrowUp color={principalColors.gs8} />
                        ) : (
                          <DesktopArrowDown color={principalColors.gs8} />
                        )
                      }
                    />
                  )
                }
                disabled={field.disabled}
              />
            )}
          </>
        }
        description={
          description && (
            <FieldDescription
              disabled={field.disabled}
              descriptionProps={descriptionProps}
            >
              {description}
            </FieldDescription>
          )
        }
        error={
          field.error?.visible && (
            <FieldError
              errorMessageProps={errorMessageProps}
              errorMessage={field.error.message}
              fieldRequired={field.required}
            />
          )
        }
      />

      {isModalVariant
        ? modalState.isOpen && (
            <Modal
              state={modalState}
              onClose={() => {
                modalState.close();
              }}
              title={renderContent(mobile?.title, contentOptions)}
              main={menuList}
              footer={renderContent(mobile?.footer, contentOptions)}
              styles={css({
                height: mobile?.height,
                minHeight: mobile?.minHeight,
              })}
            />
          )
        : selectState.isOpen && (
            <Popover
              triggerRef={fieldRef}
              state={selectState}
              maxHeight={350}
              styles={css([
                { width: desktop?.width ?? fieldRef.current?.clientWidth },
              ])}
              offset={2}
              closeOnInteractOutside
            >
              {menuList}
            </Popover>
          )}
    </>
  );
}

export function useSelectState<T>(defaultState?: SelectState<T>) {
  return useBaseFieldState<SelectState<T>>({
    value: null,
    error: undefined,
    ...defaultState,
  });
}

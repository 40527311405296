import { css } from "@emotion/react";
import type { DoctorsListMeta, ServiceDetails } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import {
  cssFns,
  LayoutContainer,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import type { ReactNode } from "react";
import Skeleton from "react-loading-skeleton";

import { useAppConfig } from "#internal/app/config";
import { useRoutePath } from "#internal/app/router";
import { Breadcrumbs } from "#internal/shared/ui/breadcrumbs";

import { DoctorsListServiceDescription } from "./service-description";
import { DoctorsListTitle } from "./title";

export const DoctorListHeader = ({
  filters,
  isMetaLoading,
  service,
  meta,
}: {
  filters: ReactNode;
  isMetaLoading: boolean;
  service: ServiceDetails;
  meta?: DoctorsListMeta;
}) => {
  const { routePath } = useRoutePath();
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();
  const message = useMessage();
  const { city, cityArea } = useAppConfig();

  if (isMobile) {
    return (
      <>
        {meta && (
          <DoctorsListTitle
            styles={css({ display: "none" })}
            city={city.name}
            count={meta.count}
            serviceName={service.name}
            cityArea={cityArea?.name}
          />
        )}
        <div
          css={[
            cssFns.padding(10),
            {
              backgroundColor: principalColors.ebblue,
              paddingInlineEnd: 0,
              overflowX: "auto",
              scrollbarWidth: "none",
            },
          ]}
        >
          {filters}
        </div>
      </>
    );
  }

  return (
    <LayoutContainer
      styles={css([
        cssFns.padding(20, 12),
        {
          backgroundColor: principalColors.white,
        },
      ])}
    >
      <div css={{ marginBlockEnd: 30 }}>
        <div css={{ marginBlockEnd: 24 }}>
          <Breadcrumbs
            links={[
              {
                text: message({
                  id: "common.home-page",
                }),
                to: routePath({
                  path: "/",
                }),
              },
              service.type === "procedure"
                ? {
                    text: message({
                      id: "common.services.all-procedures",
                    }),
                    to: routePath({
                      path: "/procedures/:city",
                      params: {
                        city: city.slug,
                      },
                    }),
                  }
                : {},
              {
                text: service.name,
              },
            ]}
          />
        </div>
        {!isMetaLoading ? (
          meta && (
            <DoctorsListTitle
              styles={css({ marginBlockEnd: 8 })}
              city={city.name}
              count={meta.count}
              serviceName={service.name}
              cityArea={cityArea?.name}
            />
          )
        ) : (
          <Skeleton css={{ height: 37, marginBlockEnd: 8 }} />
        )}
        {!isMetaLoading ? (
          meta &&
          meta.description && (
            <DoctorsListServiceDescription
              count={meta.count}
              description={meta.description}
              rating={meta.feedback_score}
              service={service.name}
            />
          )
        ) : (
          <Skeleton css={{ height: 20 }} />
        )}
      </div>
      {filters}
    </LayoutContainer>
  );
};

import { css } from "@emotion/react";
import type { Doctor } from "@hermes/api";
import { Message, useFormatNumber } from "@hermes/intl";
import { cssFns, usePrincipalColors, useScreenDetector } from "@hermes/ui";
import { Link } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";

import { DoctorProfileImage } from "./profile-image";

export const DoctorProfileReview = ({
  doctor,
  disableReview = false,
  doctor_service_id,
  imageSize,
  isClickable = true,
}: {
  doctor?: Doctor;
  doctor_service_id?: number;
  disableReview?: boolean;
  imageSize?: string;
  isClickable?: boolean;
}) => {
  const { routePath } = useRoutePath();
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();
  const formatNumber = useFormatNumber();

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <DoctorProfileImage
        doctor={doctor}
        size={(imageSize ?? isMobile) ? "80px" : "110px"}
        data-selector={disableReview ? "doctorProfilePhoto" : ""}
        doctor_service_id={doctor_service_id}
        styles={css({
          marginBlockEnd: !disableReview
            ? isMobile
              ? "8px"
              : "12px"
            : undefined,
        })}
        isClickable={isClickable}
      />
      {doctor && !!doctor.feedback_score && !disableReview && (
        <div
          css={[
            cssFns.typo({ weight: "regular", level: "body-1" }),
            { color: principalColors.gs2 },
          ]}
        >
          {formatNumber(doctor.feedback_score, {
            maximumFractionDigits: 1,
            minimumFractionDigits: 1,
          })}
        </div>
      )}
      {doctor && !disableReview && (
        <Link
          to={routePath({
            path: "/doctor/:slug",
            params: {
              slug: doctor.slug,
            },
            hash: "reviews",
            query: {
              doctor_service_id: doctor_service_id?.toString(),
            },
          })}
          css={[
            isMobile
              ? cssFns.typo({ weight: "regular", level: "body-3" })
              : cssFns.typo({ weight: "regular", level: "body-2" }),
            {
              color: principalColors.ebblue,
              textDecoration: "none",
            },
          ]}
        >
          <Message
            id="common.count.feedbacks"
            default="{count} {count, plural, one {отзыв} few {отзыва} other {отзывов}}"
            values={{
              count: doctor.feedback_count,
            }}
          />
        </Link>
      )}
    </div>
  );
};

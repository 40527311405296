import type { User } from "@hermes/api";
import { truthy } from "@hermes/ui";
import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useState,
} from "react";
import Cookies, { type CookieGetOptions } from "universal-cookie";

export type UserRole =
  | "clinic_admin"
  | "clinic_branch_admin"
  | "admin"
  | "account_manager"
  | "copywriter"
  | "operator"
  | "operator_manager";
type ConditionRole = UserRole | "unauthorized";

type AuthContextType = (
  | {
      isAuthenticated: true;
      user: User;
      role: UserRole;
    }
  | {
      isAuthenticated: false;
      user?: undefined;
      role?: undefined;
    }
) & {
  authenticate: (user: User) => void;
  signOut: () => void;
  hasRole: (roles: ConditionRole[]) => boolean;
};

const cookies = new Cookies();

export const Context = createContext<AuthContextType | undefined>(undefined);

const mapUserRole = (user: User): UserRole | undefined => {
  if (user.is_superuser) return "admin";
  if (user.is_clinic_administrator) return "clinic_admin";
  if (user.is_clinic_branch_administrator) return "clinic_branch_admin";
  if (user.is_account_manager) return "account_manager";
  if (user.is_copywriter) return "copywriter";
  if (user.is_operator) return "operator";
  if (user.is_operator_manager) return "operator_manager";
  return undefined;
};

export const getUserCookie = (options?: CookieGetOptions): User | undefined =>
  cookies.get("user", options);

export const AuthContext = ({
  children,
  cookieDomain,
}: {
  children: ReactNode;
  cookieDomain: string;
}) => {
  const [user, setUser] = useState<User | undefined>(getUserCookie());
  const role = user ? mapUserRole(user) : undefined;

  const authenticate = useCallback(
    (user: User) => {
      cookies.set("user", user, {
        domain: cookieDomain,
        path: "/",
      });
      setUser(user);
    },
    [cookieDomain],
  );
  const signOut = useCallback(() => {
    // Try removing subdomains
    let domainParts = cookieDomain.split(".").filter(truthy);
    while (getUserCookie({ doNotParse: true }) && domainParts.length) {
      cookies.remove("user", {
        domain: domainParts.join("."),
        path: "/",
      });
      domainParts = domainParts.slice(1);
    }
    setUser(undefined);
  }, [cookieDomain]);

  const hasRole = useCallback(
    (roles: ConditionRole[]): boolean => {
      if (!role) {
        return roles.includes("unauthorized");
      }
      return roles.includes(role);
    },
    [role],
  );

  return (
    <Context.Provider
      value={
        user && role
          ? {
              isAuthenticated: true,
              user,
              role,
              authenticate,
              signOut,
              hasRole,
            }
          : {
              isAuthenticated: false,
              authenticate,
              signOut,
              hasRole,
            }
      }
    >
      {children}
    </Context.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error("useAuth must be called within AuthProvider");
  }
  return context;
};

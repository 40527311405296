import { css } from "@emotion/react";
import { useRef } from "react";
import {
  type AriaTextFieldOptions,
  mergeProps,
  useFocusRing,
  useTextField,
} from "react-aria";
import { NumberFormatBase, usePatternFormat } from "react-number-format";

import { cssFns } from "../../css";
import { useSecondaryColors } from "../../theme";
import { FieldError } from "../common/error";
import { BaseFieldInput, BaseFieldLayout } from "../common/field";
import type { Field } from "../common/form";
import { type TextFieldState, useTextFieldStyles } from "./text-field";

type OneTimeCodeState = TextFieldState;

export const OneTimeCodeField = ({
  field,
  label,
  description,
  name,
  placeholder,
  autoComplete = "one-time-code",
  autoFocus,
}: {
  field: Field<OneTimeCodeState>;
  label?: string;
  description?: string;
  placeholder?: string;
  name?: string;
  autoFocus?: boolean;
  /**
   * @default "one-time-code"
   */
  autoComplete?: "on" | "off" | "name" | "one-time-code";
}) => {
  const patternProps = usePatternFormat({
    format: "####",
    allowEmptyFormatting: false,
    mask: "-",
    type: "text",
  });
  const secondaryColors = useSecondaryColors();

  const options: AriaTextFieldOptions<"input"> = {
    label,
    "aria-label": label ? undefined : (placeholder ?? "one-time-code"),
    description,
    name,
    isRequired: field.required,
    isDisabled: field.disabled,
    value: field.value,
    isInvalid: !!field.error?.visible,
    placeholder,
    autoFocus,
    autoComplete,
    onChange(value: string) {
      field.onChange({
        ...field,
        value,
      });
    },
  };

  const ref = useRef<HTMLInputElement>(null);
  const { focusProps, isFocused } = useFocusRing({
    isTextInput: true,
    within: true,
  });

  const { inputProps, errorMessageProps } = useTextField(options, ref);

  const styles = css([
    useTextFieldStyles({
      disabled: field.disabled,
    }),
  ]);

  return (
    <BaseFieldLayout
      styles={{
        container: {
          flexDirection: "row",
          gap: 6,
          alignItems: "center",
        },
      }}
      input={
        <BaseFieldInput
          multiline
          styles={css([
            {
              width: 130,
              ...(field.error?.visible && {
                background: cssFns.setOpacity(secondaryColors.red, 0.1),
                borderColor: secondaryColors.red,
              }),
              input: [
                cssFns.typo({ level: "title-3", weight: "semibold" }),
                {
                  letterSpacing: "5px",
                  textAlign: "center",
                  padding: 0,
                },
              ],
            },
            cssFns.padding(16, 20),
          ])}
          field={field}
          focused={isFocused}
          fieldProps={focusProps}
          showIcon={false}
          input={
            <NumberFormatBase
              {...mergeProps(inputProps, patternProps)}
              getInputRef={ref}
              placeholder={placeholder}
              css={styles}
              onValueChange={(values) => {
                options.onChange?.(values.formattedValue);
              }}
              autoComplete={autoComplete}
            />
          }
          disabled={field.disabled}
        />
      }
      error={
        field.error?.visible && (
          <FieldError
            errorMessageProps={errorMessageProps}
            errorMessage={field.error.message}
            fieldRequired={field.required}
          />
        )
      }
    />
  );
};

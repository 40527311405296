import { useCallback, useEffect, useMemo } from "react";
import {
  createSearchParams,
  useSearchParams as useURLSearchParams,
} from "react-router-dom";

import type { Schema, SearchParamsType } from "./types";

type Options = {
  allowUnknown?: boolean;
};

const fromURL = <S extends Schema>(
  schema: S,
  params: URLSearchParams,
  options?: Options,
): SearchParamsType<S> => {
  let result = {} as SearchParamsType<S>;

  for (const [key, type] of Object.entries(schema)) {
    const value = type.isArray
      ? type.parse(params.getAll(key))
      : type.parse(params.get(key) ?? undefined);
    if (value !== undefined) {
      result = { ...result, [key]: value };
    }
  }

  if (options?.allowUnknown) {
    for (const [key, value] of params.entries()) {
      if (key in schema) continue;
      result = {
        ...result,
        [key]: value,
      };
    }
  }

  return result;
};

const toURL = <S extends Schema>(
  schema: S,
  params: SearchParamsType<S>,
  options?: Options,
): URLSearchParams => {
  const searchParams: Record<string, string> = Object.keys(schema).reduce(
    (acc, key) => {
      const preparedValue = schema[key]?.format(params[key] as any);
      if (preparedValue === undefined) return acc;
      return { ...acc, [key]: preparedValue };
    },
    {},
  );

  if (options?.allowUnknown) {
    for (const [key, value] of Object.entries(params)) {
      if (key in schema) continue;
      searchParams[key] = value;
    }
  }

  return createSearchParams(searchParams);
};

export function useSearchParams<S extends Schema>(
  schema: S,
  options?: Options,
) {
  const [searchParams, setSearchParams] = useURLSearchParams();

  const typedSearchParams = useMemo(
    () => fromURL(schema, searchParams, options),
    [searchParams, schema],
  );

  const updateUrl = useCallback(
    (
      newParams:
        | SearchParamsType<S>
        | ((old: SearchParamsType<S>) => SearchParamsType<S>),
    ) => {
      setSearchParams(
        (prev) => {
          const params =
            typeof newParams === "function"
              ? newParams(fromURL(schema, prev, options))
              : newParams;
          const currentParams = fromURL(
            schema,
            new URLSearchParams(document.location.search),
            options,
          );

          return toURL(
            schema,
            {
              ...currentParams,
              ...params,
            },
            options,
          );
        },
        { replace: true },
      );
    },
    [schema, setSearchParams],
  );

  useEffect(() => {
    const validatedParams = toURL(
      schema,
      typedSearchParams,
      options,
    ).toString();
    const currentParams = searchParams.toString();

    if (validatedParams !== currentParams) {
      updateUrl(typedSearchParams);
    }
  }, [searchParams, schema, updateUrl, typedSearchParams]);

  return [typedSearchParams, updateUrl] as const;
}

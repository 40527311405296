import type { SerializedStyles } from "@emotion/react";
import type { Doctor } from "@hermes/api";
import { useMessage } from "@hermes/intl";
import {
  cssFns,
  truthy,
  usePrincipalColors,
  useScreenDetector,
  useSecondaryColors,
} from "@hermes/ui";
import { createElement } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";

import { Chip } from "./chip";
import { DoctorProfileInfoDetails } from "./profile-info-details";

export const DoctorProfileInfo = ({
  styles,
  doctor,
  qualification,
  services,
  premium,
  integrated,
  titleFontSize,
  heading = "h2",
  isExpDisabled = false,
  areServicesLoading,
}: {
  titleFontSize?: SerializedStyles;
  styles?: SerializedStyles;
  doctor?: Doctor;
  qualification?: string | null;
  services?: {
    id: number;
    service_name?: string;
  }[];
  premium?: boolean;
  integrated?: boolean;
  heading?: "h1" | "h2" | "h3";
  isExpDisabled?: boolean;
  areServicesLoading?: boolean;
}) => {
  const { routePath } = useRoutePath();
  const { isMobile } = useScreenDetector();
  const message = useMessage();
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();

  const commonTextStyles = [
    titleFontSize
      ? titleFontSize
      : isMobile
        ? cssFns.typo({ weight: "semibold", level: "title-4" })
        : cssFns.typo({ weight: "semibold", level: "title-3" }),
    {
      a: { color: principalColors.gs2 },
    },
  ];

  const [first, second, third] = doctor?.name.split(" ") || [];
  const isLongName = (first?.length || 0) > 5;
  const doctorServiceId = services?.[0]?.id.toString();

  return (
    <div
      css={[
        styles,
        {
          ".profile-info-text": commonTextStyles,
          ".profile-info-text-0": [commonTextStyles, { margin: 0 }],
        },
      ]}
    >
      {doctor ? (
        <div css={{ marginBlockEnd: 8 }}>
          {heading === "h1" ? (
            <h1 itemProp="name" className="profile-info-text">
              {isLongName ? (
                <>
                  <span css={{ display: "block" }}> {first}</span>
                  <span css={{ display: "block" }}>
                    {second} {third}
                  </span>
                </>
              ) : (
                doctor.name
              )}
            </h1>
          ) : (
            <div
              css={{
                display: "grid",
                gridTemplateColumns: "1fr auto auto",
              }}
            >
              {createElement(heading, {
                children: (
                  <Link
                    to={routePath({
                      path: "/doctor/:slug",
                      params: {
                        slug: doctor.slug,
                      },
                      query: {
                        doctor_service_id: doctorServiceId,
                      },
                    })}
                    css={{
                      textDecoration: "none",
                    }}
                    target={isMobile ? undefined : "_blank"}
                  >
                    {isLongName ? (
                      <>
                        <span>{first}</span>
                        <br />
                        <span>{[second, third].join(" ")}</span>
                      </>
                    ) : (
                      doctor.name
                    )}
                  </Link>
                ),
                className: "profile-info-text-0",
              })}
              <div>
                {premium && (
                  <Chip
                    color={principalColors.ebblue}
                    text={message({
                      id: "ba0c46f4-336c-488a-a2d2-75ce93c42687",
                      default: "Промо",
                    })}
                  />
                )}
              </div>
              <div>
                {integrated && (
                  <Chip
                    color={secondaryColors.midgreen}
                    text={message({
                      id: "6dba58a0-24c1-437f-975a-417b7ac965a5",
                      default: "Интегративный",
                    })}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        <Skeleton css={{ height: 54, marginBlockEnd: 8 }} />
      )}
      {areServicesLoading ? (
        <Skeleton css={{ height: 48 }} />
      ) : (
        <DoctorProfileInfoDetails
          academic_status={doctor?.academic_status}
          experience={!isExpDisabled ? doctor?.experience : undefined}
          science_degree={doctor?.science_degree}
          qualification={qualification}
          services={services
            ?.map((service) => service.service_name)
            .filter(truthy)}
        />
      )}
    </div>
  );
};

import { DoctorCard } from "../doctor-card";

export const DoctorsListLoading = ({
  limit = 5,
  isBordered,
  onInView,
}: {
  limit?: number;
  isBordered?: boolean;
  onInView?: () => void;
}) => {
  return (
    <>
      {Array.from({ length: limit }).map((_, index) => (
        <DoctorCard
          key={index}
          isBordered={isBordered}
          onInView={onInView}
          pageHeader=""
        />
      ))}
    </>
  );
};

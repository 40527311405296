import type { City, CityArea } from "@hermes/api";
import { Message, useFormatNumber } from "@hermes/intl";
import {
  cssFns,
  truthy,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import { Link, useLocation } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";
import mapIconSrc from "#internal/shared/assets/images/on_map_icon.svg";

export const DoctorClinicInfo = ({
  name,
  address,
  slug,
  city,
  cityArea,
  noRedirectToClinic,
}: {
  name?: string;
  address?: string;
  slug?: string;
  city?: City;
  cityArea?: CityArea;
  noRedirectToClinic?: boolean;
}) => {
  const { routePath } = useRoutePath();
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();

  if (!address && !name) return null;

  return (
    <div>
      <div
        css={[
          cssFns.typo({ weight: "semibold", level: "body-1" }),
          {
            color: principalColors.gs2,
            marginBlockEnd: 4,
            userSelect: noRedirectToClinic ? "none" : undefined,
          },
        ]}
      >
        {city?.slug && slug && !noRedirectToClinic ? (
          <Link
            css={{
              color: "inherit",
              textDecoration: "none",
            }}
            to={routePath({
              path: "/clinics/:city/:slug",
              params: {
                city: city.slug,
                slug: slug,
              },
            })}
            target={isMobile ? undefined : "_blank"}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {name}
          </Link>
        ) : (
          name
        )}
      </div>
      <div
        css={[
          cssFns.typo({ weight: "regular", level: "body-1" }),
          { color: principalColors.gs4 },
        ]}
      >
        <div
          itemScope
          itemProp="address"
          itemType="http://schema.org/PostalAddress"
          css={{
            display: "flex",
            columnGap: 8,
            alignItems: "center",
            color: principalColors.gs4,
          }}
        >
          <span itemProp="streetAddress">
            {[address, cityArea?.name, city?.name].filter(truthy).join(", ")}
          </span>
        </div>
      </div>
    </div>
  );
};

export const DoctorClinicMetroInfo = ({
  name,
  distance,
  icon_url,
  icon_alt,
}: {
  name: string;
  distance: number;
  icon_url: string;
  icon_alt: string;
}) => {
  const principalColors = usePrincipalColors();
  const formatNumber = useFormatNumber();

  const kilometers = distance / 1000;

  return (
    <div
      css={[
        cssFns.typo({ weight: "regular", level: "body-1" }),
        {
          display: "flex",
          columnGap: 6,
          alignItems: "center",
          color: principalColors.gs4,
        },
      ]}
    >
      <img
        css={{
          width: 20,
        }}
        src={icon_url}
        alt={icon_alt}
      />
      <div css={{ width: "fit-content" }}>{name}</div>
      <div css={{ whiteSpace: "nowrap" }}>
        (
        {formatNumber(kilometers < 1 ? distance : kilometers, {
          style: "unit",
          unit: kilometers < 1 ? "meter" : "kilometer",
          maximumFractionDigits: 1,
          minimumFractionDigits: 0,
        })}
        )
      </div>
    </div>
  );
};

export const DoctorClinicMapInfo = ({
  lat,
  lng,
  distance,
}: {
  lat: number;
  lng: number;
  distance?: number | null;
}) => {
  const { routePath } = useRoutePath();
  const principalColors = usePrincipalColors();
  const formatNumber = useFormatNumber();
  const location = useLocation();
  const { isMobile } = useScreenDetector();

  const kilometers = distance ? distance / 1000 : undefined;

  return (
    <div
      css={{
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
      data-selector="onMapLink"
    >
      <img src={mapIconSrc} alt="onMapIcon" />
      <Link
        css={[
          cssFns.typo({ level: "body-1", weight: "regular" }),
          { color: principalColors.gs4, display: "flex", columnGap: "4px" },
        ]}
        to={routePath({
          path: "/map",
          query: {
            lat,
            lng,
          },
        })}
        target={isMobile ? undefined : "_blank"}
        state={location}
      >
        {kilometers && distance && (
          <>
            {formatNumber(kilometers < 1 ? distance : kilometers, {
              style: "unit",
              unit: kilometers < 1 ? "meter" : "kilometer",
              maximumFractionDigits: 0,
              minimumFractionDigits: 0,
            })}
            {" · "}
          </>
        )}
        <Message id="76518388-67e1-440c-b7fe-91295b86779d" default="На карте" />
      </Link>
    </div>
  );
};

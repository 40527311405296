import { cssFns, usePrincipalColors, useScreenDetector } from "@hermes/ui";
import { Link as RouterLink, type To } from "react-router-dom";

type Link = {
  text?: string;
  to?: To;
  links?: Link[];
};

export const Breadcrumbs = ({ links }: { links: Link[] }) => {
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();

  const renderLinks = (links: Link[]) => {
    return links.map((link, index) => {
      if (Object.keys(link).length === 0) return false;
      const isLast = index === links.length - 1;

      return (
        <span
          key={index}
          itemProp="itemListElement"
          itemScope
          itemType="http://schema.org/ListItem"
          css={cssFns.typo({ level: "body-1", weight: "regular" })}
        >
          {link.to ? (
            <RouterLink
              to={link.to}
              itemProp="item"
              css={[
                {
                  color: principalColors.ebblue,
                  whiteSpace: "nowrap",
                  textDecoration: "none",
                },
              ]}
            >
              {link.text}
            </RouterLink>
          ) : (
            <span
              itemProp="item"
              css={[{ whiteSpace: "nowrap", color: principalColors.gs4 }]}
            >
              {link.text}
            </span>
          )}

          {link.links &&
            link.links.map((subLink, subIndex) => (
              <span
                key={subIndex}
                css={[
                  { color: principalColors.gs8 },
                  cssFns.typo({ level: "body-1", weight: "regular" }),
                ]}
              >
                {subIndex !== 0 && `, `}
                {subLink.to ? (
                  <RouterLink
                    to={subLink.to}
                    itemProp="item"
                    css={[
                      {
                        color: principalColors.ebblue,
                        textDecoration: "none",
                      },
                    ]}
                  >
                    {subLink.text}
                  </RouterLink>
                ) : (
                  <span
                    itemProp="item"
                    css={[{ whiteSpace: "nowrap", color: principalColors.gs4 }]}
                  >
                    {subLink.text}
                  </span>
                )}
              </span>
            ))}

          {!isLast && (
            <span
              css={[
                {
                  display: "inline-block",
                  whiteSpace: "nowrap",
                },
                cssFns.typo({ level: "body-1", weight: "regular" }),
              ]}
            >
              &nbsp;/&nbsp;
            </span>
          )}

          <meta itemProp="position" content={`${index + 1}`} />
        </span>
      );
    });
  };

  return (
    <div
      itemScope
      itemType="http://schema.org/BreadcrumbList"
      css={[
        cssFns.typo({ level: "body-2", weight: "regular" }),
        {
          color: principalColors.gs8,
          whiteSpace: isMobile ? "nowrap" : "none",
          overflowX: "auto",
          WebkitOverflowScrolling: "touch",
          "::-webkit-scrollbar": {
            display: "none",
          },
          a: [
            cssFns.typo({ level: "body-1", weight: "regular" }),
            {
              ":hover": {
                opacity: 0.5,
              },
              lineHeight: "28px",
            },
          ],
          span: {
            lineHeight: "28px",
          },
        },
      ]}
    >
      {renderLinks(links)}
    </div>
  );
};

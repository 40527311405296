import {
  type InfiniteData,
  useInfiniteQuery,
  type UseInfiniteQueryOptions,
  useQuery,
  type UseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import { type PaginatedResponse, useHermesQueries } from "../common";
import type { Doctor, DoctorsListOrder } from "./definitions";

export type BaseDoctorsListParams = {
  limit?: number;
  search?: string;
  city?: number;
  city_area?: number;
  service?: number[];
  clinic?: number;
  clinic_branch?: number[];
  ordering?: DoctorsListOrder;
  lat?: number;
  lng?: number;
  age_from?: number | null;
  age_to?: number | null;
  slot_date?: string;
  language?: string;
  expand?: ("clinic_branches" | "services")[];
  insurance?: number;
};

type UseV1DoctorsListParams = {
  offset?: number;
} & BaseDoctorsListParams;
export type UseV1DoctorsListResult = PaginatedResponse<Doctor[]>;

type UseV1DoctorsListOptions = UseInfiniteQueryOptions<
  UseV1DoctorsListResult,
  Error,
  InfiniteData<UseV1DoctorsListResult>
>;

export const useV1DoctorsList = (
  params: UseV1DoctorsListParams,
  options?: Partial<UseV1DoctorsListOptions>,
) => {
  const url = `/api/v1/doctors/`;
  const { api } = useApi();

  return useInfiniteQuery({
    ...options,
    queryKey: [url, params],
    queryFn: ({ pageParam }) => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            offset: params.offset ?? pageParam,
            clinic_branch: params.clinic_branch?.join(","),
            service: params.service?.join(","),
            expand: params.expand?.join(","),
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
    initialPageParam: params.offset,
    getNextPageParam({ next }) {
      if (!next) return undefined;
      const url = new URL(next);

      return url.searchParams.get("offset");
    },
  });
};

type UseV1DoctorsListParallelParams = {
  pages: { offset?: number; seen: boolean }[];
} & BaseDoctorsListParams;
type UseV1DoctorsListParallelResult = PaginatedResponse<Doctor[]>;
type UseV1DoctorsListParallelOptions =
  UseQueryOptions<UseV1DoctorsListParallelResult>;

export const useV1DoctorsListParallel = (
  { pages, ...params }: UseV1DoctorsListParallelParams,
  options?: Partial<UseV1DoctorsListParallelOptions>,
) => {
  const url = `/api/v1/doctors/`;
  const { api } = useApi();

  return useHermesQueries({
    queries: pages.map((page) => {
      return {
        enabled: typeof page.offset === "number" && page.seen,
        queryKey: [url, page.seen, page.offset, params],
        queryFn: () => {
          return api(
            {
              url,
              method: "GET",
              params: {
                ...params,
                offset: page.offset,
                clinic_branch: params.clinic_branch?.join(","),
                service: params.service?.join(","),
                expand: params.expand?.join(","),
              },
            },
            {
              200: async (r) => r.data,
            },
          );
        },
        ...options,
      };
    }),
  });
};

type UseV0OnlineDoctorsListParams = {
  search?: string;
  clinicBranchId: number;
};

type UseV0OnlineDoctorsListOptions = UseQueryOptions<
  UseV1DoctorsListResult,
  Error
>;

export const useV0OnlineDoctors = (
  params: UseV0OnlineDoctorsListParams,
  options?: Partial<UseV0OnlineDoctorsListOptions>,
) => {
  const url = `/api/v0/cabinet/${params.clinicBranchId}/online_doctors/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            search: params.search,
          },
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

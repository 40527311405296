import type { SerializedStyles } from "@emotion/react";
import { Message, useFormatNumber } from "@hermes/intl";
import { cssFns, usePrincipalColors } from "@hermes/ui";
import { useState } from "react";

export const DoctorsListServiceDescription = ({
  styles,
  description,
  count,
  rating,
  service,
}: {
  styles?: SerializedStyles;
  service: string;
  count: number;
  rating: number;
  description: string;
}) => {
  const formatNumber = useFormatNumber();
  const principalColors = usePrincipalColors();

  const [descriptionExpanded, setDescriptionExpanded] = useState(false);

  return (
    <div
      css={[
        cssFns.typo({ level: "body-1", weight: "regular" }),
        {
          color: principalColors.gs2,
          marginBlockEnd: descriptionExpanded ? 30 : 0,
          display: descriptionExpanded ? "block" : "flex",
        },
        styles,
      ]}
    >
      <div
        css={[
          {
            p: { marginBlockEnd: 16 },
            b: [cssFns.typo({ level: "body-1", weight: "semibold" }), {}],
            strong: [cssFns.typo({ level: "body-1", weight: "semibold" }), {}],
            ul: { marginBlockEnd: 16, paddingInlineStart: 30 },
          },
          descriptionExpanded
            ? {}
            : {
                width: "680px",
                height: "20px",
                display: "inline-block",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                "p:first-of-type": {
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              },
        ]}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
      {!descriptionExpanded && (
        <span
          css={[
            {
              color: principalColors.ebblue,
              textDecoration: "underline",
              cursor: "pointer",
            },
          ]}
          onClick={() => {
            setDescriptionExpanded(true);
          }}
        >
          <Message
            id="0c55f599-29de-4817-88dd-b9123c0f1598"
            default="Читать далee"
          />
        </span>
      )}
      {descriptionExpanded && (
        <>
          <div
            css={[
              cssFns.padding("16px"),
              cssFns.border({ radius: "4px" }),
              {
                backgroundColor: principalColors.gs10,
                marginBlockEnd: "16px",
                marginBlockStart: 20,
              },
            ]}
          >
            <ul css={{ margin: "0px" }}>
              <li>
                <Message
                  id="a24feb9b-d30c-4a35-9b85-5d504db810c7"
                  default="Лучшие {service}: средний рейтинг врача - {rating}"
                  values={{
                    service: service.toString(),
                    rating: formatNumber(rating, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                    }),
                  }}
                />
              </li>
              <li>
                <Message
                  id="fecaaa1b-b9f1-4a33-85a4-5f63b6c4dc45"
                  default="Проверенные отзывы: {count} проверенных отзыва"
                  values={{
                    count,
                  }}
                />
              </li>
            </ul>
          </div>
          <div
            css={[
              cssFns.padding("16px"),
              cssFns.border({ radius: "4px" }),
              { backgroundColor: "#fffbe6" },
            ]}
          >
            <Message
              id="e74346d4-7106-45cc-847b-51c63873e78e"
              default="Обратите внимание! Информация на странице представлена для ознакомления. Для назначения лечения обратитесь к врачу."
            />
          </div>
        </>
      )}
    </div>
  );
};

import { css } from "@emotion/react";
import {
  type City,
  type CityArea,
  type ClinicBranch,
  type Doctor,
  type DoctorService,
  type Slot,
} from "@hermes/api";
import { Message, Temporal } from "@hermes/intl";
import { cssFns, usePrincipalColors, useScreenDetector } from "@hermes/ui";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Skeleton from "react-loading-skeleton";

import type { AppointmentModalProps } from "../appointment-modal";
import { AppointmentSlots } from "../appointment-slots";
import {
  DoctorClinicInfo,
  DoctorClinicMapInfo,
  DoctorClinicMetroInfo,
} from "./clinic-info";
import { DoctorDesktopBranchSelect } from "./desktop-branch-select";
import { MobileBranchSelect } from "./mobile-branch-select";
import { DoctorProfileInfo } from "./profile-info";
import { DoctorProfileReview } from "./profile-review";
import { DoctorServicePrice } from "./service-price";

export type DoctorCardService = {
  id: number;
  name: string;
  type: "initial-appointment" | "procedure";
};

export type BranchServices = {
  branch: ClinicBranch;
  services: DoctorService[];
};

export const DoctorCard = ({
  doctor,
  city,
  cityArea,
  premium,
  integrated,
  branches_services = [],
  initialSlots,
  isBordered,
  request_meta,
  areServicesLoading,
  areBranchesLoading,
  areSlotsLoading,
  areSlotsEmpty,
  defaultStartDate,
  heading,
  noRedirectToClinic,
  onInView,
}: {
  doctor?: Doctor;
  city?: City;
  cityArea?: CityArea;
  premium?: boolean;
  integrated?: boolean;
  branches_services?: BranchServices[];
  initialSlots?: Map<number, Slot[]>;
  isBordered?: boolean;
  request_meta?: AppointmentModalProps["request_meta"];
  pageHeader: string;
  areServicesLoading?: boolean;
  areBranchesLoading?: boolean;
  areSlotsLoading?: boolean;
  areSlotsEmpty?: boolean;
  defaultStartDate?: Temporal.PlainDate;
  heading?: "h1" | "h2" | "h3";
  noRedirectToClinic?: boolean;
  onInView?: () => void;
}) => {
  const { isMobile } = useScreenDetector();
  const principalColors = usePrincipalColors();

  const view = useInView({
    triggerOnce: true,
    threshold: 0,
    onChange(inView) {
      if (inView) {
        onInView?.();
      }
    },
  });

  const branches = branches_services.map(({ branch }) => branch);
  const [branchId, setBranchId] = useState<number>();

  useEffect(() => {
    if (branches.length > 0 && !branchId) {
      setBranchId(branches[0]?.id);
    }
  }, [branches, branchId, setBranchId]);

  const selected =
    branches_services.find(({ branch }) => branch.id === branchId) ??
    branches_services[0];

  const station = selected?.branch.closest_stations?.[0];

  const branchSlots = selected?.branch
    ? initialSlots?.get(selected.branch.id) || []
    : [];

  return (
    <div
      ref={view.ref}
      css={[
        isBordered && isMobile
          ? cssFns.border({
              width: 1,
              style: "solid",
              color: principalColors.grayline,
            })
          : premium
            ? cssFns.border({
                width: 1,
                style: "solid",
                color: principalColors.ebblue,
              })
            : undefined,
        {
          display: "grid",
          gridTemplateColumns: isMobile ? "1fr" : "1fr auto 330px",
          borderRadius: 12,
          backgroundColor: principalColors.white,
          minHeight: 330,
        },
      ]}
    >
      <div
        css={[
          isMobile ? cssFns.padding(10) : cssFns.padding(26, 20),
          {
            display: "grid",
            gridTemplateColumns: isMobile ? "auto 1fr" : "auto 1fr",
            gridTemplateRows: isMobile
              ? "minmax(122px, auto) repeat(4, auto)"
              : "auto auto 1fr",
            columnGap: isMobile ? 12 : 30,
          },
        ]}
      >
        <div
          css={[
            cssFns.gridColumn(1, 2),
            isMobile ? cssFns.gridRow(1, 2) : cssFns.gridRow(1, 4),
          ]}
        >
          <DoctorProfileReview
            doctor={doctor}
            doctor_service_id={selected?.services[0]?.id}
          />
        </div>
        <DoctorProfileInfo
          styles={css([
            cssFns.gridColumn(2, 3),
            cssFns.gridRow(1, 2),
            { marginBlockEnd: 16 },
          ])}
          doctor={doctor}
          heading={heading}
          qualification={
            selected?.services.filter((service) => service.qualification)[0]
              ?.qualification
          }
          services={selected?.services}
          integrated={integrated}
          premium={premium}
          areServicesLoading={areServicesLoading ?? true}
        />
        <div
          css={[
            cssFns.borderBlockEnd({
              color: principalColors.grayline,
              width: 1,
              style: "solid",
            }),
            cssFns.gridRow(2, 3),
            cssFns.gridColumn(1, 3),
            {
              display: isMobile ? "block" : "none",
              marginBlock: 16,
            },
          ]}
        />
        <div
          css={[
            isMobile ? cssFns.gridColumn(1, 3) : cssFns.gridColumn(2, 3),
            isMobile ? cssFns.gridRow(4, 5) : cssFns.gridRow(2, 3),
            { marginBlockEnd: isMobile ? 6 : 24 },
          ]}
        >
          {(areBranchesLoading ?? true) || (areServicesLoading ?? true) ? (
            <Skeleton css={{ height: 54 }} />
          ) : (
            selected?.services[0] && (
              <>
                <div
                  css={[
                    cssFns.typo({ weight: "regular", level: "body-1" }),
                    { color: principalColors.gs4, marginBlockEnd: 4 },
                  ]}
                >
                  <Message
                    id="eb08a7ce-3633-4934-a3a7-79f49d1042c2"
                    default="Прием в клинике"
                  />
                </div>
                <DoctorServicePrice
                  styles={css({
                    width: isMobile ? undefined : "fit-content",
                  })}
                  discount={selected.services[0].discount}
                  price={selected.services[0].price}
                  total={selected.services[0].total}
                  info={selected.services[0].price_conditions}
                  service_type={selected.services[0].service_type}
                  insurances={selected.services[0].insurances}
                />
              </>
            )
          )}
        </div>
        <div
          css={[
            isMobile ? cssFns.gridColumn(1, 3) : cssFns.gridColumn(2, 3),
            cssFns.gridRow(3, 4),
            { marginBlockEnd: isMobile ? 16 : undefined },
          ]}
          data-doctor
        >
          <div css={{ marginBlockEnd: 6 }}>
            {(areBranchesLoading ?? true) ? (
              <Skeleton css={{ height: isMobile ? 64 : 46 }} />
            ) : (
              selected &&
              (isMobile && branches.length > 1 ? (
                <MobileBranchSelect
                  branches={branches}
                  selected={selected.branch}
                  onSelectionChange={setBranchId}
                  city={city}
                  cityArea={cityArea}
                />
              ) : (
                <DoctorClinicInfo
                  name={selected.branch.name}
                  address={selected.branch.address}
                  slug={selected.branch.clinic_slug}
                  city={city}
                  cityArea={cityArea}
                  noRedirectToClinic={noRedirectToClinic}
                />
              ))
            )}
          </div>
          {station && station.itineraries[0] && (
            <div css={{ marginBlockEnd: 6 }}>
              <DoctorClinicMetroInfo
                name={station.name}
                distance={station.distance}
                icon_url={station.itineraries[0].icon_abs_url}
                icon_alt={
                  station.itineraries[0].short_name ||
                  station.itineraries[0].name
                }
              />
            </div>
          )}
          {selected?.branch && (
            <DoctorClinicMapInfo
              lat={selected.branch.location.lat}
              lng={selected.branch.location.lng}
              distance={
                doctor?.distances.find(
                  (distance) => distance.clinic_branch === selected.branch.id,
                )?.distance
              }
            />
          )}
        </div>
      </div>
      {!isMobile && (
        <div
          css={{
            paddingBlockStart: 26,
            marginInlineEnd: -1,
            zIndex: 1,
          }}
        >
          <DoctorDesktopBranchSelect
            branches={branches}
            selected={selected?.branch}
            onSelectionChange={setBranchId}
          />
        </div>
      )}
      <div
        css={[
          cssFns.borderInlineStart({
            color: principalColors.grayline,
            width: 1,
            style: "solid",
          }),
          isMobile ? cssFns.padding(16, 10) : cssFns.padding(20, 16),
          {
            paddingBlockStart: isMobile ? 0 : undefined,
            alignSelf: isMobile ? "flex-end" : undefined,
          },
        ]}
      >
        {initialSlots &&
        doctor &&
        (areSlotsLoading === false || areSlotsEmpty) ? (
          <AppointmentSlots
            doctor={doctor}
            branch_services={selected}
            city={city}
            cityArea={cityArea}
            initialSlots={branchSlots}
            defaultStartDate={defaultStartDate}
            request_meta={request_meta}
          />
        ) : (
          <Skeleton height={isMobile ? 59 : 120} />
        )}
      </div>
    </div>
  );
};

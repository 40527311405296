import { useV0RegistrationCodesCreate } from "@hermes/api";
import { Message, useMessage } from "@hermes/intl";
import {
  ButtonV2,
  type Field,
  Form,
  type FormErrorsContainer,
  type FormFields,
  Modal,
  OneTimeCodeField,
  type TextFieldState,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import { captureException } from "@sentry/react";
import { useEffect, useState } from "react";

import { ModalBodyContainer } from "./container";
import { AppointmentCreateError } from "./errors";

export const useSendOtp = ({
  errors,
  onError,
}: {
  errors: FormErrorsContainer;
  onError?: () => void;
}) => {
  const { mutateAsync: createCode, isPending } = useV0RegistrationCodesCreate();

  return {
    isPending,
    sendOtp: async (phone: string) => {
      const otpResponse = await createCode({
        phone: phone.replace(/[^\d+]/g, ""),
      });
      if (!otpResponse.ok) {
        captureException(
          new AppointmentCreateError(
            otpResponse.error.errors[0]?.detail || "Unknown error",
          ),
          {
            level: "error",
            tags: {
              booking: "otp_send",
            },
            contexts: {
              request_data: { phone },
              response_data: {
                type: otpResponse.error.type,
                errors: otpResponse.error.errors.map((error) => ({
                  detail: error.detail,
                  code: error.code,
                })),
              },
            },
            extra: {
              messages: otpResponse.error.errors.map((error) => error.detail),
              full_error_response: JSON.stringify(otpResponse.error),
            },
          },
        );

        if (otpResponse.error.type === "validation_error") {
          errors.update(
            otpResponse.error.errors.map((error) => {
              if (error.attr === "phone") {
                return {
                  key: "client_phone",
                  message: error.detail,
                };
              }

              return {
                key: error.attr ?? "generic",
                message: error.detail,
              };
            }),
          );
        }

        onError?.();
        return;
      }

      if (otpResponse.data.send_sms === false) {
        return false;
      }
      return true;
    },
  };
};

export const OtpStep = ({
  phone,
  fields,
  onSubmit,
  onChangeStep,
  errors,
  isPending,
}: {
  phone: string;
  fields: FormFields<{
    code: Field<TextFieldState>;
  }>;
  onSubmit: () => void;
  onChangeStep: (step: "form") => void;
  errors: FormErrorsContainer;
  isPending: boolean;
}) => {
  const { isMobile } = useScreenDetector();
  const message = useMessage();
  const principalColors = usePrincipalColors();
  const [isOtpCodeResend, setIsOtpCodeResend] = useState(true);
  const [resendTimer, setResendTimer] = useState(60);

  useEffect(() => {
    if (isOtpCodeResend) {
      const interval = setInterval(() => {
        setResendTimer((prev) => {
          if (prev <= 1) {
            clearInterval(interval);
            setIsOtpCodeResend(false);
            return 60;
          }
          return prev - 1;
        });
      }, 1000);

      return () => clearInterval(interval);
    }
    return;
  }, [isOtpCodeResend]);

  useEffect(() => {
    if (!fields.code.value.includes("-") && fields.code.value !== "") {
      onSubmit();
    }
  }, [fields.code.value]);

  const { sendOtp, isPending: isOtpPending } = useSendOtp({
    errors,
    onError: () => onChangeStep("form"),
  });

  return (
    <Modal.Content
      showBackButton
      onBackButtonClick={() => onChangeStep("form")}
      title={message({
        id: "appointment-modal.title",
      })}
      main={
        <ModalBodyContainer>
          <Form fields={fields} onSubmit={onSubmit}>
            <p css={{ marginBlockEnd: 20 }}>
              <Message
                id="dbcb6f28-c108-449c-a24b-8fbdfee6b5a3"
                default="Введите код отправленный на номер:"
              />
              <br />
              <strong>{phone}</strong>
            </p>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                gap: 20,
              }}
            >
              <OneTimeCodeField
                field={fields.code}
                name="otp"
                placeholder="----"
              />
            </div>
            <div
              css={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 6,
                marginBlockStart: 20,
              }}
            >
              <span>
                <Message
                  id="1ce90a97-e2fa-4de9-b6bf-d6bbca566d33"
                  default="Не получили код?"
                />
              </span>
              <span
                css={{
                  color: principalColors.gs4,
                }}
              >
                {isOtpCodeResend ? (
                  message({
                    id: "timer.resend-again",
                    default: "Отправить код еще раз через {resendTimer} сек.",
                    values: {
                      resendTimer,
                    },
                  })
                ) : (
                  <ButtonV2
                    text={message({
                      id: "timer.resend",
                      default: "Отправить код еще раз",
                    })}
                    variant={"link"}
                    onPress={() => {
                      sendOtp(phone);
                      setIsOtpCodeResend(true);
                    }}
                  />
                )}
              </span>
            </div>
          </Form>
        </ModalBodyContainer>
      }
      footer={
        <ModalBodyContainer>
          <div
            css={{
              marginBlockEnd: isMobile ? 30 : 20,
              display: "flex",
              flexDirection: "column",
              gap: 14,
            }}
          >
            <ButtonV2
              minWidth={230}
              width={isMobile ? "100%" : undefined}
              variant="solid"
              text={message({
                id: "appointment-modal.submit",
              })}
              disabled={isOtpPending || isPending}
              onPress={() => {
                if (fields.validate()) {
                  onSubmit();
                }
              }}
            />
          </div>
        </ModalBodyContainer>
      }
    />
  );
};

import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { AppointmentField } from "./definitions";

type UseV1ClinicBranchAppointmentFieldsResult = AppointmentField[];

type UseV1ClinicBranchAppointmentFieldsOptions =
  UseQueryOptions<UseV1ClinicBranchAppointmentFieldsResult>;
type UseV1ClinicBranchAppointmentFieldsParams = {
  id: number;
};

export const useV1ClinicBranchAppointmentFields = (
  params: UseV1ClinicBranchAppointmentFieldsParams,
  options?: Partial<UseV1ClinicBranchAppointmentFieldsOptions>,
) => {
  const url = `/api/v1/clinic-branches/${params.id}/appointment-fields/`;
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

type UseV1ClinicBranchAppointmentFieldsSuspenseResult = AppointmentField[];

type UseV1ClinicBranchAppointmentFieldsSuspenseOptions =
  UseSuspenseQueryOptions<UseV1ClinicBranchAppointmentFieldsSuspenseResult>;
type UseV1ClinicBranchAppointmentFieldsSuspenseParams = {
  id: number;
};

export const useV1ClinicBranchAppointmentFieldsSuspense = (
  params: UseV1ClinicBranchAppointmentFieldsSuspenseParams,
  options?: Partial<UseV1ClinicBranchAppointmentFieldsSuspenseOptions>,
) => {
  const url = `/api/v1/clinic-branches/${params.id}/appointment-fields/`;
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
        },
        {
          200: async (r) => r.data,
        },
      );
    },
  });
};

import { Temporal, useLocale, useNow } from "@hermes/intl";
import { useEffect, useMemo } from "react";

import { FieldDescription } from "../common/description";
import { FieldError } from "../common/error";
import { BaseFieldLayout } from "../common/field";
import {
  type Field,
  type FieldState,
  useBaseFieldState,
  useFormFields,
} from "../common/form";
import { FieldLabel } from "../common/label";
import { Select, useSelectState } from "../selectable/select";

type LinearDateFieldValue = `${number}-${number}-${number}`;

export type LinearDateFieldState = FieldState<LinearDateFieldValue | null>;

const capitalize = (str: string) => str[0]?.toUpperCase() + str.slice(1);

export function LinearDateField({
  label,
  field,
  description,
  onSelect,
}: {
  label?: string;
  field: Field<LinearDateFieldState>;
  description?: string;
  onSelect?: (value: LinearDateFieldValue) => void;
}) {
  const now = useNow("year");
  const [locale] = useLocale();

  const fields = useFormFields({
    year: useSelectState<number>({
      value: null,
      required: field.required,
      disabled: field.disabled,
      error: field.error,
    }),
    month: useSelectState<number>({
      value: null,
      required: field.required,
      disabled: field.disabled,
      error: field.error,
    }),
    day: useSelectState<number>({
      value: null,
      required: field.required,
      disabled: field.disabled,
      error: field.error,
    }),
  });

  useEffect(() => {
    const [year, month, day] = (field.value?.split("-") || []).map(Number);

    fields.year.onChange((state) => ({
      ...state,
      value: year ?? null,
    }));

    fields.month.onChange((state) => ({
      ...state,
      value: month ?? null,
    }));

    fields.day.onChange((state) => ({
      ...state,
      value: day ?? null,
    }));
  }, [field.value]);

  const selected = useMemo(
    () =>
      fields.year.value && fields.month.value && fields.day.value
        ? Temporal.PlainDate.from({
            year: fields.year.value,
            month: fields.month.value,
            day: fields.day.value,
          })
        : null,
    [fields.year.value, fields.month.value, fields.day.value],
  );

  const labels = useMemo(() => {
    const dn = new Intl.DisplayNames(locale.toString(), {
      type: "dateTimeField",
    });

    return {
      year: capitalize(dn.of("year") || ""),
      month: capitalize(dn.of("month") || ""),
      day: capitalize(dn.of("day") || ""),
    };
  }, [now, locale]);

  useEffect(() => {
    if (!selected) return;

    field.onChange((state) => ({
      ...state,
      value: selected.toString() as LinearDateFieldValue,
    }));
    onSelect?.(selected.toString() as LinearDateFieldValue);
  }, [selected]);

  return (
    <BaseFieldLayout
      label={
        label && (
          <FieldLabel disabled={field.disabled} required={field.required}>
            {label}
          </FieldLabel>
        )
      }
      input={
        <div
          css={{
            display: "grid",
            gap: 8,
            gridTemplateColumns: "100px 1fr 100px",
          }}
        >
          <Select
            variant="desktop"
            field={fields.year}
            placeholder={labels.year}
            options={Array.from({ length: now.year - 1920 }).map(
              (_, index) => ({
                label: (now.year - index).toString(),
                value: now.year - index,
              }),
            )}
          />
          <Select
            variant="desktop"
            field={fields.month}
            placeholder={labels.month}
            options={Array.from({ length: 12 }).map((_, index) => ({
              label: capitalize(
                Temporal.PlainDate.from({
                  year: 2000,
                  month: index + 1,
                  day: 1,
                }).toLocaleString(locale.toString(), { month: "long" }),
              ),
              value: index + 1,
            }))}
          />
          <Select
            variant="desktop"
            field={fields.day}
            placeholder={labels.day}
            options={Array.from({
              length: Temporal.PlainDate.from({
                year: fields.year.value ?? 2000,
                month: fields.month.value ?? 1,
                day: 1,
              }).daysInMonth,
            }).map((_, index) => ({
              label: (index + 1).toString(),
              value: index + 1,
            }))}
          />
        </div>
      }
      description={
        description && (
          <FieldDescription disabled={field.disabled}>
            {description}
          </FieldDescription>
        )
      }
      error={
        field.error?.visible && (
          <FieldError
            fieldRequired={field.required}
            errorMessage={field.error.message}
          />
        )
      }
    />
  );
}

export const useLinearDateFieldState = (
  defaultState?: LinearDateFieldState,
) => {
  return useBaseFieldState<LinearDateFieldState>({
    value: null,
    error: undefined,
    ...defaultState,
  });
};

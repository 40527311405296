import type { SerializedStyles } from "@emotion/react";
import type { Doctor } from "@hermes/api";
import { useScreenDetector } from "@hermes/ui";
import { type HTMLAttributes } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { useRoutePath } from "#internal/app/router";
import { ImageLoader } from "#internal/shared/ui/image-loader";

export const DoctorProfileImage = ({
  doctor,
  size,
  styles,
  doctor_service_id,
  isClickable,
}: {
  doctor?: Doctor;
  size?: string;
  styles?: SerializedStyles;
  doctor_service_id?: number;
  isClickable: boolean;
} & HTMLAttributes<HTMLDivElement>) => {
  const { routePath } = useRoutePath();
  const { isDesktop } = useScreenDetector();

  return (
    <div
      css={[
        {
          width: size,
          height: size,
          borderRadius: "50%",
          overflow: "hidden",
        },
        styles,
      ]}
    >
      {doctor ? (
        isClickable ? (
          <Link
            to={routePath({
              path: "/doctor/:slug",
              params: {
                slug: doctor.slug,
              },
              query: {
                doctor_service_id: doctor_service_id?.toString(),
              },
            })}
            {...(isDesktop && { target: "_blank" })}
          >
            <ImageLoader
              src={doctor.image_url}
              alt={doctor.name}
              title={doctor.name}
            />
          </Link>
        ) : (
          <ImageLoader
            src={doctor.image_url}
            alt={doctor.name}
            title={doctor.name}
          />
        )
      ) : (
        <Skeleton
          css={{
            height: "100%",
          }}
        />
      )}
    </div>
  );
};

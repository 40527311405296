import type { Doctor, DoctorService, Slot } from "@hermes/api";
import { type Temporal, useNow } from "@hermes/intl";
import { type TimeSlot, TimeSlots } from "@hermes/shared";
import { useScreenDetector } from "@hermes/ui";
import { useEffect, useState } from "react";

export const SlotsBlock = ({
  doctor,
  branchSlots,
  selectedService,
  onSlotChange,
  defaultStartDate,
  defaultEndDate,
}: {
  doctor: Doctor;
  selectedService: DoctorService;
  branchSlots?: Slot[];
  onSlotChange: (slot: TimeSlot) => void;
  defaultStartDate?: Temporal.PlainDate;
  defaultEndDate?: Temporal.PlainDate;
}) => {
  const now = useNow("day");
  const { isMobile } = useScreenDetector();

  const [startDate, setStartDate] = useState<Temporal.PlainDate>(
    defaultStartDate ?? now.toPlainDate(),
  );
  const [endDate, setEndDate] = useState<Temporal.PlainDate>(
    defaultEndDate ??
      defaultStartDate?.add({ days: 3 }) ??
      now.toPlainDate().add({ days: 3 }),
  );
  useEffect(() => {
    defaultStartDate && setStartDate(defaultStartDate);
    defaultEndDate && setEndDate(defaultEndDate);
  }, [defaultStartDate, defaultEndDate]);

  return (
    <div
      css={{
        width: isMobile ? "100%" : "400px",
        margin: isMobile ? 0 : "0 auto",
      }}
    >
      <TimeSlots
        initialSlots={branchSlots}
        startDate={startDate}
        endDate={endDate}
        doctor={doctor.id}
        clinic_branch={selectedService.clinic_branch}
        service={[selectedService.service]}
        slotsLimit={false}
        onSlotPress={(slot) => {
          if (!slot) return;
          onSlotChange(slot);
        }}
        onPrevPress={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        onNextPress={(startDate, endDate) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        variant="desktop"
      />
    </div>
  );
};

import { type AlgoliaHit, useV0AlgoliaSearch } from "@hermes/api";
import { Message, useLocale, useMessage } from "@hermes/intl";
import {
  ComboBox,
  type ComboboxChildren,
  type ComboboxGroup,
  type ComboboxImperativeRef,
  type ComboboxState,
  cssFns,
  type Field,
  type ListBoxOptions,
  useDebouncedValue,
  useListBoxItemStyles,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";
import { type RefObject, useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useAppConfig } from "#internal/app/config";
import { RoutesIdEnum, useRouteId, useRoutePath } from "#internal/app/router";

export type PartialHit = Pick<
  AlgoliaHit,
  "title" | "type" | "objectID" | "slug" | "pretty_url"
>;

export const useSearchBarRoute = () => {
  const { routePath } = useRoutePath();
  const { city, cityArea } = useAppConfig();
  const route = useLocation();

  return useCallback(
    (hit?: PartialHit) => {
      if (!hit) return route;

      switch (hit.type) {
        case "Доктора":
          return routePath({
            path: "/doctor/:slug",
            params: {
              slug: hit.slug || hit.pretty_url || "",
            },
          });
        case "Клиники":
          return routePath({
            path: "/clinics/:city/:slug",
            params: {
              city: city.slug,
              slug: hit.slug || hit.pretty_url || "",
            },
          });
        case "Специальности":
        case "Услуги":
          return routePath({
            path: "/doctors/:city/:service",
            params: {
              city: city.slug,
              service: hit.slug || hit.pretty_url || "",
            },
            query: {
              city_area: cityArea?.slug,
            },
          });
      }
    },
    [city.slug, cityArea?.slug, route],
  );
};

const OptionItem: ListBoxOptions<PartialHit>["renderItem"] = ({
  option,
  rendered,
  isFocused,
  isSelected,
  level,
}) => {
  const { isMobile } = useScreenDetector();
  const routeId = useRouteId();
  const navigate = useNavigate();
  const styles = useListBoxItemStyles({ isFocused, isSelected, level });
  const route = useSearchBarRoute();

  return (
    <div
      css={[styles, { textDecoration: "none" }]}
      onPointerUp={() => {
        // Don't navigate right away on mobile & when user is on home page
        if (isMobile && routeId === RoutesIdEnum.HOME) return;

        // Delay navigation for dropdown to hide
        setTimeout(() => {
          navigate(route(option));
        }, 0);
      }}
    >
      {rendered}
    </div>
  );
};

export const SearchBarSelect = ({
  searchRef,
  field,
  onCityLinkClick,
  children,
  placeholder,
  areServicesLoading,
  fallbackOptions,
  onFocus,
}: {
  field: Field<ComboboxState<PartialHit>>;
  searchRef?: RefObject<ComboboxImperativeRef>;
  onCityLinkClick?: () => void;
  placeholder?: string;
  areServicesLoading?: boolean;
  fallbackOptions: {
    key: {
      slug: string;
      type: "Специальности";
      objectID: string;
      title: string;
    };
    label: string;
  }[];
  onFocus: () => void;
} & ComboboxChildren) => {
  const { city, cityArea } = useAppConfig();
  const { isMobile } = useScreenDetector();
  const [locale] = useLocale();
  const principalColors = usePrincipalColors();
  const message = useMessage();
  const debouncedSearch = useDebouncedValue(
    field.inputValue && field.inputValue.length > 2 ? field.inputValue : "",
    300,
  );

  const { data, isLoading } = useV0AlgoliaSearch(
    {
      query: debouncedSearch,
      filters: `city=${city.id}`,
      url: `${import.meta.env.DOQ_ALGOLIA_API_URL}/${import.meta.env.DOQ_ALGOLIA_QUERY_KEY}`,
      apiKey: import.meta.env.DOQ_ALGOLIA_API_KEY,
      applicationId: import.meta.env.DOQ_ALGOLIA_APPLICATION_ID,
    },
    { enabled: Boolean(debouncedSearch) },
  );

  const options: ComboboxGroup<PartialHit>[] | null = useMemo(() => {
    const groups = data?.hits.groupBy((hit) => hit.type);

    return groups
      ? Object.entries(groups).map(([type, hits]) => ({
          key: {
            objectID: type,
            title: "",
            type,
            slug: "",
          } as PartialHit,
          label:
            {
              Клиники: message({
                id: "common.clinics",
              }),
              Специальности: message({
                id: "common.services.initial-appointment",
              }),
              Услуги: message({
                id: "common.services.procedures",
              }),
              Доктора: message({
                id: "common.doctors",
              }),
            }[type] || "",
          options: hits.map((hit) => ({
            key: hit as PartialHit,
            label:
              (typeof hit.title === "string"
                ? hit.title
                : hit.title[locale.baseName]) || "",
          })),
        }))
      : null;
  }, [data?.hits, message, locale.baseName]);

  return (
    <ComboBox
      imperativeRef={searchRef}
      isLoading={isLoading || areServicesLoading}
      field={field}
      options={
        options
          ? options
          : fallbackOptions.length > 0
            ? [
                {
                  label: message({
                    id: "common.services.initial-appointment",
                    default: "Специальности",
                  }),
                  options: fallbackOptions,
                },
              ]
            : []
      }
      menuTrigger="focus"
      showSearchIcon={isMobile}
      onFocus={onFocus}
      renderItem={OptionItem}
      onSelectionChange={() => {
        // Move focus on the page, so that next search press will trigger dropdown
        searchRef?.current?.blur();
      }}
      mobile={{
        subtitle: (
          <div
            css={[
              cssFns.typo({ level: "body-1", weight: "regular" }),
              { marginBlockStart: "16px", color: principalColors.gs4 },
            ]}
          >
            <Message
              id="0218244d-1f42-4c3d-8746-bae41052424a"
              default="Поиск в {cityArea} {city}"
              values={{
                cityArea: cityArea ? (
                  <span
                    key="city-area-link"
                    css={{ color: principalColors.ebblue }}
                    onClick={() => {
                      onCityLinkClick?.();
                    }}
                  >
                    {cityArea.name},
                  </span>
                ) : undefined,
                city: (
                  <span
                    key="city-link"
                    css={{ color: principalColors.ebblue }}
                    onClick={() => {
                      onCityLinkClick?.();
                    }}
                  >
                    {city.name}
                  </span>
                ),
              }}
            />
          </div>
        ),
      }}
      placeholder={placeholder}
      clearable
      showArrowDown={false}
      toKeyString={(hit) => hit.objectID}
      notFoundLabel={
        debouncedSearch.length >= 3
          ? message({
              id: "95bbb2cb-787d-4dc2-b75d-118537c86e2d",
              default: "Не найдено",
            })
          : undefined
      }
      children={children}
    />
  );
};

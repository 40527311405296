import type { SerializedStyles } from "@emotion/react";
import { useRef } from "react";
import {
  type AriaCheckboxProps,
  mergeProps,
  useCheckbox,
  useFocusRing,
  VisuallyHidden,
} from "react-aria";
import { useToggleState } from "react-stately";

import { cssFns } from "../css";
import { CheckboxFilled, CheckboxNormal } from "../icons";
import { usePrincipalColors, useSecondaryColors } from "../theme";
import { FieldError } from "./common/error";
import { BaseFieldLayout } from "./common/field";
import { type Field, type FieldState, useBaseFieldState } from "./common/form";

export type CheckboxState = FieldState<boolean>;

export function Checkbox({
  field,
  autoFocus,
  label,
  labelPosition,
  onChange,
  styles,
}: {
  label?: string;
  field: Field<CheckboxState>;
  labelPosition?: "start" | "end";
  autoFocus?: boolean;
  onChange?: (value: boolean) => void;
  styles?: SerializedStyles;
}) {
  const principalColors = usePrincipalColors();
  const secondaryColors = useSecondaryColors();

  const options: AriaCheckboxProps = {
    isRequired: field.required,
    isDisabled: field.disabled,
    autoFocus,
    isSelected: field.value,
    onChange(value) {
      onChange?.(value);
      field.onChange({
        ...field,
        value,
      });
    },
    "aria-label": label ?? "checkbox",
  };

  const state = useToggleState(options);
  const ref = useRef<HTMLInputElement>(null);
  const { inputProps } = useCheckbox(options, state, ref);
  const { isFocusVisible, focusProps } = useFocusRing();

  return (
    <>
      <BaseFieldLayout
        input={
          <label
            css={[
              cssFns.padding(10, 8),
              {
                display: "flex",
                alignItems: "center",
                flexDirection:
                  labelPosition === "start" ? "row-reverse" : "row",
                gap: 5,
                cursor: "pointer",
                backgroundColor: cssFns.setOpacity(principalColors.ebblue, 0.1),
                borderRadius: 4,
              },
              ...[styles],
            ]}
          >
            <VisuallyHidden>
              <input {...mergeProps(focusProps, inputProps)} ref={ref} />
            </VisuallyHidden>
            {state.isSelected ? (
              <CheckboxFilled
                strokeWidth={isFocusVisible ? 2 : 1.5}
                color={
                  field.error?.visible === true
                    ? secondaryColors.red
                    : principalColors.ebblue
                }
              />
            ) : (
              <CheckboxNormal
                strokeWidth={isFocusVisible ? 2 : 1.5}
                color={
                  field.error?.visible === true
                    ? secondaryColors.red
                    : principalColors.ebblue
                }
              />
            )}
            {label}
            {field.required && (
              <span css={{ color: secondaryColors.red }}> *</span>
            )}
          </label>
        }
        error={
          field.error?.visible && (
            <FieldError
              errorMessage={field.error.message}
              fieldRequired={field.required}
            />
          )
        }
      />
    </>
  );
}

export const useCheckboxState = (defaultState?: CheckboxState) => {
  return useBaseFieldState<CheckboxState>({
    value: false,
    error: undefined,
    ...defaultState,
  });
};

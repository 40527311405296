import { cssFns } from "@hermes/ui";

export const Chip = ({ color, text }: { color: string; text: string }) => {
  return (
    <div
      css={[
        cssFns.typo({ weight: "semibold", level: "body-3" }),
        // Уменьшил padding потому что lineHeight текста в дизайне 13px, а общая высота 21px(сейчас с отступами стало 22px)
        cssFns.padding("2px", "6px"),
        {
          color,
          borderRadius: "30px",
          backgroundColor: cssFns.setOpacity(color, 0.2),
          pointerEvents: "none",
        },
      ]}
    >
      {text}
    </div>
  );
};

import { useQuery, type UseQueryOptions } from "@tanstack/react-query";

import { useApi } from "../../context";
import type { PaginatedResponse } from "../common";
import type { Insurance } from "./definitions";

const tryInjected = (
  locale?: string,
): PaginatedResponse<Insurance[]> | undefined => {
  const injected = (window as any)?.["__INSURANCES_DATA__"];
  return injected ? injected[locale || ""] : undefined;
};

type UseV1InsurancesParams = {
  limit?: "none" | number;
  id?: number[];
};
type UseV1InsurancesResult = PaginatedResponse<Insurance[]>;

type UseV1InsurancesOptions = UseQueryOptions<UseV1InsurancesResult>;

export const useV1Insurances = (
  params: UseV1InsurancesParams,
  options?: Partial<UseV1InsurancesOptions>,
) => {
  const url = "/api/v1/insurances/";
  const { api, instance } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      const injected = tryInjected(
        instance.defaults.headers["Accept-Language"]?.toString(),
      );
      return (
        injected ??
        api<UseV1InsurancesResult>(
          {
            url,
            method: "GET",
            params: {
              ...params,
              id: params.id?.join(","),
            },
          },
          {
            200: async (r) => r.data,
          },
        )
      );
    },
  });
};

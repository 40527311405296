import {
  useQuery,
  type UseQueryOptions,
  useSuspenseQuery,
  type UseSuspenseQueryOptions,
} from "@tanstack/react-query";

import { useApi } from "../../context";
import type { DoctorsListMeta } from "./definitions";

type UseV1DoctorsListMetaSuspenseParams = {
  city?: number;
  city_area?: number;
  service?: number[];
  lat?: number;
  lng?: number;
  slot_date?: string;
  ordering?: "default" | "rating" | "price" | "experience" | "distance";
  language?: string;
  insurance?: number;
};
type UseV1DoctorsListMetaSuspenseResult =
  | ({
      ok: true;
    } & DoctorsListMeta)
  | {
      ok: false;
      detail: string;
    };
type UseV1DoctorsListMetaSuspenseOptions =
  UseSuspenseQueryOptions<UseV1DoctorsListMetaSuspenseResult>;

export const useV1DoctorsListMetaSuspense = (
  params: UseV1DoctorsListMetaSuspenseParams,
  options?: Partial<UseV1DoctorsListMetaSuspenseOptions>,
) => {
  const url = "/api/v1/doctors-meta/";
  const { api } = useApi();

  return useSuspenseQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            service: params.service?.join(","),
          },
        },
        {
          200: async (r) => ({
            ok: true as const,
            ...r.data,
          }),
          404: async (r) => ({
            ok: false as const,
            ...r.data,
          }),
        },
      );
    },
  });
};
type UseV1DoctorsListMetaParams = UseV1DoctorsListMetaSuspenseParams;
type UseV1DoctorsListMetaResult = UseV1DoctorsListMetaSuspenseResult;
type UseV1DoctorsListMetaOptions = UseQueryOptions<UseV1DoctorsListMetaResult>;

export const useV1DoctorsListMeta = (
  params: UseV1DoctorsListMetaParams,
  options?: Partial<UseV1DoctorsListMetaOptions>,
) => {
  const url = "/api/v1/doctors-meta/";
  const { api } = useApi();

  return useQuery({
    ...options,
    queryKey: [url, params],
    queryFn: () => {
      return api(
        {
          url,
          method: "GET",
          params: {
            ...params,
            service: params.service?.join(","),
          },
        },
        {
          200: async (r) => ({
            ok: true as const,
            ...r.data,
          }),
          404: async (r) => ({
            ok: false as const,
            ...r.data,
          }),
        },
      );
    },
  });
};

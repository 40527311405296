import {
  createContext,
  type ReactNode,
  useCallback,
  useContext,
  useMemo,
} from "react";

const Context = createContext<{ [flag: string]: boolean } | undefined>(
  undefined,
);

/**
 * Set or override values of given flags for children.
 * Final flag values are defined by nearest Flags instance
 * that provides a boolean value for it.
 */
export const Flags = ({
  value,
  children,
}: {
  value: { [flag: string]: boolean };
  children?: ReactNode;
}) => {
  const parentValue = useContext(Context);
  const childValue = useMemo(
    () => ({ ...parentValue, ...value }),
    [parentValue, value],
  );
  return <Context.Provider value={childValue}>{children}</Context.Provider>;
};

/**
 * Render children only if specified flag is enabled.
 */
export const Flag = ({
  id,
  description,
  fallback,
  children,
}: {
  /** Globally unique flag ID */
  id: string;
  /** Short phrase describing what enabling the flag does. */
  description?: string;
  /** Rendered when the flag is not enabled */
  fallback?: ReactNode;
  /** Rendered when the flag is enabled */
  children?: ReactNode;
}) => {
  const flag = useFlag();
  const value = flag({ id, description });
  return value ? <>{children}</> : <>{fallback}</>;
};

/**
 * Get specified flag value
 */
export const useFlag = () => {
  const flags = useContext(Context);
  return useCallback<
    (params: {
      /** Globally unique flag ID */
      id: string;
      /** Short phrase describing what enabling the flag does. */
      description?: string;
    }) => boolean
  >(({ id }) => flags?.[id] ?? false, [flags]);
};

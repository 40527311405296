import type { ReactNode } from "react";

import { polyfillDatetimeFormat } from "./datetime-format";
import { polyfillNumberFormat } from "./number-format";
import { polyfillPluralRules } from "./plural-rules";
import { polyfillRelativeTimeFormat } from "./relative-time-format";

const supportedLocales = new Set<string>();

export type PolyfillExclude =
  | "relative-time-format"
  | "date-time-format"
  | "number-format"
  | "plural-rules";

export const PollyFillLoader = ({
  availableLocales,
  children,
  exclude,
}: {
  availableLocales: string[];
  children: ReactNode;
  exclude?: PolyfillExclude[];
}) => {
  const unsupportedLocales = availableLocales.filter(
    (locale) => !supportedLocales.has(locale),
  );

  if (unsupportedLocales.length) {
    const set = new Set(exclude);
    throw Promise.all([
      !set.has("plural-rules")
        ? polyfillPluralRules(unsupportedLocales)
            .then(() =>
              !set.has("number-format")
                ? polyfillNumberFormat(unsupportedLocales)
                : null,
            )
            .then(() =>
              Promise.all([
                !set.has("date-time-format")
                  ? polyfillDatetimeFormat(unsupportedLocales)
                  : null,
                !set.has("relative-time-format")
                  ? polyfillRelativeTimeFormat(unsupportedLocales)
                  : null,
              ]),
            )
        : null,
    ]).then(() => {
      unsupportedLocales.forEach((locale) => supportedLocales.add(locale));
    });
  }

  return <>{children}</>;
};

import {
  type DefaultError,
  type QueriesOptions,
  type QueriesResults,
  type QueryKey,
  useQueries as useBaseQueries,
  useQuery as useBaseQuery,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";

export type PaginatedResponse<T> = {
  count: number;
  next: string | null;
  previous: string | null;
  results: T;
};

const inferState = (query: UseQueryResult<any, any>) =>
  `${query.dataUpdatedAt}-${query.errorUpdatedAt}-${query.status}-${query.fetchStatus}`;

export function useHermesQuery<
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
): UseQueryResult<TData, TError> & {
  state: string;
} {
  const query = useBaseQuery(options) as UseQueryResult<TData, TError> & {
    state: string;
  };

  query.state = inferState(query);

  return query;
}

export function useHermesQueries<
  R = any,
  T extends Array<R> = Array<R>,
  TCombinedResult = QueriesResults<T>,
>(options: {
  queries: readonly [...QueriesOptions<T>];
}): TCombinedResult & {
  state: string;
} {
  const queries = useBaseQueries(options) as TCombinedResult & {
    state: string;
  };

  if (Array.isArray(queries)) {
    queries.state = "";
    for (let i = 0; i < queries.length; i++) {
      if (!queries[i]) continue;
      queries[i].state = inferState(queries[i]);
      queries.state += queries[i].state;
    }
  }

  return queries;
}

import { useCallback } from "react";

import { useLocale } from "../locale";
import { Temporal } from "../temporal";

export const useFormatDateTime = () => {
  const [locale] = useLocale();

  return useCallback(
    (
      value: Temporal.PlainDate | Temporal.PlainDateTime | Temporal.PlainTime,
      options?: Intl.DateTimeFormatOptions,
    ) => {
      return value.toLocaleString(locale.toString(), options);
    },
    [locale],
  );
};

export const useFormatDateTimeParts = () => {
  const [locale] = useLocale();

  return useCallback(
    (
      value: Temporal.PlainDate | Temporal.PlainDateTime | Temporal.PlainTime,
      options?: Intl.DateTimeFormatOptions,
    ) => {
      return value.toLocaleString(locale.toString(), options);
    },
    [locale],
  );
};

export const useFormatRelativeTime = () => {
  const [locale] = useLocale();

  return useCallback(
    (value: number, unit: Intl.RelativeTimeFormatUnit) => {
      const rtf = new Intl.RelativeTimeFormat(locale.toString(), {
        numeric: "auto",
      });

      return rtf.format(value, unit);
    },
    [locale],
  );
};

export const useFormatLargestRelativeTime = () => {
  const [locale] = useLocale();

  return useCallback(
    (duration: Temporal.Duration, direction: -1 | 1 = 1) => {
      const rtf = new Intl.RelativeTimeFormat(locale.toString(), {
        numeric: "always",
      });

      const balanced = duration.round({
        largestUnit: "years",
        roundingMode: "ceil",
        smallestUnit: "seconds",
        relativeTo: Temporal.Now.plainDateISO(),
      });

      const largest = (
        [
          [balanced.years, "years"],
          [balanced.months, "months"],
          [balanced.days, "days"],
          [balanced.hours, "hours"],
          [balanced.minutes, "minutes"],
          [balanced.seconds, "seconds"],
        ] as const
      ).find(([value]) => value > 0);

      if (!largest) return rtf.format(0, "seconds");

      const rounded = balanced.round({
        largestUnit: largest[1],
        roundingMode: "ceil",
        smallestUnit: largest[1],
        relativeTo: Temporal.Now.plainDateISO(),
      });

      return rtf.format(rounded[largest[1]] * direction, largest[1]);
    },
    [locale],
  );
};

import { Message } from "@hermes/intl";
import {
  cssFns,
  icons,
  usePrincipalColors,
  useScreenDetector,
} from "@hermes/ui";

export const Placeholder = () => {
  const principalColors = usePrincipalColors();
  const { isMobile } = useScreenDetector();

  return (
    <div
      css={[
        cssFns.center(),
        cssFns.padding("12px"),
        cssFns.border({ radius: "4px" }),
        {
          minHeight: isMobile ? 56 : undefined,
          backgroundColor: cssFns.setOpacity(principalColors.gs8, 0.1),
        },
      ]}
    >
      <div
        css={[
          cssFns.typo({ level: "body-1", weight: "regular" }),
          {
            display: "flex",
            columnGap: "4px",
          },
        ]}
      >
        <icons.MobileListingCalendar />
        <Message
          id="03bb890d-9ac8-46f0-9b12-19b9c3037018"
          default="Клиника не предоставила расписание"
        />
      </div>
    </div>
  );
};
